<template>

  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">


          <card>
            <h4 slot="header" class="card-title">Створити турнір</h4>
            <form>
              <div class="row">
                <div class="col-md-6">
                  <base-input type="text"
                              label="Назва"
                              :disabled="false"
                              placeholder="Назва турніру"
                              v-model="form.name">
                  </base-input>
                </div>
                <div class="col-md-3">
                  <base-input type="text"
                              label="Коротка назва (для Telegram бота)"
                              placeholder="Коротка назва"
                              v-model="form.shortName">
                  </base-input>
                </div>


                <div class="col-md-3">
                  <base-input type="text"
                              label="Дата"
                              placeholder="03.10.2019"
                              v-model="form.date">
                  </base-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-3">

                  <div class="form-group"><label class="control-label">
                    Місто
                  </label>
                    <v-select label="name" :filterable="true" :options="info.cityList"
                              v-model="form.city">
                      <template slot="no-options">Виберіть місто
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          <span class="name-city">{{ option.name }}</span>
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          <span class="name-city">{{ option.name }}</span>
                        </div>
                      </template>
                    </v-select>
                  </div>

                </div>
                <div class="col-md-3">
                  <base-input type="text"
                              label="Адреса"
                              placeholder="Адреса"
                              v-model="form.address">
                  </base-input>
                </div>
                <div class="col-md-3">
                  <base-input type="text"
                              label="Контакти"
                              placeholder="Контакти"
                              v-model="form.contacts">
                  </base-input>
                </div>
                <div class="col-md-3">
                  <base-input type="text"
                              label="Організатор"
                              placeholder="Організатор"
                              v-model="form.manager">
                  </base-input>
                </div>

              </div>


              <div class="row">


                <div class="col-md-3">
                  <base-input type="text"
                              label="Головний суддя"
                              placeholder="Головний суддя"
                              v-model="form.judgename">
                  </base-input>
                </div>
                <div class="col-md-3">
                  <base-input type="text"
                              label="Секретар"
                              placeholder="Секретар"
                              v-model="form.secretaryname">
                  </base-input>
                </div>

                <div class="col-md-3">

                  <div class="form-group"><label class="control-label">
                    Друк протоколів
                  </label>
                    <v-select label="name" :filterable="true" :options="info.protocolList"
                              v-model="form.protocol_type">
                      <template slot="no-options"> -
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          <span class="name-city">{{ option.name }}</span>
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          <span class="name-city">{{ option.name }}</span>
                        </div>
                      </template>
                    </v-select>
                  </div>

                </div>






              </div>
              <div class="row">

                <div class="col-md-3">

                  <div class="form-group"><label class="control-label">
                    Публікувати (видимість в TelegramBot)
                  </label>
                    <v-select label="name" :filterable="true" :options="info.publishList"
                              v-model="form.publish">
                      <template slot="no-options"> -
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          <span class="name-city">{{ option.name }}</span>
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          <span class="name-city">{{ option.name }}</span>
                        </div>
                      </template>
                    </v-select>
                  </div>

                </div>

                <div class="col-md-3">

                  <div class="form-group"><label class="control-label">
                    Статус реєстрації
                  </label>
                    <v-select label="name" :filterable="true" :options="info.registrationStatusList"
                              v-model="form.registration_status">
                      <template slot="no-options"> -
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          <span class="name-city">{{ option.name }}</span>
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          <span class="name-city">{{ option.name }}</span>
                        </div>
                      </template>
                    </v-select>
                  </div>

                </div>





              </div>


                            <div class="row" style="display: none">
                              <div class="col-md-12">


                                <div class="form-group" style="display: none">
                                  <label>Категорії Керугі</label>
                                  <div>
                                    <!--<button class="btn btn-fill btn-primary"><span class="checkbox-container"><i class="nc-icon nc-simple-add" style="vertical-align: super;"></i></span>
                                      Додати свою категорію
                                    </button>-->
                                  </div>
                                  <div style="margin-left: -5px; margin-right: -5px;">
                                    <button v-for="category in info.allCategories"
                                            v-if="category.type == 1"
                                            v-bind:class="{'btn-warning':form.category_ids.includes(category.id), ' btn-disabled':!form.category_ids.includes(category.id)} "
                                            class="btn btn-fill checkbox-list-button" @click="clickCategory(category.id)">
                                      <span class="checkbox-container"><i class="nc-icon nc-check-2" style="vertical-align: super"
                                                                          v-if="form.category_ids.includes(category.id)"></i> </span>
                                      {{category.name}} {{category.start}}-{{category.end}}
                                    </button>

                                  </div>

                                  <hr>
                                  <div>
                                    <div class="form-group" v-for="category in info.allCategories"
                                         v-if="category.type == 1 && form.category_ids.includes(category.id)">
                                      <label>{{category.name}} {{category.start}}-{{category.end}}</label>

                                      <div class="row">
                                        <div class="col-md-2 col-lg-1"><label>Чоловіки</label></div>
                                        <div class="col-md-10">
                                          <button v-for="maleWeight in category.male"
                                                  v-bind:class="{'btn-warning':checkWeightCategory(category.id, maleWeight, 'ч')} "
                                                  @click="clickWeightCategory(category.id, maleWeight, 'ч')"
                                                  class="btn btn-sm btn-fill checkbox-list-button" style="font-size: 18px;">
                                            <span class="checkbox-container"><i class="nc-icon nc-check-2"
                                                                                style="vertical-align: super"
                                                                                v-if="checkWeightCategory(category.id, maleWeight, 'ч')"></i> </span>
                                            {{maleWeight}}
                                          </button>

                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col-md-2 col-lg-1"><label>Жінки</label></div>
                                        <div class="col-md-10">
                                          <button v-for="maleWeight in category.male"
                                                  v-bind:class="{'btn-warning':checkWeightCategory(category.id, maleWeight, 'ж')} "
                                                  @click="clickWeightCategory(category.id, maleWeight, 'ж')"
                                                  class="btn btn-sm btn-fill checkbox-list-button" style="font-size: 18px;">
                                            <span class="checkbox-container"><i class="nc-icon nc-check-2"
                                                                                style="vertical-align: super"
                                                                                v-if="checkWeightCategory(category.id, maleWeight, 'ж')"></i> </span>
                                            {{maleWeight}}
                                          </button>

                                        </div>
                                      </div>


                                      <hr>
                                    </div>
                                  </div>
                                  <hr>

                                </div>
                              </div>
                            </div>


                            <div class="row" style="display: none">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label>Категорії Пумсе Індивідуальні</label>
                                  <div>
                                    <!--<button class="btn btn-fill btn-primary"><span class="checkbox-container"><i class="nc-icon nc-simple-add" style="vertical-align: super;"></i></span>
                                      Додати свою категорію
                                    </button>-->
                                  </div>
                                  <div style="margin-left: -5px; margin-right: -5px;">
                                    <button v-for="category in info.allCategories" v-if="category.type == 2"
                                            v-bind:class="{'btn-warning':form.category_ids.includes(category.id), ' btn-disabled':!form.category_ids.includes(category.id)} "
                                            class="btn btn-fill checkbox-list-button" @click="clickCategory(category.id)">
                                      <span class="checkbox-container"><i class="nc-icon nc-check-2" style="vertical-align: super"
                                                                          v-if="form.category_ids.includes(category.id)"></i> </span>
                                      {{category.name}} {{category.start}}-{{category.end}}
                                    </button>


                                  </div>

                                  <hr>
                                  <div>
                                    <div class="form-group" v-for="category in info.allCategories"
                                         v-if="category.type == 2 && form.category_ids.includes(category.id)">
                                      <label>{{category.name}} {{category.start}}-{{category.end}}</label>

                                      <div class="row">
                                        <div class="col-md-2 col-lg-1"><label>Чоловіки</label></div>
                                        <div class="col-md-10">
                                          <button v-for="maleWeight in category.male"
                                                  v-bind:class="{'btn-warning':checkWeightCategory(category.id, maleWeight, 'ч')} "
                                                  @click="clickWeightCategory(category.id, maleWeight, 'ч')"
                                                  class="btn btn-sm btn-fill checkbox-list-button" style="font-size: 18px;">
                                            <span class="checkbox-container"><i class="nc-icon nc-check-2"
                                                                                style="vertical-align: super"
                                                                                v-if="checkWeightCategory(category.id, maleWeight, 'ч')"></i> </span>
                                            {{maleWeight}}
                                          </button>

                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col-md-2 col-lg-1"><label>Жінки</label></div>
                                        <div class="col-md-10">
                                          <button v-for="maleWeight in category.male"
                                                  v-bind:class="{'btn-warning':checkWeightCategory(category.id, maleWeight, 'ж')} "
                                                  @click="clickWeightCategory(category.id, maleWeight, 'ж')"
                                                  class="btn btn-sm btn-fill checkbox-list-button" style="font-size: 18px;">
                                            <span class="checkbox-container"><i class="nc-icon nc-check-2"
                                                                                style="vertical-align: super"
                                                                                v-if="checkWeightCategory(category.id, maleWeight, 'ж')"></i> </span>
                                            {{maleWeight}}
                                          </button>

                                        </div>
                                      </div>


                                      <hr>
                                    </div>
                                  </div>
                                  <hr>

                                </div>
                              </div>
                            </div>


                            <div class="row" style="display: none">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <label>Категорії Пумсе Пари</label>
                                  <div>

                                  </div>
                                  <div style="margin-left: -5px; margin-right: -5px;">
                                    <button v-for="category in info.allCategories" v-if="category.type == 3"
                                            v-bind:class="{'btn-warning':form.category_ids.includes(category.id), ' btn-disabled':!form.category_ids.includes(category.id)} "
                                            class="btn btn-fill checkbox-list-button" @click="clickCategory(category.id)">
                                      <span class="checkbox-container"><i class="nc-icon nc-check-2" style="vertical-align: super"
                                                                          v-if="form.category_ids.includes(category.id)"></i> </span>
                                      {{category.name}} {{category.start}}-{{category.end}}
                                    </button>


                                  </div>

                                  <hr>
                                  <div>
                                    <div class="form-group" v-for="category in info.allCategories"
                                         v-if="category.type == 3 && form.category_ids.includes(category.id)">
                                      <label>{{category.name}} {{category.start}}-{{category.end}}</label>

                                      <div class="row">
                                        <div class="col-md-2 col-lg-1"><label>Чоловіки</label></div>
                                        <div class="col-md-10">
                                          <button v-for="maleWeight in category.male"
                                                  v-bind:class="{'btn-warning':checkWeightCategory(category.id, maleWeight, 'ч')} "
                                                  @click="clickWeightCategory(category.id, maleWeight, 'ч')"
                                                  class="btn btn-sm btn-fill checkbox-list-button" style="font-size: 18px;">
                                            <span class="checkbox-container"><i class="nc-icon nc-check-2"
                                                                                style="vertical-align: super"
                                                                                v-if="checkWeightCategory(category.id, maleWeight, 'ч')"></i> </span>
                                            {{maleWeight}}
                                          </button>

                                        </div>
                                      </div>

                                      <div class="row">
                                        <div class="col-md-2 col-lg-1"><label>Жінки</label></div>
                                        <div class="col-md-10">
                                          <button v-for="maleWeight in category.male"
                                                  v-bind:class="{'btn-warning':checkWeightCategory(category.id, maleWeight, 'ж')} "
                                                  @click="clickWeightCategory(category.id, maleWeight, 'ж')"
                                                  class="btn btn-sm btn-fill checkbox-list-button" style="font-size: 18px;">
                                            <span class="checkbox-container"><i class="nc-icon nc-check-2"
                                                                                style="vertical-align: super"
                                                                                v-if="checkWeightCategory(category.id, maleWeight, 'ж')"></i> </span>
                                            {{maleWeight}}
                                          </button>

                                        </div>
                                      </div>


                                      <hr>
                                    </div>
                                  </div>
                                  <hr>

                                </div>
                              </div>
                            </div>


              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Важлива інформація</label>
                    <textarea rows="5" class="form-control border-input"
                              placeholder="Важлива інформація"
                              v-model="form.notes">
              </textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Повний опис/Регламент</label>
                    <textarea rows="5" class="form-control border-input"
                              placeholder="Повний опис"
                              v-model="form.description">
              </textarea>
                  </div>
                </div>
              </div>


              <div class="text-center">
                <button v-if="saving" type="submit" class="btn btn-warning  float-right">
                  Збереження...
                </button>
                <button v-else type="submit" class="btn btn-primary btn-fill float-right"
                        @click.prevent="saveTournament()">
                  Зберегти
                </button>
              </div>
            </form>
          </card>

        </div>
      </div>
    </div>
  </div>

</template>
<script>
import Card from 'src/components/Cards/Card.vue'
import LTable from "@/components/Table";

const tableColumns = ['Id', 'Name', 'Salary', 'Country', 'City'];
const tableData = [{
  id: 1,
  name: 'Dakota Rice',
  salary: '$36.738',
  country: 'Niger',
  city: 'Oud-Turnhout'
},
  {
    id: 2,
    name: 'Minerva Hooper',
    salary: '$23,789',
    country: 'Curaçao',
    city: 'Sinaai-Waas'
  },
  {
    id: 3,
    name: 'Sage Rodriguez',
    salary: '$56,142',
    country: 'Netherlands',
    city: 'Baileux'
  },
  {
    id: 4,
    name: 'Philip Chaney',
    salary: '$38,735',
    country: 'Korea, South',
    city: 'Overland Park'
  },
  {
    id: 5,
    name: 'Doris Greene',
    salary: '$63,542',
    country: 'Malawi',
    city: 'Feldkirchen in Kärnten'
  }
];
export default {
  props: ['id'],
  components: {Card, LTable},
  data() {
    return {
      saving: false,
      table1: {
        columns: [...tableColumns],
        data: [...tableData]
      },
      form: {
        id: this.id,
        name: 'Новий турнір',
        shortName: '',
        notes: '',
        date: '',
        address: '',
        contacts: '',
        manager: '',
        city: '',
        publish: 0,
        protocol_type:  {name: 'З Організаціями', value: 1},
        registration_status:  {name: 'Реєстрація закрита', value: 0},
        type: '',
        description: ``,
        secretaryname: ``,
        judgename: ``,
        category_ids: [],
        category_weigh_ids: []
      },
      categoryList: [
        // type 1 - пумсе
        // sex 1 - male, 2- female, 0 - немає
        // poomsaeType - 1- традиционное, 2 - фристайл,
        // workType - 1- особисті, парні, командні, сімє
        // weight - для пумсе пустое поле всегда
        {id:1, type:1, ageStart:' ', ageStop:' ', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'діти', fullname:'Особисті традиційні діти хлопці'},
        {id:2, type:1, ageStart:' ', ageStop:' ', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'діти', fullname:'Особисті традиційні діти дівчата'},
        {id:3, type:1, ageStart:' ', ageStop:' ', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні молодші юнаки хлопці'},
        {id:4, type:1, ageStart:' ', ageStop:' ', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні молодші юнаки  дівчата'},
        {id:5, type:1, ageStart:' ', ageStop:' ', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні юнаки хлопці'},
        {id:6, type:1, ageStart:' ', ageStop:' ', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні юнаки дівчата'},
        {id:7, type:1, ageStart:' ', ageStop:' ', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні кадети хлопці'},
        {id:8, type:1, ageStart:' ', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні кадети дівчата'},
        {id:9, type:1, ageStart:' ', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні юніори чоловіки'},
        {id:10, type:1, ageStart:' ', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні юніори дівчата'},
        {id:11, type:1, ageStart:' ', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні чоловіки до 30'},
        {id:12, type:1, ageStart:' ', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні жінки до 30'},
        {id:13, type:1, ageStart:' ', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні чоловіки до 40'},
        {id:14, type:1, ageStart:' ', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні жінки до 40'},
        {id:15, type:1, ageStart:' ', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні чоловіки до 50'},
        {id:16, type:1, ageStart:' ', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні жінки до 50'},
        {id:17, type:1, ageStart:' ', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні чоловіки 51+'},
        {id:18, type:1, ageStart:' ', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні жінки 51+'},
        {id:19, type:1, ageStart:' ', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні жінки 31+'},
        {id:20, type:1, ageStart:' ', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Особисті традиційні чоловіки 31+'},
        {id:21, type:1, ageStart:' ', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Парні традиційні діти'},
        {id:22, type:1, ageStart:' ', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Парні традиційні молодші юнаки'},
        {id:23, type:1, ageStart:' ', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Парні традиційні юнаки'},
        {id:24, type:1, ageStart:' ', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Парні традиційні кадети'},
        {id:25, type:1, ageStart:' ', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Парні традиційні юніори'},
        {id:26, type:1, ageStart:' ', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Парні традиційні до 30'},
        {id:27, type:1, ageStart:' ', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Парні традиційні до 40'},
        {id:28, type:1, ageStart:' ', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Парні традиційні до 50'},
        {id:29, type:1, ageStart:' ', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Парні традиційні 51+'},
        {id:30, type:1, ageStart:' ', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Парні традиційні 31+'},
        {id:31, type:1, ageStart:' ', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні діти хлопці'},
        {id:32, type:1, ageStart:' ', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні діти дівчата '},
        {id:33, type:1, ageStart:'', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні молодші юнаки хлопці'},
        {id:34, type:1, ageStart:'', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні молодші юнаки дівчата'},
        {id:35, type:1, ageStart:'', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні юнаки хлопці'},
        {id:36, type:1, ageStart:'', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні юнаки дівчата'},
        {id:37, type:1, ageStart:'', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні кадети хлопці'},
        {id:38, type:1, ageStart:'', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні кадети дівчата'},
        {id:39, type:1, ageStart:'', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні юніори чоловіки'},
        {id:40, type:1, ageStart:'', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні юніори дівчата'},
        {id:41, type:1, ageStart:'', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні чоловіки  до 30'},
        {id:42, type:1, ageStart:'', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні жінки до 30'},
        {id:43, type:1, ageStart:'', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні чоловіки  до 40'},
        {id:44, type:1, ageStart:'', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні жінки до 40'},
        {id:45, type:1, ageStart:'', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні чоловіки  до 50'},
        {id:46, type:1, ageStart:'', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні жінки до 50'},
        {id:47, type:1, ageStart:'', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні чоловіки  51+'},
        {id:48, type:1, ageStart:'', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні жінки 51+'},
        {id:49, type:1, ageStart:'', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні чоловіки  31+'},
        {id:50, type:1, ageStart:'', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні традиційні жінки 31+'},
        {id:51, type:1, ageStart:'', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Індивідуальні вільні до 12 хлопці'},
        {id:52, type:1, ageStart:'', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Індивідуальні вільні до 17 хлопці'},
        {id:53, type:1, ageStart:'', ageStop:'', sex:1,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Індивідуальні вільні 18+ хлопці'},
        {id:54, type:1, ageStart:'', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Індивідуальні вільні до 12 дівчата'},
        {id:55, type:1, ageStart:'', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Індивідуальні вільні до 17 дівчата'},
        {id:56, type:1, ageStart:'', ageStop:'', sex:2,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Індивідуальні вільні 18+ дівчата'},
        {id:57, type:1, ageStart:'', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Парні вільні до 12'},
        {id:58, type:1, ageStart:'', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Парні вільні до 17'},
        {id:59, type:1, ageStart:'', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Парні вільні 18+'},
        {id:60, type:1, ageStart:'', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні вільні до 12'},
        {id:61, type:1, ageStart:'', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні вільні до 17'},
        {id:62, type:1, ageStart:'', ageStop:'', sex:0,  weight:'', poomsaeType:1,  workType:1,  poomsae1:'', poomsae2:'', name:'молодші юнаки', fullname:'Командні вільні 18+'},
      ],
      info: {
        cityList: [
          {name: 'Вінниця'},
          {name: 'Київ'},
          {name: 'Київська область'},
          {name: 'Рівне'},
          {name: 'Івано-Франківськ'},
          {name: 'Луцьк'},
          {name: 'Львів'},
          {name: 'Харків'},
          {name: 'Хмельницьк'},
          {name: 'Кам\'янець-Подільський'},
        ],

        publishList: [
          {name: 'Опублікувати', value: 1},
          {name: 'Не публікувати', value: 0},
        ],

        protocolList: [
          {name: 'З Організаціями', value: 1},
          {name: 'З Областями', value: 2},
        ],
        registrationStatusList: [
          {name: 'Реєстрація відкрита', value: 1},
          {name: 'Реєстрація закрита', value: 0},
        ],

        categoryTypes: [
          {
            id: 1,
            type: 1,
            name: 'Керугі',
            ageTypes: [
              {id: 1, name: 'Діти'}
            ],

          },
          {type: 2, name: 'Пумсе'},
        ],
        allCategories: [

          {
            id: 1,
            name: 'Діти',
            start: '2012',
            end: '2013',
            type: '1', // kerugi
            male: ['-18', '-20', '-22', '-24', '-26', '-28', '-30', '-32', '-34', '-37', '-41', '41+'],
            female: ['-18', '-20', '-22', '-24', '-26', '-28', '-30', '-32', '-34', '-37', '-41', '41+'],
            any: []
          },
          {
            id: 2,
            name: 'Молодші юнаки',
            start: '2010',
            end: '2011',
            type: '1', // kerugi

            male: ['-18', '-20', '-22', '-24', '-26', '-28', '-30', '-32', '-34', '-37', '-41', '41+'],
            female: ['-18', '-20', '-22', '-24', '-26', '-28', '-30', '-32', '-34', '-37', '-41', '41+'],
            any: []
          },
          {
            id: 3,
            name: 'Юнаки',
            start: '2008',
            end: '2009',
            type: '1', // kerugi
            male: ['-27', '-30', '-33', '-36', '-40', '-44', '-48', '-52', '-57', '57+'],
            female: ['-18', '-20', '-22', '-24', '-26', '-28', '-30', '-32', '-34', '-37', '-41', '41+'],
            any: []
          },
          {
            id: 4,
            name: 'Кадети',
            start: '2005',
            end: '2007',
            type: '1', // kerugi
            male: ['-18', '-20', '-22', '-24', '-26', '-28', '-30', '-32', '-34', '-37', '-41', '41+'],
            female: ['-18', '-20', '-22', '-24', '-26', '-28', '-30', '-32', '-34', '-37', '-41', '41+'],
            any: []
          },
          {
            id: 5,
            name: 'Юніори',
            start: '2002',
            end: '2004',
            type: '1', // kerugi
            male: ['-45', '-48', '-51', '-55', '-59', '-63', '-68', '-73', '-78', '78+'],
            female: ['-42', '-44', '-46', '-49', '-52', '-55', '-59', '-63', '-68', '68+'],
            any: []
          },
          {
            id: 6,
            name: 'Дорослі',
            start: '2004',
            end: '2009',
            type: '1', // kerugi

            male: ['-54', '-58', '-63', '-68', '-74', '-80', '-87', '87+'],
            female: ['-46', '-49', '-53', '-57', '-62', '-67', '-73', '73+'],
            any: []
          },

        ]
      }
    }
  },
  computed: {
    tournamentOne() {
      return this.$store.getters['tournament/tournamentOne'];
    },

    categoriesList() {
      return this.$store.getters['data/categories'];
    },

    categoriesTable() {
      let result = [];
      if (this.categoriesList) {
        // result.

      }

      return result;
    }
  },
  watch: {
    tournamentOne() {
      if (this.tournamentOne.data !== undefined && this.tournamentOne.data.item) {
        this.form.name = this.tournamentOne.data.item.name;
        this.form.address = this.tournamentOne.data.item.address;
        this.form.date = this.tournamentOne.data.item.date;
        this.form.contacts = this.tournamentOne.data.item.contacts;
        this.form.manager = this.tournamentOne.data.item.manager;
        this.form.description = this.tournamentOne.data.item.description;
        this.form.shortName = this.tournamentOne.data.item.short_name;
        this.form.notes = this.tournamentOne.data.item.notes;
        this.form.secretaryname = this.tournamentOne.data.item.secretaryname;
        this.form.judgename = this.tournamentOne.data.item.judgename;

        if (this.tournamentOne.data.item.category_ids.length > 0) {
          this.form.category_ids = this.tournamentOne.data.item.category_ids.split(',');
          for (let ik in this.form.category_ids) {
            this.form.category_ids[ik] = parseInt(this.form.category_ids[ik]);
          }
        }

        for (let i in this.info.cityList) {
          if (this.info.cityList[i].name == this.tournamentOne.data.item.city) {
            this.form.city = this.info.cityList[i];
          }
        }

        for (let i in this.info.publishList) {
          if (this.info.publishList[i].value == this.tournamentOne.data.item.published) {
            this.form.publish = this.info.publishList[i];
          }
        }

        for (let i in this.info.registrationStatusList) {
          if (this.info.registrationStatusList[i].value == this.tournamentOne.data.item.registration_status) {
            this.form.registration_status = this.info.registrationStatusList[i];
          }
        }

        for (let i in this.info.protocolList) {
          if (this.info.protocolList[i].value == this.tournamentOne.data.item.protocol_type) {
            this.form.protocol_type = this.info.protocolList[i];
          }
        }


      }
    },
  },
  mounted() {
    if (this.form.id != undefined) {
      this.$store.dispatch('tournament/fetchTournamentOne', {id: this.id});
    }
  },
  methods: {
    successfulSaved() {


      if (this.tournamentOne.status == 200 && this.tournamentOne.data.item.id > 0) {
        // this.$router.replace({path: `/user/tournaments/`}) // -> open tournament
        this.$router.replace({ path: `/user/tournaments/overview/${this.tournamentOne.data.item.id}` }) // -> open tournament
      }
      console.log('tournamentOne');
      console.log(this.tournamentOne);

      console.log('successfulSaved');
      this.$notify({
        group: 'foo',
        title: 'Турнір збережено',
        type: 'success',
      });
    },

    faileSaved() {
      this.$notify({
        group: 'foo',
        title: 'Помилка. Турнір не збережено...',
        type: 'error',
      });
    },

    updateProfile() {
      alert('Your data: ' + JSON.stringify(this.user))
    },
    saveTournament() {
      // this.saving = true;
      var params = Object.assign({}, this.form);
      params.category_ids = params.category_ids.toString();
      params.protocol_type = this.form.protocol_type.value;
      params.registration_status = this.form.registration_status.value;
      params.city = params.city.name;
      params.short_name = params.shortName;
      params.published = params.publish.value;
      params.allCategories = JSON.stringify(this.info.allCategories);
      params.id = this.id;
      console.log('saveTournament', params);

      if (params.id != undefined) {
        this.$store.dispatch('tournament/updateTournament', params)
            .then(() => this.successfulSaved())
            .catch(error => {
              this.faileSaved()
            });

        // TODO добавить обработку ошибок


        // this.$store.dispatch('tournament/updateTournament', params)
        //   .then(response => console.log(response))
        //   .catch(error => console.log(error));


        //   .then(() => this.successfulSaved())
        //    .catch(error => {
        //   console.log(error);
        //   this.faileSaved()
        // });
      } else {
        this.$store.dispatch('tournament/createTournament', params)
            .then(() => this.successfulSaved())
            .catch(error => {
              this.faileSaved()
            });
      }


    },
    clickCategory(id) {
      console.log('clickCategory');

      if (this.form.category_ids.includes(id)) {
        this.form.category_ids.splice(this.form.category_ids.indexOf(id), 1)
      } else {
        this.form.category_ids.push(id);
        // по умолчанию включаем все весовки
        // category.id, maleWeight, 'ч'

        for (let i = 0; i < this.info.allCategories.length; i++) {
          if (this.info.allCategories[i].id == id) {

            for (let ik = 0; ik < this.info.allCategories[i].male.length; ik++) {
              let idKeyM = id + '_' + this.info.allCategories[i].male[ik] + '_' + 'ч';
              this.form.category_weigh_ids.push(idKeyM);
            }
            for (let ik = 0; ik < this.info.allCategories[i].female.length; ik++) {
              let idKeyF = id + '_' + this.info.allCategories[i].female[ik] + '_' + 'ж';
              this.form.category_weigh_ids.push(idKeyF);
            }

            break;
          }

        }

      }
    },


    clickWeightCategory(category, maleWeight, sex) {

      let id = category + '_' + maleWeight + '_' + sex;
      if (this.form.category_weigh_ids.includes(id)) {
        this.form.category_weigh_ids.splice(this.form.category_weigh_ids.indexOf(id), 1)
      } else {
        this.form.category_weigh_ids.push(id);
      }


      return true;
// console.log(this.form.category_weigh_ids);
      let weightCategory = {category: category, maleWeight: maleWeight, sex: sex};

      // console.log(weightCategory);
      // if (this.form.category_weigh_ids.includes(weightCategory)) {
      //   this.form.category_weigh_ids.splice(this.form.category_weigh_ids.indexOf(weightCategory), 1)
      // } else {
      //   this.form.category_weigh_ids.push(weightCategory)
      // }
      //
      //

      // перебираем все выбранные категории
      let add = true;
      for (let i = 0; i < this.form.category_weigh_ids.length; i++) {
        if (this.form.category_weigh_ids[i].category == category && this.form.category_weigh_ids[i].maleWeight == maleWeight && this.form.category_weigh_ids[i].sex == sex) {
          add = false;
          // console.log('remnove i');
          // console.log(this.form.category_weigh_ids[i]);
          this.form.category_weigh_ids.splice(i, 1);
          // console.log(weightCategory);
        }
      }

      if (add === true) {
        this.form.category_weigh_ids.push(weightCategory)
      }

    },

    // проверка выбрана ли уже категория
    checkWeightCategory(category, maleWeight, sex) {
      let id = category + '_' + maleWeight + '_' + sex;


      return this.form.category_weigh_ids.includes(id);

      // перебираем все выбранные категории
      for (let i = 0; i < this.form.category_weigh_ids.length; i++) {
        // console.log(this.form.category_weigh_ids[i]);
        if (this.form.category_weigh_ids[i].category == category && this.form.category_weigh_ids[i].maleWeight == maleWeight && this.form.category_weigh_ids[i].sex == sex) {
          return true;
        }
      }
      return false;
    },
  }
}

</script>
<style>
.checkbox-list-button {
  margin: 5px;
}

.checkbox-list-button > span > i {
  font-weight: bold;
  vertical-align: super;
}

.checkbox-container {
  border: 1px solid rgb(204, 204, 204);
  padding: 3px;
  display: inline-block;
  width: 25px;
  height: 25px;
  vertical-align: middle;
}
</style>
