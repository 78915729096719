var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticClass:"c_right"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row row-lg-eq-height"},[_vm._m(1),_c('div',{staticClass:"col-lg-6 order-lg-2 order-1"},[_c('div',{staticClass:"c_right_content"},[_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"button c_right_button"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.login()}}},[_vm._v("Логін")])])])])])])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home",staticStyle:{"max-height":"540px"}},[_c('div',{staticClass:"parallax_background parallax-window",staticStyle:{"background-image":"url(/images/banner.jpg)","background-size":"cover","background-repeat":"repeat-x","position":"absolute","top":"100px","height":"calc(100% - 100px)"},attrs:{"data-v-5a90ec03":"","data-parallax":"scroll","data-image-src":"images/index.jpg","data-speed":"0.8"}}),_c('div',{staticClass:"home_container"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"home_content text-center"},[_c('div',{staticClass:"home_title"},[_vm._v("Taekwondo in UKRAINE")])])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-6 c_right_col order-lg-1 order-2"},[_c('div',{staticClass:"c_right_image d-flex flex-column align-items-center justify-content-center"},[_c('img',{attrs:{"src":"images/pic_1.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"section_title_container"},[_c('div',{staticClass:"section_title"},[_c('h2',[_vm._v("EasyTournament")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_right_text"},[_c('p',[_vm._v("Сервіс допомагає організувати проведення чемпіонатів. Організатор може в режимі реального часу слідкувати за ходом проведння турнірів, змагань.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content_list_1 c_right_list"},[_c('ul',[_c('li',[_vm._v("Реєстрація учасників")]),_c('li',[_vm._v("Допомога в проведенні жеребкування")]),_c('li',[_vm._v("Швидке формування необхідної звітності")]),_c('li',[_vm._v("Проведення закритих(приватних) турнірів")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_left"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row row-lg-eq-height"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"c_left_content"},[_c('div',{staticClass:"section_title_container"},[_c('div',{staticClass:"section_title"},[_c('h2',[_vm._v("EasyPoomsae")])])]),_c('div',{staticClass:"content_list_2 c_left_list"},[_c('ul',[_c('li',{staticClass:"d-flex flex-row align-items-start justify-content-start"},[_c('div',[_c('div',[_vm._v("01.")])]),_c('p',[_vm._v("Сервіс дозволяє провести онлайн реєстрацію учасників чемпіонату з тхеквондо в розділі Poomsae.")])]),_c('li',{staticClass:"d-flex flex-row align-items-start justify-content-start"},[_c('div',[_c('div',[_vm._v("02.")])]),_c('p',[_vm._v("Є можливість проведення індивідуальних та командних змагань з традиційного та вільного пумсе")])]),_c('li',{staticClass:"d-flex flex-row align-items-start justify-content-start"},[_c('div',[_c('div',[_vm._v("03.")])]),_c('p',[_vm._v("Одразу після виступу учасника відбувається оцінювання суддями виступу та виведення результатів спортсмена. Даний функціонал забезпечує прозорість оцінювання виступу спортсмена.")])])])])])]),_c('div',{staticClass:"col-lg-6 c_left_col"},[_c('div',{staticClass:"c_left_image d-flex flex-column align-items-center justify-content-center"},[_c('img',{attrs:{"src":"images/pic_2.png","alt":""}})])])])])])
}]

export { render, staticRenderFns }