import DataAPI from '../api/data';

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    categories: {}
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    categories(state) {
      return state.categories;
    },
  },
  mutations: {

    ['FETCHING_CATEGORIES'](state) {
      state.isLoading = true;
      state.error = null;
      state.categories = {};
    },
    ['FETCHING_CATEGORIES_SUCCESS'](state, categories) {
      state.isLoading = false;
      state.error = null;
      console.log(state.categories);
      state.categories = categories;
    },
    ['FETCHING_CATEGORIES_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
      state.categories = {};
    },
  },
  actions: {
    fetchCategories({commit}) {
      commit('FETCHING_CATEGORIES');
      return DataAPI.getCategories()
        .then(res => commit('FETCHING_CATEGORIES_SUCCESS', res.data))
        .catch(err => commit('FETCHING_CATEGORIES_ERROR', err));
    },

  },
}
