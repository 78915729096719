<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive"
          >
            <template slot="header">
              <h4 class="card-title">Список турнірів</h4>
              <p class="card-category">для створення нового турніру натисніть на кнопку
                <router-link class="btn btn-sm btn-primary btn-fill " :to="{path:'/user/tournaments/create'}"><i
                  class="nc-icon nc-simple-add" style="vertical-align: sub;"></i> Створити новий турнір
                </router-link>
              </p>
              <br>
            </template>
          </card>

        </div>
      </div>

      <div>

        <ul class="nav nav-tabs " style="">
          <li class="nav-item">
            <a href="javascript:;" class="nav-link " v-bind:class="{'active':activeTab == 1}"
               v-on:click="setActiveTournamentListTab(1)">Мої турнiри</a>
          </li>

        </ul>

      </div>


      <div class="row">
        <div class="col-12">
          <card class="strpied-tabled-with-hover" body-classes="table-full-width table-responsive"
          >

            <table style="user-select: none;" class="table table-hover table-striped_" v-if="tournaments.data">
              <thead>
              <slot name="columns">
                <tr>
                  <th>ID</th>
                  <th>Назва</th>
<!--                  <th>Категорії</th>-->
<!--                  <th>Учасники</th>-->
                  <th>Місто</th>
                  <th>Дата</th>

                  <th style="text-align: right;"></th>
                </tr>
              </slot>
              </thead>
              <tbody>
              <tr v-for="(item, index) in tournaments.data.list" :key="index">
                <td>{{ item.id }}</td>
                <td>
                  <div :title="item.name" style="  width: 100%; max-width: 350px;
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis; ">{{ item.name }}<span class="text-danger" v-if="!item.name">не вказано</span></div>

                </td>

                <td>{{ item.city }}<span class="text-danger" v-if="!item.city">не вказано</span></td>
                <td>{{ item.date }}<span class="text-danger" v-if="!item.date">не вказано</span></td>

                <td style="justify-content: right;" v-if="userOne && item.user == userOne.data.user.id">
                  <div class="action-buttons-wrapper">
                    <a :href="'/user/tournaments/overview/'+item.id" class="btn btn-sm btn-primary btn-fill "
                            @click="openTournament(item.id)">
                      <i class="nc-icon nc-album-2"  ></i>
                      <span>Відкрити</span>
                    </a>

<!--                    <button class="btn btn-sm  btn-primary btn-fill "-->
<!--                            @click="openTournament(item.id)">-->
<!--                      <i class="nc-icon nc-album-2"  ></i>-->
<!--                      <span>Відкрити</span>-->
<!--                    </button>-->

<!--                    <button class="btn btn-sm  btn-warning btn-fill"-->
<!--                            @click="updateTournament(item.id)">-->
<!--                      <i class="nc-icon nc-ruler-pencil"  ></i> Змінити-->
<!--                    </button>-->

                    <button class="btn btn-sm btn-danger btn-fill     "
                            @click="deleteTournament(item.id)"><i class="nc-icon nc-simple-remove"
                                                                   ></i>Видалити
                    </button>
                  </div>

                </td>

                <td v-else></td>
              </tr>
              </tbody>
            </table>

            <loader v-else></loader>

          </card>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LTable from 'src/components/Table.vue'
import Card from 'src/components/Cards/Card.vue'
import Loader from 'src/components/Elements/Loader/Ring.vue'

export default {
  components: {
    LTable,
    Card,
    Loader
  },
  data() {
    return {}
  },
  computed: {
    isLoading() {
      return this.$store.getters['tournament/isLoading'];
    },
    hasError() {
      return this.$store.getters['tournament/hasError'];
    },
    error() {
      return this.$store.getters['tournament/error'];
    },
    hasTournaments() {
      return this.$store.getters['tournament/hasTournaments'];
    },
    tournaments() {
      return this.$store.getters['tournament/tournaments'];
    },

    activeTab() {
      return this.$store.getters['settings/activeTournamentListTab'];
    },
    userOne() {
      let user = this.$store.getters['user/userOne'];
      console.log('user', user);
      return this.$store.getters['user/userOne'];
    },
  },
  mounted() {
    this.$store.dispatch('tournament/fetchTournaments')
      .then(() => this.$data.message = '');
  },
  methods: {
    openTournament(id) {
      this.$router.replace({path: `/user/tournaments/overview/${id}`}) // -> /user/123
    },
    updateTournament(id) {
      this.$router.replace({path: `/user/tournaments/update/${id}`}) // -> /user/123
      // this.$router.push({ name: 'TournamentFormUpdate', params: {id} });
      // router.push({ path: `/user/tournaments/update/1` })
    },
    deleteTournament(id) {
      if (window.confirm("Ви впевнені, що хочете видали турнір?")) {
        let params = {'id': id};
        this.$store.dispatch('tournament/deleteTournament', params)
          .then(() => this.deletedTournament(id));
      }

    },
    deletedTournament(id) {
      this.$notify(
        {
          text: 'Турнір видалено',
          horizontalAlign: 'right',
          verticalAlign: 'top',
          duration: 10000,
          speed: 1000,
          type: 'success'
        })

      this.deleteFromList(id);
    },
    deleteFromList(id) {
      let length = this.tournaments.data.list.length;
      for (let i = 0; i < length; i++) {
        console.log(this.tournaments.data.list[i].id);
        if (this.tournaments.data.list[i].id === id) {
          this.tournaments.data.list.splice(i, 1);
          break;
        }
      }

    },

    setActiveTournamentListTab(position) {
      this.$store.dispatch('settings/setActiveTournamentListTab', position);
    },

  }
}
</script>
<style>
</style>
