<template>
  <div class="row new-create-update-form" v-if="actionTournamentAddCategory">
    <div class="col-md-12">
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <base-input type="text"
                      label="Назва"
                      placeholder=""
                      v-model="formAddCategory.name">
          </base-input>
        </div>

        <div class="col-lg-6 col-sm-12">
          <base-input type="text"
                      label="Вікова категорія"
                      placeholder=""
                      v-model="formAddCategory.agecategory">
          </base-input>
        </div>

        <div class="col-lg-1 col-sm-6">
          <base-input type="number" min="0" max="100"
                      label="Вік від"
                      placeholder=""
                      v-model="formAddCategory.age1">
          </base-input>
        </div>
        <div class="col-lg-1 col-sm-6">
          <base-input type="number" min="0" max="100"
                      label="Вік до"
                      placeholder=""
                      v-model="formAddCategory.age2">
          </base-input>
        </div>

        <div class="col-lg-1 col-sm-6">
          <base-input type="number" min="0" max="100"
                      label="Вага від"
                      placeholder=""
                      v-model="formAddCategory.weight1">
          </base-input>
        </div>
        <div class="col-lg-1 col-sm-6">
          <base-input type="number" min="0" max="100"
                      label="Вага до"
                      placeholder=""
                      v-model="formAddCategory.weight2">
          </base-input>
        </div>

        <div class="col-lg-2 col-sm-12">

          <div class="form-group"><label class="control-label">
            Стать
          </label>
            <v-select label="name" :filterable="true" :options="[{id:0, name:'-'}, {id:1, name:'чоловік'}, {id:2, name:'жінка'}]"
                      v-model="formAddCategory.sex">
              <template slot="no-options">Укажіть стать
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <span class="name-city">{{ option.name }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  <span class="name-city">{{ option.name }}</span>
                </div>
              </template>
            </v-select>
          </div>

        </div>

        <div class="col-lg-2 col-sm-12">
          <div class="form-group"><label class="control-label">
            Розділ
          </label>
            <v-select label="name" :filterable="false" :options="[{id:1, name:'Керугі'}, {id:2, name:'Пумсе'}]"
                      v-model="formAddCategory.type">
              <template slot="no-options">Укажіть Розділ
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <span class="name-city">{{ option.name }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  <span class="name-city">{{ option.name }}</span>
                </div>
              </template>
            </v-select>
          </div>

        </div>


        <div class="col-lg-2 col-sm-12">
          <div class="form-group"><label class="control-label">
            Тип
          </label>
            <v-select label="name" :filterable="false" :options="[{id:1, name:'Сітка'}, {id:2, name:'Список'}]"
                      v-model="formAddCategory.showType">
              <template slot="no-options">Укажіть тип
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <span class="name-city">{{ option.name }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  <span class="name-city">{{ option.name }}</span>
                </div>
              </template>
            </v-select>
          </div>

        </div>


        <div class="col-lg-2 col-sm-12" v-if="formAddCategory.type == 2 || (formAddCategory.type.id != undefined && formAddCategory.type.id == 2)">
          <div class="form-group"><label class="control-label">
            Вид пумсе
          </label>
            <v-select label="name" :filterable="false" :options="[{id:1, name:'Традиційне'}, {id:2, name:'Вільне (фрістайл)'}]"
                      v-model="formAddCategory.poomsaeType">
              <template slot="no-options">Укажіть Вид пумсе
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <span class="name-city">{{ option.name }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  <span class="name-city">{{ option.name }}</span>
                </div>
              </template>
            </v-select>
          </div>
        </div>


        <div class="col-lg-2 col-sm-12">

          <div class="form-group"><label class="control-label">
            Відділення
          </label>
            <v-select label="name" :filterable="true"
                      :options="tournamentOne.data.item.sections"
                      v-model="formAddCategory.section">
              <template slot="no-options">Укажіть відділення
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <span class="name-city">{{ option.name }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  <span class="name-city">{{ option.name }}</span>
                </div>
              </template>
            </v-select>
          </div>

        </div>


        <div class="col-lg-2 col-sm-12">

          <div class="form-group"><label class="control-label">
            Статус
          </label>
            <v-select label="name" :filterable="true" :options="[{id:0, name:'-'}, {id:1, name:'Опубліковано'}, {id:2, name:'Чернетка'}]"
                      v-model="formAddCategory.categoryStatus">
              <template slot="no-options">Укажіть статус категорії
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <span class="name-category-status">{{ option.name }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  <span class="name-category-status">{{ option.name }}</span>
                </div>
              </template>
            </v-select>
          </div>

        </div>



      </div>

      <div class="row">
        <div class="col-md-3">
          <button @click="saveCategoryAndAdd()" class="btn btn-sm btn-primary btn-fill  "  style="margin: 10px 10px 10px 10px;" >
            <i class="nc-icon nc-cloud-upload-94" style="vertical-align: sub;"></i> Зберегти</button>

          <button @click="saveCategoryClose()" class="btn btn-sm btn-dark btn-fill  "  style="margin: 10px 10px 10px 10px;" >
            <i class="nc-icon nc-simple-remove" style="vertical-align: sub;"></i> Закрити</button>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: "form-category",
    props: ['formAddCategory'],


    computed: {
      tournamentOne() {
        return this.$store.getters['tournament/tournamentOne'];
      },
      actionTournamentAddCategory() {
        return this.$store.getters['settings/actionTournamentAddCategory'];
      },
    },

    methods:{



      saveCategoryClose(){
        // this.actions.addCategory = false;
        this.$modal.hide('category-form-modal');
        // this.actions.addCategory = false;
      },
      saveCategoryAndAdd() {
        this.saveCategory(false);
      },

      saveCategoryAndClose() {
        this.saveCategory(true);
      },


      saveCategory(close) {
        var params = Object.assign({}, this.formAddCategory);
        params.tournament = this.tournamentOne.data.item.id;
        params.sex = params.sex.id == undefined ? 0: params.sex.id;
        params.type = params.type.id == undefined ? 0: params.type.id;
        params.poomsaeType = params.poomsaeType.id == undefined ? 0: params.poomsaeType.id;
        params.categoryStatus = params.categoryStatus.id == undefined ? 0: params.categoryStatus.id;
        params.showType = params.showType.id == undefined ? 0: params.showType.id;

        if(params.name.length < 1){
          alert('Укажіть назву категорії');
          return false;
        }

        if(params.type == 0){
          alert('Укажіть розділ - керугі чи пумсе');
          return false;
        }

        if(params.showType < 1){
          alert('Укажіть тип категорії - сітка чи список');
          return false;
        }

        // якщо це пумсе то треба указати яке
        if(params.type == 2 && params.poomsaeType < 1){
          alert('Укажіть вид пумсе');
          return false;
        }


        if(params.categoryStatus < 1){
          alert('Укажіть статус категорії');
          return false;
        }

        // если сначал была выбрано пумсе потом поменяли на керуги - скинуть данные о выбраном пумсе
        if(params.type == 1){
          params.poomsaeType = 0;
        }

        console.log(params);


        if (params.id != undefined) {
          this.$store.dispatch('tournament/addCategory', params)
            .then(() => this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.tournamentOne.data.item.id}))
            .catch(error => {
              alert('Помилка');
            });

          // TODO добавить обработку ошибок

        } else {

          // .then(() => this.successfulSaved())
          // .catch(error => {
          //   this.faileSaved()
          // });
        }

        // this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.tournamentOne.data.item.id});
        this.$modal.hide('category-form-modal');
      },

    }
  }
</script>

<style scoped>

</style>
