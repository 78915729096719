<template>
  <div class="content_">

    <div class="container-fluid_ poomsae-queues" >
      <div class="card" style="padding: 10px 10px 10px 10px">
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-fill btn-sm" @click="addHeight()">Додати висоту</button>
          </div>
        </div>
      <div class="row">
        <div class="col-md-3" v-for="heigh in heightList">
          <h4 class="title"><input style="width: 100%" type="text" v-model="heigh.name"></h4>
          <div class="previous-queue poomsae-queue" >

            <div class="sportsman" style=" padding: 5px; border-bottom: 1px dotted #ccc">Шерлок Холмс
              <div class="steps">
                <div class="step" style="background: #04ff04; display: inline-block; border-radius: 50%; width: 40px; height: 40px;  border: 1px solid rgb(0, 0, 0); position: relative;/*! transform: rotate(30dg); *//*! transform: rotate(45deg); *//*! transform: rotate(45deg); */"></div>
                <div class="step" style=" display: inline-block; border-radius: 50%; width: 40px; height: 40px; background: rgb(255, 255, 255) none repeat scroll 0% 0%; border: 1px solid rgb(0, 0, 0); position: relative;/*! transform: rotate(30dg); *//*! transform: rotate(45deg); *//*! transform: rotate(45deg); */"></div>
                <div class="step" style="display: inline-block; border-radius: 50%; width: 40px; height: 40px; background: rgb(255, 255, 255) none repeat scroll 0% 0%; border: 1px solid rgb(0, 0, 0); position: relative;/*! transform: rotate(30dg); *//*! transform: rotate(45deg); *//*! transform: rotate(45deg); */"></div>
              </div>
            </div>


            <div class="sportsman" style=" padding: 5px; border-bottom: 1px dotted #ccc">Шерлок Холмс
            <div class="steps">
              <div class="step" style="display: inline-block; border-radius: 50%; width: 40px; height: 40px; background: rgb(255, 255, 255) none repeat scroll 0% 0%; border: 1px solid rgb(0, 0, 0); position: relative;/*! transform: rotate(30dg); *//*! transform: rotate(45deg); *//*! transform: rotate(45deg); */"><div class="red-line" style="position: absolute; width: 50px; height: 2px; background: red none repeat scroll 0% 0%;transform: rotate(-45deg);left: -5px;top: 20px;"></div><div class="red-line" style="position: absolute; width: 50px; height: 2px; background: red none repeat scroll 0% 0%;transform: rotate(45deg);left: -5px;top: 20px;"></div></div>
              <div class="step" style=" display: inline-block; border-radius: 50%; width: 40px; height: 40px; background: rgb(255, 255, 255) none repeat scroll 0% 0%; border: 1px solid rgb(0, 0, 0); position: relative;/*! transform: rotate(30dg); *//*! transform: rotate(45deg); *//*! transform: rotate(45deg); */"><div class="red-line" style="position: absolute; width: 50px; height: 2px; background: red none repeat scroll 0% 0%;transform: rotate(-45deg);left: -5px;top: 20px;"></div><div class="red-line" style="position: absolute; width: 50px; height: 2px; background: red none repeat scroll 0% 0%;transform: rotate(45deg);left: -5px;top: 20px;"></div></div>
              <div class="step" style="background: #04ff04; display: inline-block; border-radius: 50%; width: 40px; height: 40px;  border: 1px solid rgb(0, 0, 0); position: relative;/*! transform: rotate(30dg); *//*! transform: rotate(45deg); *//*! transform: rotate(45deg); */"></div>
            </div>
            </div>
            <div class="sportsman" style=" padding: 5px; border-bottom: 1px dotted #ccc">Шерлок Холмс1</div>
            <div class="sportsman" style=" padding: 5px; border-bottom: 1px dotted #ccc">Шерлок Холмс2</div>
            <div class="sportsman" style=" padding: 5px; border-bottom: 1px dotted #ccc">Шерлок Холмс3</div>
            <div class="sportsman" style=" padding: 5px; border-bottom: 1px dotted #ccc">Шерлок Холмс4</div>
            <div class="sportsman" style=" padding: 5px; border-bottom: 1px dotted #ccc">Шерлок Холмс5</div>
            <div class="sportsman" style=" padding: 5px; border-bottom: 1px dotted #ccc">Шерлок Холмс6</div>
            <div class="sportsman" style=" padding: 5px; border-bottom: 1px dotted #ccc">Шерлок Холмс7</div>
          </div>
        </div>



      </div>
      </div>
    </div>



  </div>
</template>

<script>
  // розбивання дошок
  import html2canvas from 'html2canvas';
  import {Sortable, Plugins, DragStartEvent, DragStopEvent} from '@shopify/draggable';
  import bracketWinners from './bracket-winners.vue';
  export default {
    name: 'list-constructor-new',
    components: {
      Sortable, Plugins, DragStartEvent, DragStopEvent, 'bracket-winners':bracketWinners
    },
    props:{
      selectedDrawCategory:Object
    },
    data() {
      return {
        swappable: null,
        heightList:[
          {
            name:' Висота 1 м',
            sportsmans:[],
          },

        ],

        results:{
          protocol_type:'list',
          tournament_id:'',
          protocolid_id:'',
          start_round:'',
          plases:{
            place1:'',
            place2:'',
          },
          rounds:[
            {
              type:'preround',
              sps:[
                {
                  sp_id: '',
                  name: '',
                  oblast: '',
                  organization: '',
                  teh1: '',
                  pres1: '',
                  total1: '',
                  teh2: '',
                  pres2: '',
                  total2: '',
                  totalAll: ''
                },
              ],
            },
            {
              type:'semifinal',
              sps:[
                {
                  sp_id: '',
                  teh1: '',
                  pres1: '',
                  total1: '',
                  teh2: '',
                  pres2: '',
                  total2: '',
                  totalAll: ''
                },
              ],
            },
            {
              type:'final',
              sps:[
                {
                  sp_id: '',
                  teh1: '',
                  pres1: '',
                  total1: '',
                  teh2: '',
                  pres2: '',
                  total2: '',
                  totalAll: ''
                },
              ],
            },
          ],

        },

        sortableCategory:null,
        colorSheme: true,
        showBracket32:false,
        showBracket16:false,
        showBracket8:false,
        showBracket4:false,
        showBracket2:true,
        sortableSheme: false,
        sportsmans:[],
        bracketFigths:{},
        bracketEl:null,
        selectedFight:{f:'', number:'', decision:'', winSpId:'', winSpName:'', red: {spId: '', spName: '', points: '', spOrg: ''},blue: {spId: '', spName: '', points: '', spOrg: ''}},
      }
    },

    watch: {
      selectedDrawCategory: function(newVal) {

        this.selectedDrawCategory = newVal;

        this.init();
        this.getCategoryData();



        // this.resortBracketInit();
      }
    },

    mounted(){
      this.init();

      if( this.results.tournament_id != '' &&  this.results.tournament_id != undefined){
        if(this.results.start_round == 'preround' || this.results.start_round == 'semifinal' || this.results.start_round == 'final'){

        }else{

          // this.resortCategoryDays();
        }
      }

    },



    methods:{
      addHeight(){

        let newCat = {
            name:' Новая категория',
            sportsmans:[],
          };

        this.heightList.push(newCat);

      },

      getCategoryData(){
        // this.$store.dispatch('tournament/sortableCategoryListSave', saveData);
      },

      init(){
        if(this.selectedDrawCategory != undefined && this.selectedDrawCategory.id != undefined) {
          // раскидываем спорстменов по кругам
          this.sportsmans = this.selectedDrawCategory.sportsmans;
          this.results.protocolid_id = this.selectedDrawCategory.id;
          this.results.tournament_id = this.selectedDrawCategory.tournament_id;
          this.results.start_round = this.selectedDrawCategory.start_round;


          console.log('this.selectedDrawCategory', this.selectedDrawCategory);

          let countSp = Object.keys(this.sportsmans).length;
          let i = 0;

          let saveData = {
            tournament:this.results.tournament_id,
            category:this.results.protocolid_id,
          };


          console.log('this.results', this.results);
          // если это первая жеребьевка и не задано start_round - закидваем в предыдущий круг и сохраняем сразу
          if(this.results.start_round == 'preround' || this.results.start_round == 'semifinal' || this.results.start_round == 'final'){
            // закидываем спорсменов так как они уже раскинутые

           // this.$store.dispatch('tournament/fetchCategoryListSportsmans', saveData);
          }
          else {
            this.$store.dispatch('tournament/createCategoryListFirst', saveData);
            console.log(this.results.rounds[0]);
          }


        }

        this.setSortableCategoryDay();
      },
      setSortableCategoryDay(){
        // console.log('setSortableCategoryDay');

        let containersDays = document.querySelectorAll('.poomsae-queue');
        // console.log('poomsae-queuepoomsae-queuepoomsae-queuepoomsae-queue',containersDays);
        if(containersDays.length > 0){
          //  инициализация разбивки по дням категорий

          if(this.sortableCategory !== null){
            // debugger;
            this.sortableCategory.destroy();
          }

          this.sortableCategory = new Sortable(containersDays, {
            draggable: '.sortable-category-drag-item',
            mirror: {
              constrainDimensions: true,
            },
            delay: 0,
            plugins: [Plugins.ResizeMirror],
            dragClass: "sortable-category-drag-item",
          });
          this.sortableCategory.on('drag:stop', () => this.resortCategoryDays());
        }else{
          this.sortableCategory = null;
        }
      },


      resortCategoryDays(){
        console.log('resortCategoryDays');
        let preround = document.getElementById('preround');
        let semifinal = document.getElementById('semifinal');
        let final = document.getElementById('final');

        let itemsPreround = preround.getElementsByClassName('poomsae-queue-item');
        let itemsSemifinal = semifinal.getElementsByClassName('poomsae-queue-item');
        let itemsFinal = final.getElementsByClassName('poomsae-queue-item');


        let itemsPreroundIds = [];
        let itemsSemifinalIds = [];
        let itemsFinalIds = [];
        let itemsAllIds = [];

        let i = 0;
        for (i = 0; i<itemsPreround.length; i++){
          if(itemsPreround[i].classList.contains('draggable-mirror')){

          }else if(itemsPreround[i].classList.contains('draggable-mirror')){

          }else if(itemsPreround[i].classList.contains('draggable--original')){

          }else{

            console.log(itemsPreround[i]);
            let spID = itemsPreround[i].getAttribute('data-sp-id');
            let name = itemsPreround[i].getAttribute('data-sp-name');
            let organization = itemsPreround[i].getAttribute('data-sp-organization');
            let oblast = itemsPreround[i].getAttribute('data-sp-oblast');
            itemsPreroundIds.push({id:spID, position:i, name:name, organization: organization, oblast:oblast });
            itemsAllIds.push({id:spID, position:i, name:name, organization: organization, oblast:oblast });
          }

        }

        i = 0;
        for (i = 0; i<itemsSemifinal.length; i++){
          if(itemsSemifinal[i].classList.contains('draggable-mirror')){

          }else if(itemsSemifinal[i].classList.contains('draggable-mirror')){

          }else if(itemsSemifinal[i].classList.contains('draggable--original')){

          }else{

            let spID = itemsPreround[i].getAttribute('data-sp-id');
            let name = itemsPreround[i].getAttribute('data-sp-name');
            let organization = itemsPreround[i].getAttribute('data-sp-organization');
            let oblast = itemsPreround[i].getAttribute('data-sp-oblast');
            itemsPreroundIds.push({id:spID, position:i, name:name, organization: organization, oblast:oblast });
            itemsAllIds.push({id:spID, position:i, name:name, organization: organization, oblast:oblast });
          }

        }


        i = 0;
        for (i = 0; i<itemsFinal.length; i++){
          if(itemsFinal[i].classList.contains('draggable-mirror')){

          }else if(itemsFinal[i].classList.contains('draggable-mirror')){

          }else if(itemsFinal[i].classList.contains('draggable--original')){

          }else{

            let spID = itemsPreround[i].getAttribute('data-sp-id');
            let name = itemsPreround[i].getAttribute('data-sp-name');
            let organization = itemsPreround[i].getAttribute('data-sp-organization');
            let oblast = itemsPreround[i].getAttribute('data-sp-oblast');
            itemsPreroundIds.push({id:spID, position:i, name:name, organization: organization, oblast:oblast });
            itemsAllIds.push({id:spID, position:i, name:name, organization: organization, oblast:oblast });
          }
        }

        let saveData = {
          tournament:this.results.tournament_id,
          category:this.results.protocolid_id,
          itemsPreroundIds:itemsPreroundIds,
          itemsSemifinalIds:itemsSemifinalIds,
          itemsFinalIds:itemsFinalIds,
          itemsAllIds:itemsAllIds,
        };

        console.log('saveData', saveData);


        this.$store.dispatch('tournament/sortableCategoryListSave', saveData)
        // .then(() => this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.id}))
          .catch(error => {
            alert('Помилка сервера');
          });

        console.log('saveData', saveData);



        console.log(itemsPreroundIds);
        console.log(itemsSemifinalIds);
        console.log(itemsFinalIds);

        // send data to
      },

      saveData(){
        // send data to

        console.log('saveData');
      },

    }
  }
</script>

<style lang="scss">

</style>
