<template>
  <div>
    <!-- Home -->

    <div class="home" style="max-height: 540px">
      <div data-v-5a90ec03="" data-parallax="scroll" data-image-src="images/index.jpg" data-speed="0.8" class="parallax_background parallax-window" style="background-image: url(/images/banner.jpg);background-size: cover;background-repeat: repeat-x;     position: absolute;
    top: 100px;
    height: calc(100% - 100px);">
        <!--          <div class="shadow" style="position: absolute;left: 0;top: 0;height: 100%;width: 100%;background: #000;opacity: 0.8;"></div>-->
      </div>
      <!--        <div class="parallax_background parallax-window" data-parallax="scroll" data-image-src="images/index.jpg"-->
      <!--             data-speed="0.8"></div>-->
      <div class="home_container">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="home_content text-center">
                <!--                  <div class="home_icon ml-auto mr-auto d-flex flex-column align-items-center justify-content-center">-->
                <!--                    <div><img src="images/icon_1.svg" alt="https://www.flaticon.com/authors/srip"></div>-->
                <!--                  </div>-->
                <div class="home_title">Taekwondo in UKRAINE</div>
                <!-- <div class="domain_search">
                   <div class="domain_search_background"></div>
                   <form action="#" class="domain_search_form" id="domain_search_form">
                     <input type="text" class="domain_search_input" placeholder="Your domain name" required="required">
                     <div class="domain_search_dropdown d-flex flex-row align-items-center justify-content-center">
                       <div class="domain_search_selected">.com</div>
                       <ul>
                         <li>.com</li>
                         <li>.io</li>
                         <li>.net</li>
                       </ul>
                     </div>
                     <button class="domain_search_button">search</button>
                   </form>
                 </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>





    <!-- Domain Pricing -->


    <div class="c_right">
      <div class="container">
        <div class="row row-lg-eq-height">

          <!-- Content Right Image -->
          <div class="col-lg-6 c_right_col order-lg-1 order-2">
            <div class="c_right_image d-flex flex-column align-items-center justify-content-center">
              <img src="images/pic_1.png" alt="">
            </div>
          </div>

          <!-- Content Right Content -->
          <div class="col-lg-6 order-lg-2 order-1">
            <div class="c_right_content">
              <div class="section_title_container">
                <div class="section_title"><h2>EasyTournament</h2></div>
              </div>
              <div class="c_right_text">
                <p>Сервіс допомагає організувати проведення чемпіонатів. Організатор може в режимі реального часу слідкувати за ходом проведння турнірів, змагань.</p>
              </div>
              <div class="content_list_1 c_right_list">
                <ul>
                  <li>Реєстрація учасників</li>
                  <li>Допомога в проведенні жеребкування</li>
                  <li>Швидке формування необхідної звітності</li>
                  <li>Проведення закритих(приватних) турнірів</li>
                </ul>
              </div>
              <div class="button c_right_button"><a  @click="login()" href="#">Логін</a></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Content Left -->

    <div class="c_left">
      <div class="container">
        <div class="row row-lg-eq-height">

          <!-- Content Left Content -->
          <div class="col-lg-6">
            <div class="c_left_content">
              <div class="section_title_container">
                <div class="section_title"><h2>EasyPoomsae</h2></div>
              </div>
              <div class="content_list_2 c_left_list">
                <ul>
                  <li class="d-flex flex-row align-items-start justify-content-start">
                    <div>
                      <div>01.</div>
                    </div>
                    <p>Сервіс дозволяє провести онлайн реєстрацію учасників чемпіонату з тхеквондо в розділі Poomsae.</p>
                  </li>
                  <li class="d-flex flex-row align-items-start justify-content-start">
                    <div>
                      <div>02.</div>
                    </div>
                    <p>Є можливість проведення індивідуальних та командних змагань з традиційного та вільного пумсе</p>
                  </li>
                  <li class="d-flex flex-row align-items-start justify-content-start">
                    <div>
                      <div>03.</div>
                    </div>
                    <p>Одразу після виступу учасника відбувається оцінювання суддями виступу та виведення результатів спортсмена. Даний функціонал забезпечує прозорість оцінювання виступу спортсмена.</p>
                  </li>
                </ul>
              </div>
<!--              <div class="button c_left_button"><a href="#">Створити чемпіонат</a></div>-->
            </div>
          </div>

          <!-- Content Left Image -->
          <div class="col-lg-6 c_left_col">
            <div class="c_left_image d-flex flex-column align-items-center justify-content-center">
              <img src="images/pic_2.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>















  </div>
</template>

<script>
  export default {
    name: "Home",
    methods:{
      login(){
        this.$router.replace({ path: `/user/profile` });
      }
    },
    mounted() {
      // if ('serviceWorker' in navigator) {
      //   alert("Will the service worker register?");
      //   navigator.serviceWorker.register('service-worker.js')
      //     .then(function(reg){
      //       alert("Yes, it did.");
      //     }).catch(function(err) {
      //     alert("No it didn't. This happened:", err)
      //   });
      // }
    }
  }
</script>
