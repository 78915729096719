export default {

  namespaced: true,

  state: {
    activeTournamentId: 0,
    activeCategoryId: 0,
    activeBracketSize: 0,
    activeFight: '',

    activeTournamentTab: 1,
    activeTournamentCategoryTab: 1,
    activeTournamentListTab: 1,
    actionTournamentAddSportsman: false,
    actionTournamentAddCategory: false,
    destroyBracket: false,
  },
  getters: {
    activeFight(state) {
      return state.activeFight;
    },
    activeTournamentId(state) {
      return state.activeTournamentId;
    },
    activeCategoryId(state) {
      return state.activeCategoryId;
    },
    activeBracketSize(state) {
      return state.activeBracketSize;
    },
    activeTournamentTab(state) {
      return state.activeTournamentTab;
    },
    activeTournamentCategoryTab(state) {
      return state.activeTournamentCategoryTab;
    },
    activeTournamentListTab(state) {
      return state.activeTournamentListTab;
    },
    actionTournamentAddSportsman(state) {
      return state.actionTournamentAddSportsman;
    },

    actionTournamentAddCategory(state) {
      return state.actionTournamentAddCategory;
    },

    destroyBracket(state) {
      return state.destroyBracket;
    },


  },

  mutations: {
    ['ACTIVE_TOURNAMENT_ID_UPDATE'](state, payload) {
      state.activeTournamentId = payload;
    },
    ['ACTIVE_CATEGORY_ID_UPDATE'](state, payload) {
      state.activeCategoryId = payload;
    },
    ['ACTIVE_BRACKET_SIZE_UPDATE'](state, payload) {
      state.activeBracketSize = payload;
    },
    ['ACTIVE_TOURNAMENT_TAB_UPDATE'](state, payload) {
      state.activeTournamentTab = payload;
    },
    ['ACTIVE_TOURNAMENT_LIST_TAB_UPDATE'](state, payload) {
      state.activeTournamentListTab = payload;
    },
    ['ACTIVE_FIGHT_UPDATE'](state, payload) {
      state.activeFight = payload;
    },
    ['ACTIVE_TOURNAMENT_CATEGORY_TAB_UPDATE'](state, payload) {
      state.activeTournamentCategoryTab = payload;
    },
    ['ACTIONS_TOURNAMENT_ADD_SPORTSMAN_UPDATE'](state, payload) {
      state.actionTournamentAddSportsman = payload;
    },
    ['ACTIONS_TOURNAMENT_ADD_CATEGORY_UPDATE'](state, payload) {
      state.actionTournamentAddCategory = payload;
    },
    ['DESTROY_BRACKET_UPDATE'](state, payload) {
      state.destroyBracket = payload;
    },
  },


  actions: {
    setActiveTournamentId({commit}, payload) {
      commit('ACTIVE_TOURNAMENT_ID_UPDATE', payload);
    },
    setActiveCategoryId({commit}, payload) {
      commit('ACTIVE_CATEGORY_ID_UPDATE', payload);
    },
    setActiveBracketSize({commit}, payload) {
      console.log('setActiveBracketSize', payload);
      commit('ACTIVE_BRACKET_SIZE_UPDATE', payload);
    },
    setActiveFight({commit}, payload) {
      console.log('setActiveFight', payload);
      commit('ACTIVE_FIGHT_UPDATE', payload);
    },
    setActiveTournamentTab({commit}, payload) {
      commit('ACTIVE_TOURNAMENT_TAB_UPDATE', payload);
    },
    setActiveTournamentCategoryTab({commit}, payload) {
      commit('ACTIVE_TOURNAMENT_CATEGORY_TAB_UPDATE', payload);
    },

    setActionTournamentAddSportsman({commit}, payload) {
      commit('ACTIONS_TOURNAMENT_ADD_SPORTSMAN_UPDATE', payload);
    },

    setActionTournamentAddCategory({commit}, payload) {
      commit('ACTIONS_TOURNAMENT_ADD_CATEGORY_UPDATE', payload);
    },
    setDestroyBracket({commit}, payload) {
      commit('DESTROY_BRACKET_UPDATE', payload);
    },

    setActiveTournamentListTab({commit}, payload) {
      commit('ACTIVE_TOURNAMENT_LIST_TAB_UPDATE', payload);
    },
  },
}
