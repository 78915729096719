import Vue from 'vue';
import Vuex from 'vuex';
import dataModule from './data';
import TournamentModule from './tournament';
import TournamentSectionModule from './tournament-section';
import UserModule from './user';
import SecurityModule from './security';
import SettingsModule from './settings';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    security: SecurityModule,
    data: dataModule,
    tournament: TournamentModule,
    user: UserModule,
    settings: SettingsModule,
    tournament_section: TournamentSectionModule,
  },
});
