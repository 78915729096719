import axios from 'axios';
// import {getToken} from "../data/localStorage";

let baseUrl = '';
if (process.env.NODE_ENV == 'development') {
  baseUrl = 'https://app.taekwondo.com.ua';
} else {
  baseUrl = 'https://app.taekwondo.com.ua';
}

const base = baseUrl;
// const base = 'http://api.app.loc';
export default {
  update(params) {
    return axios.post(
      base + '/api/user-update',
      params
    );
  },
  create(params) {
    console.log(params);
    return axios.post(
      base + '/user/create',
      params
    );
  },
  delete(params) {
    return axios.post(
      base + '/api/tournament-delete',
      {
        id: params.id,
      }
    );
  },

  login(params) {
    return axios.post(
      base + '/user/login',
      {
        username: params.email,
        password: params.password,
      }
    );
  },

  addSportsman(params) {
    return axios.post(
      base + '/user/add-sportsman',
      params
    );
  },

  removeOneSportsmanTeam(params) {
    return axios.post(
      base + '/user/remove-sportsman-team',
      params
    );
  },


  getOne(params) {
    return axios.get(base + '/api/user', {
      headers: {
        // Authorization: getToken()
      },
      params: {id: params.id}});
  },


  getCurrentUser() {
    return axios.get(base + '/wf-api/user/current');
  },

}


export  const baseUrlServer = baseUrl;
