var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home-layout"},[_c('div',{staticClass:"super_container"},[_c('header',{staticClass:"header trans_400"},[_c('div',{staticClass:"header_content d-flex flex-row align-items-center justify-content-start trans_400"},[_c('div',{staticClass:"logo"},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.toHome()}}},[_vm._m(0)]),_vm._m(1)]),_vm._m(2),_c('div',{staticClass:"header_right d-flex flex-row align-items-center justify-content-start"},[(!_vm.auth)?_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.toLogin()}}},[_vm._m(3)]):_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.toProfile()}}},[_vm._m(4)]),_vm._m(5)])])]),_vm._m(6),_c('public-content'),_vm._m(7)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"float":"left"}},[_c('img',{staticStyle:{"width":"90px","border-radius":"15px","margin-right":"10px"},attrs:{"src":"/logo.jpg"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding-top":"20px","float":"left"}},[_c('a',{attrs:{"href":"/"}},[_vm._v("Taekwondo.com.ua")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-10 offset-lg-2"},[_c('nav',{staticClass:"main_nav"},[_c('ul',{staticClass:"d-flex flex-row align-items-center justify-content-start"})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone d-flex flex-row align-items-center justify-content-start",staticStyle:{"cursor":"pointer"}},[_c('div',[_vm._v("Кабінет секретаря")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"phone d-flex flex-row align-items-center justify-content-start",staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"fa fa-user",attrs:{"aria-hidden":"true"}}),_c('div',[_vm._v("Мій профіль")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hamburger"},[_c('i',{staticClass:"fa fa-bars",attrs:{"aria-hidden":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu trans_500"},[_c('div',{staticClass:"menu_content d-flex flex-column align-items-center justify-content-center"},[_c('div',{staticClass:"menu_nav trans_500"},[_c('ul',{staticClass:"text-center"},[_c('li',[_c('a',{attrs:{"href":"index.html"}},[_vm._v("Головна")])]),_c('li',[_c('a',{attrs:{"href":"about.html"}},[_vm._v("Про нас")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"copyright_bar text-center"},[_vm._v(" Copyright © All rights reserved ")])])
}]

export { render, staticRenderFns }