<template>
  <div class="wrapper user-room-dashboard"  >
    <side-bar style="display: none">
      <mobile-menu slot="content"></mobile-menu>

      <sidebar-link to="/user/profile">
        <i class="nc-icon nc-circle-09"></i>
        <p>Мій профіль</p>
      </sidebar-link>



<!--      <sidebar-link to="/user/team">-->
<!--        <i class="nc-icon nc-notes"></i>-->
<!--        <p>Моя команда</p>-->
<!--      </sidebar-link>-->

<!--      <sidebar-link to="/user/participation">-->
<!--        <i class="nc-icon nc-notes"></i>-->
<!--        <p>Заявки на участь</p>-->
<!--      </sidebar-link>-->

      <sidebar-link to="/user/tournaments">
        <i class="nc-icon nc-notes"></i>
        <p>Турніри</p>
      </sidebar-link>

<!--      <sidebar-link to="/user/my-tournaments">-->
<!--        <i class="nc-icon nc-atom"></i>-->
<!--        <p>Мої Турніри</p>-->
<!--      </sidebar-link>-->
<!--
      <sidebar-link to="/user/poomsae/judge">
        <i class="nc-icon nc-atom"></i>
        <p>Poomsae пуль тр.</p>
      </sidebar-link>
&lt;!&ndash;&ndash;&gt;
      <sidebar-link to="/user/poomsae/TV">
        <i class="nc-icon nc-atom"></i>
        <p>Poomsae TV тр.</p>
      </sidebar-link>
&lt;!&ndash;&ndash;&gt;
      <sidebar-link to="/user/poomsae/secretary">
        <i class="nc-icon nc-atom"></i>
        <p>Poomsae секретар</p>
      </sidebar-link>

      <sidebar-link to="/user/poomsae/secretary">
        <i class="nc-icon nc-atom"></i>
        <p>Hapkido секретар</p>
      </sidebar-link>


      <sidebar-link to="/user/hapkido/judge">
        <i class="nc-icon nc-atom"></i>
        <p>Hapkido пульт</p>
      </sidebar-link>-->




<!---->
<!--      <sidebar-link to="/user/brackets">-->
<!--        <i class="nc-icon nc-atom"></i>-->
<!--        <p>Конструктор сіток</p>-->
<!--      </sidebar-link>-->
<!---->
<!---->

<!--


        <sidebar-link to="/admin/overview">
          <i class="nc-icon nc-chart-pie-35"></i>
          <p>Dashboard</p>
        </sidebar-link>
        <sidebar-link to="/admin/user">
          <i class="nc-icon nc-circle-09"></i>
          <p>User Profile</p>
        </sidebar-link>
        <sidebar-link to="/admin/table-list">
          <i class="nc-icon nc-notes"></i>
          <p>Table list</p>
        </sidebar-link>
        <sidebar-link to="/admin/typography">
          <i class="nc-icon nc-paper-2"></i>
          <p>Typography</p>
        </sidebar-link>
        <sidebar-link to="/admin/icons">
          <i class="nc-icon nc-atom"></i>
          <p>Icons</p>
        </sidebar-link>
        <sidebar-link to="/admin/maps">
          <i class="nc-icon nc-pin-3"></i>
          <p>Maps</p>
        </sidebar-link>
        <sidebar-link to="/admin/notifications">
          <i class="nc-icon nc-bell-55"></i>
          <p>Notifications</p>
        </sidebar-link>
-->

        <template slot="bottom-links">
          <sidebar-link class="active"
                        to="/admin/upgrade">
            <i class="nc-icon nc-alien-33"></i>
            <p>Підтримати проект</p>
          </sidebar-link>
        </template>
    </side-bar>
    <div class="main-panel" style="width: 100%!important;">
      <top-navbar></top-navbar>

      <dashboard-content @click="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import DashboardContent from './Content.vue'
  import MobileMenu from './MobileMenu.vue'
  export default {
    components: {
      TopNavbar,
      ContentFooter,
      DashboardContent,
      MobileMenu
    },
    methods: {
      toggleSidebar () {
        if (this.$sidebar.showSidebar) {
          this.$sidebar.displaySidebar(false)
        }
      }
    },
    mounted(){
      this.$store.dispatch('user/fetchUserCurrent');
    },
  }

</script>
