<template>
  <div class="winners" style="max-width: 450px;
              float: right;
              display: block;
              width: 100%;">
    <table class="table table-bordered">
      <tbody>
      <tr>
        <td><span>Золото</span></td>
        <td>{{bracketFigths.gold.spName}}</td>
      </tr>
      <tr>
        <td>Срібло</td>
        <td>{{bracketFigths.silver.spName}}</td>
      </tr>

      <tr v-if="bracketFigths.bronze1.spName != ''">
        <td>Бронза</td>
        <td>{{bracketFigths.bronze1.spName}}</td>
      </tr>

      <tr v-if="bracketFigths.bronze2.spName != ''">
        <td>Бронза</td>
        <td>{{bracketFigths.bronze2.spName}}</td>
      </tr>

      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: "bracket-winners",
    props:{bracketFigths:Object}
  }
</script>

<style scoped>

</style>
