<template>


  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <card style="padding-bottom: 500px">
            <h4 slot="header" class="card-title">Мій профіль секретаря</h4>
            <form>
              <div class="row">
                <div class="col-md-6 text-center">

                  <div class="border" >
                  <img :src="baseUrlServer+'/images/user-default.png'">
<!--                  <img :src="imgDataUrl">-->
                  </div>

<!--                  <button class="btn btn-info btn-fill" >Завантажити фото</button>-->
                  <button  type="button" class="btn btn-info btn-fill" @click="toggleShow">Завантажити фото</button>
                  <my-upload field="img"
                             @crop-success="cropSuccess"
                             @crop-upload-success="cropUploadSuccess"
                             @crop-upload-fail="cropUploadFail"
                             v-model="show"
                             :noRotate="false"
                             :width="300"
                             :height="300"
                             :url="baseUrlServer + '/user/upload-avatar'"
                             :params="params"
                             :headers="headers"
                             lang-type="ua"
                             img-format="png"></my-upload>

                </div>

                <div class="col-md-6" v-if="userOne.data != undefined">
                  <base-input type="email"
                              label="Email"
                              :disabled="true"
                              placeholder="Light dashboard"
                              v-model="userOne.data.user.email">
                  </base-input>
                  <base-input type="text"
                              label="Username"
                              :disabled="true"
                              placeholder="Username"
                              v-model="userOne.data.user.username">
                  </base-input>

                  <base-input type="text"
                              label="Ім'я "
                              :disabled="true"
                              placeholder="First Name"
                              v-model="userOne.data.user.fname">
                  </base-input>

                  <base-input type="text"
                              label="Прізвище"
                              :disabled="true"
                              placeholder="Last Name"
                              v-model="userOne.data.user.lname">
                  </base-input>

                </div>
                <div class="col-md-6" v-else>
                  Завантаження...
                </div>
              </div>

<!--              <div class="row">-->
<!--                <div class="col-md-6">-->

<!--                </div>-->
<!--                <div class="col-md-6">-->

<!--                </div>-->
<!--              </div>-->

<!--              <div class="row">-->

<!--                <div class="col-md-4">-->
<!--                  <base-input type="text"-->
<!--                              label="Країна"-->
<!--                              placeholder="Країна"-->
<!--                              v-model="user.country">-->
<!--                  </base-input>-->
<!--                </div>-->
<!--                <div class="col-md-4">-->
<!--                  <base-input type="text"-->
<!--                              label="Область"-->
<!--                              placeholder="Область"-->
<!--                              v-model="user.region">-->
<!--                  </base-input>-->
<!--                </div>-->
<!--                <div class="col-md-4">-->
<!--                  <base-input type="text"-->
<!--                              label="Місто"-->
<!--                              placeholder="Місто"-->
<!--                              v-model="user.city">-->
<!--                  </base-input>-->
<!--                </div>-->



<!--              </div>-->



<!--              <div class="row">-->
<!--                <div class="col-md-12">-->
<!--                  <div class="form-group">-->
<!--                    <label>Розкажіть трохи про себе</label>-->
<!--                    <textarea rows="5" class="form-control border-input"-->
<!--                              placeholder="Тут можете коротко описати про себе"-->
<!--                              v-model="user.aboutMe">-->
<!--              </textarea>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="text-center">-->
<!--                <button type="submit" class="btn btn-info btn-fill float-right" @click.prevent="updateProfile">-->
<!--                  Update Profile-->
<!--                </button>-->
<!--              </div>-->
              <div class="clearfix"></div>
            </form>
          </card>
        </div>
        <div class="col-md-4">

        </div>
      </div>

<!--      <user-team></user-team>-->

    </div>
  </div>


</template>
<script>
  import Card from 'src/components/Cards/Card.vue';
  import myUpload from 'vue-image-crop-upload';
  import UserTeam from 'src/pages/user/UserTeam.vue';

  import {baseUrlServer} from '../../api/user';

  console.log( baseUrlServer);

  export default {
    components: {
      Card,  UserTeam, 			'my-upload': myUpload
    },
    data () {
      return {
        baseUrlServer:'',
        ua: {
          hint: 'Натисніть, або перетягніть файл в це вікно',
          loading: 'Завантажую……',
          noSupported: 'Ваш браузер не підтримується, будь ласка скористайтесь IE10 + або іншими браузерами',
          success: 'Завантаження виконано успішно',
          fail: 'Помилка завантаження',
          preview: 'Попередній перегляд',
          btn: {
            off: 'Відмінити',
            close: 'Закрити',
            back: 'Назад',
            save: 'Зберегти'
          },
          error: {
            onlyImg: 'Тільки зображення',
            outOfSize: 'Зображення перевищує граничний розмір: ',
            lowestPx: 'Мінімальний розмір зображення: '
          }
        },

        // user: {
        //   company: '',
        //   username: ' ',
        //   email: ' ',
        //   firstName: ' ',
        //   lastName: ' ',
        //   address: ' ',
        //   city: '',
        //   region: '',
        //   country: '',
        //   aboutMe: ``
        // },


        show: false,
        params: {
          token: '123456798',
          name: 'avatar'
        },
        headers: {
          smail: '*_~'
        },
        imgDataUrl: '/images/user-default.png' // the datebase64 url of created image
      }
    },


    computed: {
      userOne() {
        return this.$store.getters['user/userOne'];
      },
    },
    updated(){

    },
    created(){
      // this.$store.dispatch('user/fetchUserCurrent');
      this.baseUrlServer = baseUrlServer;
      this.imgDataUrl = this.baseUrlServer + this.imgDataUrl;

      const token = localStorage.getItem('jwt')
      if (token) {

        this.headers = {'Authorization':'Bearer '+token, 'WWW-Authenticate':'Bearer '+token};
        console.log('headers', this.headers);
        // Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer '+token;
        // Vue.prototype.$http.defaults.headers.common['WWW-Authenticate'] = 'Bearer '+token;
      }
    },
    // mounted(){
    //     this.$store.dispatch('user/fetchUserCurrent');
    // },
    watch: {
      userOne: function(newVal) {
        if(this.userOne.data){
          this.imgDataUrl = this.baseUrlServer + this.userOne.data.user.img;
        }
      }
    },

    methods: {
      updateProfile () {
        alert('Your data: ' + JSON.stringify(this.user))
      },

      toggleShow() {
        this.show = !this.show;
      },
      /**
       * crop success
       *
       * [param] imgDataUrl
       * [param] field
       */
      cropSuccess(imgDataUrl, field){
        console.log('-------- crop success --------');
        this.imgDataUrl = imgDataUrl;
      },
      /**
       * upload success
       *
       * [param] jsonData   服务器返回数据，已进行json转码
       * [param] field
       */
      cropUploadSuccess(jsonData, field){
        console.log('-------- upload success --------');
        console.log(jsonData);
        console.log('field: ' + field);
      },
      /**
       * upload fail
       *
       * [param] status    server api return error status, like 500
       * [param] field
       */
      cropUploadFail(status, field){
        console.log('-------- upload fail --------');
        console.log(status);
        console.log('field: ' + field);
      }
    }
  }

</script>
<style>

</style>
