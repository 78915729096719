<template>
  <div class="tournament-content-tab" v-bind:class="{'tournament-content-active-tab':activeTab == 4}">
    <!--        <div class="row" style="padding-left: 500px;">-->
   <div class="categories-container categories-draw-container">
     <div class="row">
       <div class="col-md-12 fixed-categories" style="">
         <card>
           <div class="navigation-panel">
             <button v-bind:class="{'btn-warning':hideEmptyCategories}" @click="clickHideEmptyCategories()"
                     class="btn btn-sm btn-fill checkbox-list-button" style="font-size: 14px;">
                                                <span class="checkbox-container"><i class="nc-icon nc-check-2"
                                                                                    style="vertical-align: super"
                                                                                    v-if="hideEmptyCategories"></i> </span>
               Сховати пусті категорії
             </button>


             <button class="btn btn-sm btn-fill btn-primary" style="margin: 5px;" @click="toogleAutoSortableShemeAll()">
               Автоматичне жеребкування всіх категорій
             </button>

             <div class="category-filters">

               <ul class="nav nav-tabs " >
                 <li class="nav-item" v-bind:class="{'active':(filterAgeCategoryVal == '' || filterAgeCategoryVal == null) }" @click="filterAgeCategoryVal = null">
                   <a href="javascript:;" class="nav-link "    >Всі вікові</a>
                 </li>
                 <li class="nav-item"  v-bind:class="{'active':filterAgeCategoryVal == filterAgeCategoryItem  }" v-for="filterAgeCategoryItem in filterAgeCategory">
                   <a href="javascript:;" class="nav-link " @click="filterAgeCategoryVal = filterAgeCategoryItem"    >{{filterAgeCategoryItem}}</a>
                 </li>
               </ul>
             </div>
           </div>


           <div  id="category_list_drawing" class="category_list_drawing_wrapper" >
             <div  class="category_list_drawing">

               <div class="category-item" style="cursor: pointer" v-for="(item, index) in tournamentOne.data.item.protocols" :key="'cat_'+item.id+'_'+index"
                    :id="item.id +'_tab_category'"
                    v-if="(!hideEmptyCategories || (hideEmptyCategories== true && item.count > 0)) && ( !filterAgeCategoryVal || filterAgeCategoryVal === item.age_category ) && item.sex == 1 "
                    @click="selectDrawCategory(index)" v-bind:class="{'bg-info':selectedDrawCategory.id == item.id}">

                 <div><i class="nc-icon nc-notes" v-if="selectedDrawCategory.show_type == 2"></i>

                   <span style="margin-left: 5px;">{{ item.fullname }}</span>
                   <span style="margin-left: 10px" class="category-count-sportsmans">{{ item.count }}</span>
                 </div>

               </div>
             </div>

             <div  class="category_list_drawing">

               <div class="category-item woman-category" style="cursor: pointer" v-for="(item, index) in tournamentOne.data.item.protocols" :key="'cat_'+item.id+'_'+index"
                    :id="item.id +'_tab_category'"
                    v-if="(!hideEmptyCategories || (hideEmptyCategories== true && item.count > 0)) && ( !filterAgeCategoryVal || filterAgeCategoryVal === item.age_category ) && item.sex == 2 "
                    @click="selectDrawCategory(index)" v-bind:class="{'bg-info':selectedDrawCategory.id == item.id}">

                 <div><i class="nc-icon nc-notes" v-if="selectedDrawCategory.show_type == 2"></i>

                   <span style="margin-left: 5px;">{{ item.fullname }}</span>
                   <span style="margin-left: 10px" class="category-count-sportsmans">{{ item.count }}</span>
                 </div>

               </div>
             </div>

             <div  class="category_list_drawing">

               <div class="category-item other-category" style="cursor: pointer" v-for="(item, index) in tournamentOne.data.item.protocols" :key="'cat_'+item.id+'_'+index"
                    :id="item.id +'_tab_category'"
                    v-if="(!hideEmptyCategories || (hideEmptyCategories== true && item.count > 0)) && ( !filterAgeCategoryVal || filterAgeCategoryVal === item.age_category ) && item.sex != 1 && item.sex != 2 "
                    @click="selectDrawCategory(index)" v-bind:class="{'bg-info':selectedDrawCategory.id == item.id}">

                 <div><i class="nc-icon nc-notes" v-if="selectedDrawCategory.show_type == 2"></i>

                   <span style="margin-left: 5px;">{{ item.fullname }}</span>
                   <span style="margin-left: 10px" class="category-count-sportsmans">{{ item.count }}</span>
                 </div>

               </div>
             </div>
           </div>



           <div  id="category_list_drawing_" class="category_list_drawing" v-if="1 < 0">
               <div class="category-item" style="cursor: pointer" v-for="(item, index) in tournamentOne.data.item.protocols" :key="index"
                   :id="item.id +'_tab_category'"
                   v-if="(!hideEmptyCategories || (hideEmptyCategories== true && item.count > 0)) && ( !filterAgeCategoryVal || filterAgeCategoryVal === item.age_category )"
                   @click="selectDrawCategory(index)" v-bind:class="{'bg-info':selectedDrawCategory.id == item.id}">
                 <!--                      <td>  <span>{{item.id}}</span></td>-->
                 <div><i class="nc-icon nc-notes" v-if="selectedDrawCategory.show_type == 2"></i>
<!--                   <i class="nc-icon nc-bullet-list-67" v-if="selectedDrawCategory.show_type == 1"></i>-->


                   <span style="margin-left: 5px;">{{ item.fullname }}</span>
                   <span style="margin-left: 10px" class="category-count-sportsmans">{{ item.count }}</span>



<!--                   <div class="dop-info">
                     <button v-if="item.type == 1" class="btn btn-sm btn-danger btn-fill" style="padding: 2px;">
                       керугі
                     </button>
                     <button v-if="item.type == 2" class="btn btn-sm btn-fill"
                             style="background: #9368e9; padding: 2px;">пумсе
                     </button>
                     <button v-if="item.show_type == 1" class="btn btn-sm btn-warning btn-fill"
                             style="padding: 2px;">сітка
                     </button>
                     <button v-if="item.show_type == 2" class="btn btn-sm  btn-fill" style="padding: 2px;">список
                     </button>
                     <button v-if="item.show_type == 1" class="btn btn-sm btn-dark btn-fill" style="padding: 2px;">
                       {{ item.type == 2 && item.poomsae_type == 1 ? 'традиційне' : '' }}{{
                         item.type == 2 &&
                         item.poomsae_type == 2 ? 'вільне' : ''
                       }}
                     </button>

&lt;!&ndash;                     <span>{{ item.sex == 1 ? 'Чоловіки' : '' }}{{ item.sex == 2 ? 'Жінки' : '' }}</span>&ndash;&gt;
                     <div class="category-count-sportsmans">
                       <span>{{ item.count }}</span></div>
                   </div>-->
                 </div>

               </div>
           </div>
         </card>
       </div>

       <div class="col-md-3"></div>
       <div class="col-md-3 fixed-categories show_type1" style="position: fixed; top: 350px; z-index:2; left: 0;"
            v-if="selectedDrawCategory.show_type == 1">

         <card>
           <!--<div style="margin-bottom: 15px;">
             <button @click="clickHideEmptyCategories()"
                     class="btn btn-sm btn-fill  " >

               Напів-Автоматично жеребкувати
             </button>

           </div>-->


           <div style="    max-height: 600px;    overflow: auto;" id="pendingdrawingblock" class="pending-drawing-block">
             <div :data-sp="item.id" style="padding: 2px 5px" class="sp-item  sortable-drag-item"
                  v-for="(item, index) in selectedDrawCategory.sportsmans"
                  :key="selectedDrawCategory.id+'_pending_drawing_'+index" v-if="item.in_bracket < 1">
               <div class="group-team group-team-top text-top-line "><span class="tehnical-number"></span>{{ item.name }}
               </div>
               <div class="group-team group-team-top text-bottom-line">{{ item.organization }}/{{ item.oblast }}</div>
               <div class="group-team group-team-top line-center"></div>
<!--               <div><input type="number" value="1"></div>-->
             </div>

           </div>
         </card>
       </div>

       <!--      Сетки-->
       <div class="col-md-9" v-if="selectedDrawCategory.show_type == 1">
         <brackets-constructor :selectedDrawCategory.sync="selectedDrawCategory"></brackets-constructor>
       </div>


     </div>
   </div>

    <div class="row categories-poomsae-list-container" v-if="selectedDrawCategory.show_type != 1">
<!--      Сетки-->
<!--      <div class="col-md-12" v-if="selectedDrawCategory.show_type == 1">
        <brackets-constructor :selectedDrawCategory.sync="selectedDrawCategory"></brackets-constructor>
      </div>-->
      <!--      списки-->
      <div class="col-md-12 poomsae-container" >

        <result-constructor :selectedDrawCategory.sync="selectedDrawCategory"></result-constructor>
      </div>


    </div>
  </div>
</template>

<script>
import UserBracketsContructor from 'src/pages/tools/UserBracketsContructor.vue';
import ResultContructor from 'src/pages/tools/ResultContructor.vue';
import ListContructor from 'src/pages/tools/ListContructor.vue';
import ListContructorNew from 'src/pages/tools/ListContructorNew.vue';

export default {
  name: "tab-drawing",
  props: ['category'],
  components: {
    'list-constructor-new': ListContructorNew,
    'list-constructor': ListContructor,
    'brackets-constructor': UserBracketsContructor,
    'result-constructor': ResultContructor,
  },
  data() {
    return {
      selectedCategory: 0,
      filterAgeCategoryVal: null,
      // selectedDrawCategory: {},
      hideEmptyCategories: true,
      showBracketCategories: false,
      regions: [],
      file: '',
    }
  },
  computed: {
    activeTab() {
      return this.$store.getters['settings/activeTournamentTab'];
    },
    tournamentOne() {
      return this.$store.getters['tournament/tournamentOne'];
    },
    filterAgeCategory(){
      var age_category = [];
      let length = this.tournamentOne.data.item.protocols.length;
      for (let i = 0; i < length; i++) {
        console.log(this.tournamentOne.data.item.protocols[i]);
        const age  = this.tournamentOne.data.item.protocols[i].age_category;
        if (!age_category.includes(age)){
          age_category.push(age);
        }

        // if (this.tournamentOne.data.item.protocols[i].id === this.selectedCategory) {
        //
        // }
      }

      // age_category.

      return age_category;
    },
    selectedDrawCategory() {
      if (this.selectedCategory != undefined && this.selectedCategory > 0) {
        console.log('this.category ', this.selectedCategory);

        let length = this.tournamentOne.data.item.protocols.length;
        for (let i = 0; i < length; i++) {
          if (this.tournamentOne.data.item.protocols[i].id === this.selectedCategory) {
            return this.tournamentOne.data.item.protocols[i];
          }
        }
      }else {
        return this.tournamentOne.data.item.protocols[0];
      }
    },
  },

  mounted() {
    this.selectedCategory = this.category != undefined ? this.category : 0;
  },

  updated() {
    // this.openCategoryFromUrl();
    // console.log('selectedDrawCategory.show_type ', this.selectedDrawCategory.show_type);
  },


  methods: {


    selectDrawCategory(category) {
      // this.category = category;
      let selectedDrawCategory = this.tournamentOne.data.item.protocols[category];
      this.selectedCategory = selectedDrawCategory.id;
      // this.$des''troy();
      // this.$destroy(UserBracketsContructor);
      // var vm = new tmp().$mount('#mount-point');
      // vm.$destroy();

      // console.log('category index', category);

      // this.selectedDrawCategory = this.tournamentOne.data.item.protocols[category];
      // let selectedDrawCategory = this.tournamentOne.data.item.protocols[category];

      // this.$router.replace({ path: `/user/tournaments/overview/${this.tournamentOne.data.item.id}/bracket/${selectedDrawCategory.id}` }) // -> /user/123
      // window.location = `/user/tournaments/overview/${this.tournamentOne.data.item.id}/bracket/${selectedDrawCategory.id}`;
      // http://localhost:8080/user/tournaments/overview/4/bracket/195
      // this.selectedDrawCategory = category;
    },


    clickHideEmptyCategories() {
      // console.log(clickHideEmptyCategories)
      if (this.hideEmptyCategories == true) {
        this.hideEmptyCategories = false;
      } else {
        this.hideEmptyCategories = true;
      }
    },


    // Автоматическа жеребьевка всех категорий
    toogleAutoSortableShemeAll() {
      console.log('toogleAutoSortableShemeAll');

      let params = {
        tournament_id: this.tournamentOne.data.item.id,
        time: new Date().getTime(),
      };

      if (window.confirm("Прожеребкувати всі категорії автоматично? Попередні жеребкування будуть стерті.")) {
        this.$store.dispatch('tournament/autoSortableBracketAll', params).then(() => this.$store.dispatch('tournament/fetchTournamentOneFull', {id: params.tournament_id}))
            .then(() => this.updateBracket());
      }

      // this.$store.dispatch('tournament/autoSortableBracket', params).then(() => this.$store.dispatch('tournament/fetchTournamentOneFull', {id: params.tournament_id}))
      //   .then(() => window.location.reload());


      // if (this.sortableSheme === true) {
      //   this.disableToogleSortableSheme();
      // } else if (this.sortableSheme !== true) {
      //   this.enableToogleSortableSheme();
      // }

    },

    updateBracket() {
      alert('Дані оновлено');
      window.location.reload();
    }

  }

}
</script>

<style lang="scss" scoped>

.categories-container{


  .bg-info {
    background-color: #007bff !important;
    color: #fff;
  }

  .category-filters{
    .nav.nav-tabs{
      border: none;
    }
    .nav-item{
      border-radius: 4px 4px 4px 4px;
      background-color: #6c757d;
      border: 1px solid #fff;
    }

    .nav-item.active, .nav-item:focus, .nav-item:hover{
      //background-color: #007bff;


      > a, a:hover{
        background-color: #007bff;
        color: #fff;
      }
    }
  }

  .navigation-panel{
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }

  .category_list_drawing_wrapper{
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 20px;

    .category_list_drawing{
      display: grid;
      grid-template-columns: auto  auto;
      grid-gap: 10px;

      .category-item{
        border: 1px solid #ddd;
        background: #f6f5f5;
      }

      .category-item.woman-category{
        border: 1px solid #ddd;
        background: #ffc0f0;
      }

      .category-item.other-category{
        border: 1px solid #caacff;
        background: #9f71f3;
      }

      .category-count-sportsmans{
        background: #ccc;
        width: auto;
        padding: 1px 2px;
        border-radius: 4px;
        text-align: center;
      }
    }
  }
}

</style>
