<template>
  <div class="wrapper">
    <div class="main-panel-judge">

      <judge-content>

      </judge-content>
<!--      <dashboard-content @click="toggleSidebar">-->

<!--      </dashboard-content>-->
    </div>
  </div>
</template>
<style lang="scss">

</style>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import JudgeContent from './Content.vue'
  import MobileMenu from './MobileMenu.vue'
  export default {
    components: {
      // TopNavbar,
      // ContentFooter,
      JudgeContent,
      // MobileMenu
    },
    methods: {
      // toggleSidebar () {
      //   if (this.$sidebar.showSidebar) {
      //     this.$sidebar.displaySidebar(false)
      //   }
      // }
    }
  }

</script>
