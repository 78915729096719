import { render, staticRenderFns } from "./ParticipationInTournament.vue?vue&type=template&id=3a2a86f4&scoped=true&"
import script from "./ParticipationInTournament.vue?vue&type=script&lang=js&"
export * from "./ParticipationInTournament.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a2a86f4",
  null
  
)

export default component.exports