<template>
<div>
  participation in the tournament
</div>
</template>

<script>
  export default {
    name: "ParticipationInTournament"
  }
</script>

<style scoped>

</style>
