<template>
  <div class="fight-number" @click="selectFight()">
    <span class="tehnical-number-order">{{ tehnumber }}</span>
    <span class="tehnical-number">{{ tehnumber }}</span>
    <!--    <span class="fight-decision-text">{{bracketFigths.fight_1.decision}}</span>-->
    <div class="fight-number-text " v-bind:class="{'active':this.tehnumber == this.activeFight}">
      <!--    <div class="fight-number-text " v-bind:class="{'active':isActive}">{{number}}-->

      {{ this.number }}


      <!--      <input type="text" style="width: 100%;-->
      <!--    height: 100%;-->
      <!--    border: none;-->
      <!--    padding: 0;-->
      <!--    margin: 0;-->
      <!--background: initial;-->
      <!--">-->
    </div>

  </div>
</template>

<script>
export default {
  name: "BracketFightNumberDraw",
  props: {
    selectBracketFight: {
      type: Function,
      description: "Function select Bracket Fight"
    },
    fight: {
      type: [String, Number],
      description: "Fight number"
    },
    number: {
      type: [String, Number],
      description: "Fight number"
    },
    tehnumber: {
      type: [String, Number],
      description: "Fight number"
    },

  },
  data() {

    return {
      isActive: false,
    }
  },
  computed: {
    activeFight() {
      return this.$store.getters['settings/activeFight'];
    },
  },
  methods: {
    selectFight() {
      this.selectBracketFight(this.tehnumber);

      console.log('this.tehnumber')

      this.$store.dispatch('settings/setActiveFight', this.tehnumber);

    }
  }
}
</script>

<style scoped>

</style>
