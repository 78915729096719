<template>
  <div class="group " data-fight="1">
    <div class="line-vertical"></div>

    <span class="fight-points-blue-text">{{ getSportsmanPointsByPosition }} <i
        style="position:absolute; top:0; right:5px; font-style: normal">:</i></span>
    <span class="fight-points-red-text">{{ getSportsmanPointsByPosition }}</span>

    <bracket-sportsman-item-draw :showOrganization="true" :technicalNumber="technicalNumber1"
                                 :sportsman="getSportsman"></bracket-sportsman-item-draw>
    <div class="divider"></div>
    <bracket-fight-number-draw :selectBracketFight.sync="selectFight" :number="getFightNumberByPosition"
                             :tehnumber="fightNumber"></bracket-fight-number-draw>
    <bracket-sportsman-item-draw :showOrganization="true"  :technicalNumber="technicalNumber2"
                                 :sportsman="getSportsman2"></bracket-sportsman-item-draw>
  </div>
</template>
<script>
export default {
  name: 'BracketFigtSection',
  props: {
    getFightNumberByPosition: {},
    getSportsman: {},
    getSportsman2: {},
    getSportsmanPointsByPosition: {},
    selectFight: {},
    technicalNumber1: {},
    technicalNumber2: {},
    fightNumber: {},
  }
}
</script>
