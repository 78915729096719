<template>
  <div class="tournament-content-tab" v-bind:class="{'tournament-content-active-tab':activeTab == 1}">
    <div class="row">
      <div class="col-md-12" v-if="tournamentOne.data">
        <card>

          <div class="row">
            <div class="col-md-12">
              <a class="btn btn-sm  btn-fill btn-warning" style="margin: 5px;"
                 :href="baseUrlServer+'/tournament-report/print-pdf?id='+tournamentOne.data.item.id"
                 target="_blank"> Друк результатів (PDF)</a>

              <a class="btn btn-sm  btn-fill btn-warning" style="margin: 5px;"
                 :href="baseUrlServer+'/tournament-report/print-pdf?id='+tournamentOne.data.item.id"
                 target="_blank"> Друк жеребкування (всі протоколи PDF)</a>

              <a class="btn btn-sm  btn-fill btn-warning" style="margin: 5px;"
                 :href="baseUrlServer+'/tournament-report/print-pdf?id='+tournamentOne.data.item.id"
                 target="_blank"> Друк протоколів зважування (всі протоколи PDF)</a>

            </div>
          </div>
          <div class="row">
            <div class="col-md-2 col-xs-12">
              <div class="_col-md-12" v-if="tournamentOne.data">
                <h4>Загальна кiлькiсть</h4>

              </div>

              <table class="table table-responsive">
                <tbody>
                <tr>
                  <td>К-сть спортсменів</td>
                  <td>{{countSportsmans}}</td>
                </tr>
                <tr>
                  <td>Клуби/організації</td>
                  <td>{{countOrganizations}}</td>
                </tr>
                <tr>
                  <td>К-сть категорій</td>
                  <td>{{countCategories}}</td>
                </tr>
                <tr>
                  <td>К-сть областей</td>
                  <td>{{countRegionsMenWoman.regions}}</td>
                </tr>

                <tr>
                  <td>К-сть дівчат</td>
                  <td>{{countRegionsMenWoman.woman}}</td>
                </tr>

                <tr>
                  <td>К-сть хлопців</td>
                  <td>{{countRegionsMenWoman.men}}</td>
                </tr>

                </tbody>
              </table>
            </div>

            <div class="col-md-3 col-xs-12">
              <div class=" ">
                <h4>Області</h4>
              </div>

              <table class="table table-responsive">
                <thead>
                <tr>
                  <th style="width: 85%;">Область</th>
                  <th class="text-center"> Кiлькiсть учасникiв</th>
                </tr>

                </thead>
                <tbody>
                <tr v-for="regionItem in regionsList">
                  <td>{{ regionItem.name }}</td>
                  <td>{{ regionItem.count }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="col-md-4 col-xs-12">
              <div class="_col-md-12">
                <h4>Організації</h4>
              </div>

              <table class="table table-responsive">
                <thead>
                <tr>
                  <th style="width: 85%;">Назва організації</th>
                  <th class="text-center"> Кiлькiсть учасникiв</th>
                </tr>

                </thead>
                <tbody>
                <tr v-for="organization in tournamentOne.data.item.organizations">
                  <td>{{ organization.organization }} <br><span
                    class="text-muted small">{{ organization.oblast }}</span></td>
                  <td>{{ organization.registered }}</td>
                </tr>
                </tbody>
              </table>
            </div>

            <div class="col-md-3 col-xs-12">
              <div class="_col-md-12">
                <h4>Категорії</h4>
              </div>

              <table class="table table-responsive">
                <thead>
                <tr>
                  <th style="width: 85%;">Назва організації</th>
                  <th class="text-center"> Кiлькiсть учасникiв</th>
                </tr>

                </thead>
                <tbody>
                <tr v-for="protocol in tournamentProtocols" v-if="protocol.count_sportsmans > 0">
                  <td>{{ protocol.fullname }}</td>
                  <td>{{ protocol.count_sportsmans }}</td>
                </tr>
                </tbody>
              </table>
            </div>



          </div>


          <hr>
          <div class="row">
            <div class="col-md-12">
              <div v-if="tournamentOne.data && tournamentOne.data.item"
                   v-html="tournamentOne.data.item.description"></div>
              <!--                TODO Додати перевiрку на JS при створенні-редагуванні-->
            </div>
          </div>
        </card>

      </div>
    </div>
  </div>
</template>

<script>

import {baseUrlServer} from "../../api/user";

export default {
  name: "tab-info",
  created(){
    this.baseUrlServer = baseUrlServer;
  },
  data() {
    return {
      regions: [],
    }
  },
  computed: {
    activeTab() {
      return this.$store.getters['settings/activeTournamentTab'];
    },
    tournamentOne() {
      return this.$store.getters['tournament/tournamentOne'];
    },

    countSportsmans() {
      return  this.tournamentOne.data.item.sportsmans.length
    },

    countOrganizations() {
      return  this.tournamentOne.data.item.organizations.length
    },
    regionsList() {
      let regions = {};


      for (const sportsman of this.tournamentOne.data.item.sportsmans){

        if(regions[sportsman.oblast] != undefined){
          regions[sportsman.oblast]['count']++;
        }else{
          regions[sportsman.oblast] = {
            name: sportsman.oblast,
            count:  1
          }
        }
      }


      let regionsArr = [];
      let keys = Object.keys(regions);
      console.log('keys', keys)

      for (let i  in keys) {
        regionsArr.push(regions[keys[i]])
      }


      return  regionsArr.sort(function (a, b){
        if ( a.count > b.count ){
          return -1;
        }
        if ( a.count < b.count ){
          return 1;
        }
        return 0;
      });
    },

    countRegionsMenWoman() {
      let counts = {regions:0, men:0, woman:0};
      let regions = [];
      let men = [];
      let woman = [];

      for (const sportsman of this.tournamentOne.data.item.sportsmans){
        if(regions.includes(sportsman.oblast) !== true) regions.push(sportsman.oblast)
        if(sportsman.sex === "1") men.push(sportsman.oblast)
        if(sportsman.sex === "2") woman.push(sportsman.oblast)
      }

      counts.regions = regions.length;
      counts.men = men.length;
      counts.woman = woman.length;

      return  counts;
    },
    tournamentProtocols() {
      return this.tournamentOne.data.item.protocols.sort((a, b) => b.count_sportsmans - a.count_sportsmans);
    },

    countCategories() {
      return  this.tournamentOne.data.item.protocols.filter(function (el){return el.count_sportsmans > 0}).length;
    },
  },


}
</script>

<style scoped>

</style>
