import UserAPI from '../api/user';

// авторизация
import Axios from 'axios'
import TournamentAPI from "../api/tournament";

export default {
  namespaced: true,
  state: {
    isLoading: false,
    error: null,
    errorSignup: null,
    errorLogin: null,
    requestSend: false,
    requestSuccessful: false,
    requestFail: false,
    isModalFormShow: true,
    users: [],
    userOne: {}
  },
  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    requestSend(state) {
      return state.requestSend;
    },
    requestSuccessful(state) {
      return state.requestSuccessful;
    },
    hasError(state) {
      return state.error !== null;
    },
    error(state) {
      return state.error;
    },
    errorSignup(state) {
      return state.errorSignup;
    },
    hasErrorSignup(state) {
      return state.errorSignup !== null;
    },
    errorLogin(state) {
      return state.errorLogin;
    },
    hasErrorLogin(state) {
      return state.errorLogin !== null;
    },
    hasUsers(state) {
      return state.users.length > 0;
    },
    isModalFormShow(state) {
      return state.isModalFormShow;
    },
    users(state) {
      return state.users;
    },
    userOne(state) {
      // if(state.userOne != undefined){
      // }

      return state.userOne;
    },
  },
  mutations: {
    ['CREATING_USER'](state) {
      state.isLoading = true;
      state.requestSend = true;
      state.error = null;
    },
    ['LOGIN_USER_SUCCESS'](state, post) {
      state.isLoading = false;
      state.requestSuccessful = true;
      state.isModalFormShow = false;
      state.requestSend = false;
      state.requestFail = false;
      state.error = null;
      state.errorLogin = null;
      state.users.unshift(post);

      // state.errorSignup = post.data.errors;
      if(post.data.status !== 200){
        state.errorLogin = post.data.errors;
      }else{
        state.errorLogin = null;
      }
    },
    ['LOGIN_USER_ERROR'](state, error) {
      state.isLoading = false;
      state.requestSuccessful = false;
      state.requestSend = false;
      state.requestFail = true;
      state.error = error;
      // state.errorSignup = post;

      state.users = [];
    },


    ['CREATING_USER_SUCCESS'](state, post) {
      state.isLoading = false;
      state.requestSuccessful = true;
      state.isModalFormShow = false;
      state.requestSend = false;
      state.requestFail = false;
      state.error = null;
      state.errorSignup = null;
      state.users.unshift(post);

      // state.errorSignup = post.data.errors;
      if(post.data.status !== 200){
        state.errorSignup = post.data.errors;
      }
    },
    ['CREATING_USER_ERROR'](state, error) {
      state.isLoading = false;
      state.requestSuccessful = false;
      state.requestSend = false;
      state.requestFail = true;
      state.error = error;
      // state.errorSignup = post;

      state.users = [];
    },

    ['UPDATING_USER'](state) {
      state.isLoading = false;
      state.requestSend = true;
      state.error = null;
    },
    ['UPDATING_USER_SUCCESS'](state, post) {
      state.isLoading = false;
      state.requestSuccessful = true;
      state.isModalFormShow = false;
      state.requestSend = false;
      state.requestFail = false;
      state.error = null;
      state.userOne = post;
    },
    ['UPDATING_USER_ERROR'](state, error) {
      state.isLoading = false;
      state.requestSuccessful = false;
      state.requestSend = false;
      state.requestFail = true;
      state.error = error;
      state.users = [];
    },

    ['FETCHING_USERS'](state) {
      state.isLoading = true;
      state.error = null;
      state.users = [];
    },
    ['FETCHING_USERS_SUCCESS'](state, users) {
      state.isLoading = false;
      state.error = null;
      state.users = users;
    },
    ['FETCHING_USERS_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
      state.users = [];
    },


    ['FETCHING_USER_ONE'](state) {
      state.isLoading = true;
      state.error = null;
      state.userOne = {};
    },
    ['FETCHING_USER_ONE_SUCCESS'](state, userOne) {
      state.isLoading = false;
      state.error = null;
      state.userOne = userOne;

    },
    ['FETCHING_USER_ONE_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
      state.currentUser = {};
    },



 ['ADDING_USER_SPORTSMAN'](state) {
      state.isLoading = true;
      state.error = null;
      state.userOne = {};
    },
    ['ADDING_USER_SPORTSMAN_SUCCESS'](state, userOne) {
      state.isLoading = false;
      state.error = null;

    },
    ['ADDING_USER_SPORTSMAN_ERROR'](state, error) {
      state.isLoading = false;
      state.error = error;
      // state.currentUser = {};
    },

    ['SHOW_MODAL_FORM_USER'](state, error) {
      state.isModalFormShow = true;
    },
    ['HIDE_MODAL_FORM_USER'](state, error) {
      state.isModalFormShow = false;
    },
    ['DELETING_USER'](state, error) {
      // state.isModalFormShow = true;
    },
    ['DELETING_USER_SUCCESS'](state, error) {
      // state.isModalFormShow = false;
    },
    ['DELETING_USER_ERROR'](state, error) {
      // state.isModalFormShow = false;
    },
  },
  actions: {
    createUser({commit}, params) {
      commit('CREATING_USER');
      return UserAPI.create(params)
        .then(res => {
          commit('CREATING_USER_SUCCESS', res.data)
          if(res.data.data.status === 200){
            let token = res.data.data.item.token;
            localStorage.setItem('jwt', token);
            Axios.defaults.headers.common['Authorization'] = token;

          }else {
            localStorage.removeItem('token');
            localStorage.removeItem('jwt');
          }

        })
        .catch(err => commit('CREATING_USER_ERROR', err));
    },

    loginUser({commit}, params) {
      commit('CREATING_USER');
      return UserAPI.login(params)
        .then(res => {
          commit('LOGIN_USER_SUCCESS', res.data)
          if(res.data.data.status === 200){
            let token = res.data.data.item.token;
            localStorage.setItem('jwt', token);
            Axios.defaults.headers.common['Authorization'] = token;


          }else {
            localStorage.removeItem('token')
          }

          return res.data;
        })
        .catch(err => commit('LOGIN_USER_ERROR', err));
    },
    updateUser({commit}, params) {
      commit('UPDATING_USER');
      return UserAPI.update(params)
        .then(res => commit('UPDATING_USER_SUCCESS', res.data))
        .catch(err => commit('UPDATING_USER_ERROR', err));
    },
    showModalForm({commit}, val) {
      if (val === true) {
        commit('SHOW_MODAL_FORM_USER');
      } else {
        commit('HIDE_MODAL_FORM_USER');
      }

      return true;
    },

    fetchUserCurrent ({commit}) {
      commit('FETCHING_USER_ONE');
      return UserAPI.getCurrentUser()
        .then(res => commit('FETCHING_USER_ONE_SUCCESS', res.data))
        .catch(err => commit('FETCHING_USER_ONE_ERROR', err));
    },

    addSportsman ({commit}, params) {
      commit('ADDING_USER_SPORTSMAN');
      return UserAPI.addSportsman(params)
        .then(res => commit('ADDING_USER_SPORTSMAN_SUCCESS', res.data))
        .catch(err => commit('ADDING_USER_SPORTSMAN_ERROR', err));
    },

    removeOneSportsmanTeam ({commit}, params) {
      commit('REMOVE_USER_SPORTSMAN');
      return UserAPI.removeOneSportsmanTeam(params)
        .then(res => commit('REMOVE_USER_SPORTSMAN_SUCCESS', res.data))
        .catch(err => commit('REMOVE_USER_SPORTSMAN_ERROR', err));
    },

  },
}
