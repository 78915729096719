<template>
  <div class="tournament-content-tab" v-bind:class="{'tournament-content-active-tab':activeTab == 7}">



    <div class="row"  >
      <div class="col-md-12">
        <card>
          <template slot="header">
            <h4 class="title">Категорії({{filteredCategories.length}})</h4>
            <div>
              <button class="btn btn-sm btn-primary btn-fill  "  style="margin: 0 5px 0 0;" @click="toogleAddCategory()">
                <i class="nc-icon nc-simple-add" style="vertical-align: sub;"></i>  Додати
              </button>
            </div>
          </template>

          <hr>

          <modal name="category-form-modal" :adaptive=true  :minHeight=350 height="auto" :minWidth=400 width="80%" :scrollable=true>
            <form-category :formAddCategory="formCategoryData"></form-category>
          </modal>




          <div class="_row">
            <div class="category-filters">
              <div class="col-md-4">
                <div class="form-group"><label class="control-label">
                  Вікова категорія
                </label>

                  <v-select label="name" :filterable="true" :options="filterAgeCategory"
                            v-model="filterAgeCategoryVal" >
                    <template slot="no-options">Укажіть вікову категорію
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        <span class="name-city">{{ option.name }}</span>
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        <span class="name-city">{{ option.name }}</span>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <table class="table table-hover " v-if="filteredCategories.length > 0">
                <thead>
                <slot name="columns">
                  <tr>
                    <th>№</th>
                    <th>ID</th>
                    <th>Назва</th>
                    <th>Вікова категорія</th>
                    <th>Стать</th>
                    <th>К-сть учасників</th>
                    <!--                        <th>Кваліфікація</th>-->
                    <!--                        <th>Тренер</th>-->
                    <!--                        <th>Бере участь</th>-->
                    <th style="text-align: right;"></th>
                    <th style="text-align: right;"></th>
                  </tr>
                </slot>
                </thead>
                <tbody>
                <tr v-for="(item, index) in filteredCategories" v-if="!filterAgeCategoryVal || filterAgeCategoryVal == item.age_category" :key="index">
                  <td>{{index+1}}</td>
                  <td>{{item.id}}</td>
                  <td>{{item.fullname}}<span class="text-danger" v-if="!item.fullname">не вказано</span>
<div style="display: grid; grid-template-columns: 70px 70px 90px; grid-column-gap: 10px">
  <button v-if="item.type == 1" class="btn btn-xs btn-danger btn-fill">керугі</button><button v-if="item.type == 2" class="btn btn-xs btn-fill" style="background: #9368e9">пумсе</button>
  <button v-if="item.show_type == 1" class="btn btn-xs btn-warning btn-outline">сітка</button>
  <button v-if="item.show_type == 2" class="btn btn-xs  btn-outline">список</button>


  <button v-if="item.type == 2  && (item.poomsae_type == 1 || item.poomsae_type == 2)" class="btn btn-xs  btn-outline"> {{item.type == 2 && item.poomsae_type == 1 ? 'традиційне':''}}{{item.type == 2 && item.poomsae_type == 2 ? 'вільне':''}}</button>


</div>

                  </td>


                  <td> {{item.age_category}}</td>

                  <td> <span v-if="item.sex == 1">чоловік</span><span v-if="item.sex == 2">жінка</span></td>
                  <td>{{item.count}}</td>
                  <td style="justify-content: right;">


                    <button @click="downloadPdf(item, 'results')"
                            :href="baseUrlServer+'/tournament-result/print-pdf?category='+item.id"
                            style="margin-right: 10px" class="btn btn-xs btn-dark btn-fill  " target="_blank" download>
                      <i class="nc-icon nc-attach-87" style="vertical-align: sub;"></i> Жеребкування (PDF)
                    </button>

                    <button @click="downloadPdf(item, 'weight')"
                            :href="baseUrlServer+'/tournament-result/print-pdf?category='+item.id"
                            style="margin-right: 10px" class="btn btn-xs btn-dark btn-fill  " target="_blank" download>
                      <i class="nc-icon nc-attach-87" style="vertical-align: sub;"></i> Протокол зважування (PDF)
                    </button>

                  </td>
                  <td style="justify-content: right;">


                    <button style="margin-right: 10px" class="btn btn-xs btn-warning btn-fill    " @click="updateOneCategory(item)"> <i class="nc-icon nc-simple-remove" style="vertical-align: sub;"></i>Редагувати
                    </button>
                    <button class="btn btn-xs btn-danger btn-fill    " @click="deleteOneCategory(item.id)"> <i class="nc-icon nc-simple-remove" style="vertical-align: sub;"></i>Видалити
                    </button>

                  </td>
                </tr>
                </tbody>
              </table>

              <div v-else> Інформація відсутня</div>
              <!--                TODO Додати перевiрку на JS при створенні-редагуванні-->
            </div>
          </div>

        </card>

      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';

  import {Sortable, Plugins, DragStartEvent, DragStopEvent} from '@shopify/draggable';
  import FormCategory from  'src/pages/tournament/_formCategory.vue';
  import {baseUrlServer} from "../../api/user";
  export default {
    name: "tab-categories",
    components:{
      'form-category': FormCategory
    },

    data: function(){

      return {
        filterAgeCategoryVal: null,
        // sortableCategoyDay:'',
        formCategoryData:{
          id:'',
          name:'',
          age1:'',
          age2:'',
          sex:'',
          type:'',
          weight1:'',
          weight2:'',
          poomsaeType:'',
          showType:'',
          agecategory:'',
          section:'',
          categoryStatus:'',
        },
      }
    },


    computed: {
      activeTab() {
        return this.$store.getters['settings/activeTournamentTab'];
      },
      filterAgeCategory(){
        var age_category = [];
        let length = this.tournamentOne.data.item.protocols.length;
        for (let i = 0; i < length; i++) {
          console.log(this.tournamentOne.data.item.protocols[i]);
          const age  = this.tournamentOne.data.item.protocols[i].age_category;
          if (!age_category.includes(age)){
            age_category.push(age);
          }

          // if (this.tournamentOne.data.item.protocols[i].id === this.selectedCategory) {
          //
          // }
        }

        // age_category.

        return age_category;
      },
      actionTournamentAddCategory() {
        return this.$store.getters['settings/actionTournamentAddCategory'];
      },

      activeTournamentCategoryTab() {
        return this.$store.getters['settings/activeTournamentCategoryTab'];
      },

      tournamentOne() {
        return this.$store.getters['tournament/tournamentOne'];
      },


      filteredCategories: function() {

        function compare( a, b ) {
          if ( a.count < b.count ){
            return -1;
          }
          if ( a.count > b.count ){
            return 1;
          }
          return 0;
        }




        console.log('filteredCategories', this.tournamentOne.data.item.protocols);
        let filters = [];
        if(this.tournamentOne.data.item.protocols){
          filters = this.tournamentOne.data.item.protocols;
          // let checkedFiters = this.tournamentOne.data.item.protocols.filter(function(el) {
          //   return el.count > 0;
          // });
          // checkedFiters.forEach(element => {
          //   filters.push(element);
          // });


        }
        // Sort by count
        // filters.sort( compare );
        return filters;
      },
    },


    mounted(){
      // this.setSortableCategoryDay();
    },
    updated(){
      // this.setSortableCategoryDay();
    },
    created() {
      this.baseUrlServer = baseUrlServer;
    },

    methods:{

      // удаление одной категории с турнира
      updateOneCategory(category){
          console.log(category);

          // this.formAddSportsman.first_name = sportsman.first_name;
          this.formCategoryData.id = category.id;
          this.formCategoryData.name = category.fullname;
          this.formCategoryData.age1 = category.age1;
          this.formCategoryData.age2 = category.age2;
          this.formCategoryData.sex = category.sex;
          this.formCategoryData.type = category.type;
          this.formCategoryData.weight1 = category.weight1;
          this.formCategoryData.weight2 = category.weight2;
          this.formCategoryData.poomsaeType = category.poomsaeType;
          this.formCategoryData.categoryStatus = category.categoryStatus;
          this.formCategoryData.showType = category.showType;
          this.formCategoryData.agecategory = category.age_category;

        if(category.show_type == '1'){
          this.formCategoryData.showType =  {id:1, name:'Сітка'};
        }else if(category.show_type == '2'){
          this.formCategoryData.showType = {id:2, name:'Список'};
        }

        if(category.type == '1'){
          this.formCategoryData.type =  {id:1, name:'Керугі'};
        }else if(category.type == '2'){
          this.formCategoryData.type = {id:2, name:'Пумсе'};
        }

        if(category.poomsae_type == 0){
          this.formCategoryData.poomsaeType = {id:0, name:'-'};
        }
        else if(category.poomsae_type == '1'){
          this.formCategoryData.poomsaeType =   {id:1, name:'Традиційне'};
        }else if(category.poomsae_type == '2'){
          this.formCategoryData.poomsaeType = {id:2, name:'Вільне (фрістайл)'};
        }

        if(category.category_status == 0){
          this.formCategoryData.categoryStatus = {id:0, name:'-'};
        }
        else if(category.category_status == '1'){
          this.formCategoryData.categoryStatus =   {id:1, name:'Опубліковано'};
        }else if(category.category_status == '2'){
          this.formCategoryData.categoryStatus = {id:2, name:'Чернетка'};
        }



        if(category.sex == 0){
          this.formCategoryData.sex = {id:0, name:'-'};
        }else if(category.sex == '1'){
          this.formCategoryData.sex =  {id:1, name:'чоловік'};
        }else if(category.sex == '2'){
          this.formCategoryData.sex = {id:2, name:'жінка'};
        }

        this.$store.dispatch('settings/setActionTournamentAddCategory',true);
        this.$modal.show('category-form-modal');
      },

      deleteOneCategory(id){

        if (window.confirm(" Після видалення дані неможливо буде відновити. Ви впевнені, що хочете видали категорію з турніру?")) {
          let params = {'id': id};
          this.$store.dispatch('tournament/deleteOneCategory', params)
            .then(() => this.deleteFromListCategory(id));
        }
      },
      deleteFromListCategory(id){
        let length = this.tournamentOne.data.item.protocols.length;
        for (let i = 0; i < length; i++) {
          console.log(this.tournamentOne.data.item.protocols[i].id);
          if (this.tournamentOne.data.item.protocols[i].id === id){
            this.tournamentOne.data.item.protocols.splice(i, 1);
            break;
          }
        }
      },






      toogleAddCategory(){
        // if(this.actionTournamentAddCategory == true){
        //   this.$store.dispatch('settings/setActionTournamentAddCategory',false);
        // }else{
        //   this.$store.dispatch('settings/setActionTournamentAddCategory',true);
        // }
        // Reset Category Form
        this.formCategoryData.id = '';

        this.$store.dispatch('settings/setActionTournamentAddCategory',true);
        this.$modal.show('category-form-modal');
      },

      showCategoryForm () {
        this.$modal.show('category-form-modal');
      },
      hideCategoryForm () {
        this.$modal.hide('category-form-modal');
      },


      setActiveCategoryTab(position){
        this.$store.dispatch('settings/setActiveTournamentCategoryTab', position);
      },

      resortCategoryDays(){
        let day1 = document.getElementById('day1');
        let day2 = document.getElementById('day2');
        let itemsDay1 = day1.getElementsByClassName('category-item');
        let itemsDay2 = day2.getElementsByClassName('category-item');

        let categoryId = '';
        let day1ids = [];
        let day1position = [];
        let day2ids = [];
        let day2position = [];


        let i = 0;
        for (i = 0; i<itemsDay1.length; i++){
          if(itemsDay1[i] == undefined){
            continue;

          }
          if(itemsDay1[i].classList.contains('draggable-mirror')){

          }else if(itemsDay1[i].classList.contains('draggable-mirror')){

          }else if(itemsDay1[i].classList.contains('draggable--original')){

          }else{

            console.log(itemsDay1[i]);
            categoryId = itemsDay1[i].getAttribute('data-category-id');

            day1ids.push(categoryId);
            day1position.push({id:categoryId, position:i})
          }

        }

        i++;
        for (let k = 0; k<itemsDay2.length; k++){
          if(itemsDay2[i] == undefined){
            continue;

          }
          if(itemsDay2[i].classList.contains('draggable-mirror')){

          }else if(itemsDay2[k].classList.contains('draggable-mirror')){

          }else if(itemsDay2[k].classList.contains('draggable--original')){

          }else{
            categoryId = itemsDay2[k].getAttribute('data-category-id');
            day2ids.push(categoryId);
            day2position.push({id:categoryId, position:(k+i)})
          }

        }


        console.log('itemsDay1', itemsDay1);

        let params = {
          day1ids:day1ids,
          day2ids:day2ids,
          day1position:day1position,
          day2position:day2position,
        };
        console.log('params', params);
        this.$store.dispatch('tournament/setCategoryDays', params)
        // .then(() => this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.id}))
          .catch(error => {
            alert('Помилка сервера');
          });
      },

      downloadPdf(category, type){
        var url = baseUrlServer+'/tournament-result/print-pdf?category='+category.id;
        var nameFile = category.fullname.replace(/\s+/g,"_");
        if(type == 'weight'){
          nameFile = 'Протокол_Зважування_'+nameFile;
          url = baseUrlServer+'/tournament-report/print-weight-pdf?category='+category.id;
        }

        axios({
          url: url,
          method: 'GET',
          responseType: 'blob',
        }).then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;

          fileLink.setAttribute('download', nameFile+'.pdf');
          document.body.appendChild(fileLink);

          fileLink.click();
        });
      }


    }
  }
</script>

<style scoped>

</style>
