<template>
  <div :class="{'nav-open': $sidebar.showSidebar}">
    <notifications></notifications>
    <notifications group="foo" />
    <div></div>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'App',
    // watch: {
    //   '$route':{
    //     handler: (to, from) => {
    //       // document.title = to.meta.title || 'Taekwondo.com.ua'
    //       document.title = 'Taekwondo.com.ua'
    //     },
    //     immediate: true
    //   }
    // },
    metaInfo: {
      title: 'Taekwondo system',
      titleTemplate: '%s | Taekwondo.com.ua',
      htmlAttrs: {
        lang: 'uk-UA'
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: 'Taekwondo system Ukraine' },
        { name: 'viewport', content: 'width=1000, initial-scale=1' }

      ]
    },
  }
</script>
<style lang="scss">
  .vue-notifyjs.notifications{
    .list-move {
      transition: transform 0.3s, opacity 0.4s;
    }
    .list-item {
      display: inline-block;
      margin-right: 10px;

    }
    .list-enter-active {
      transition: transform 0.2s ease-in, opacity 0.4s ease-in;
    }
    .list-leave-active {
      transition: transform 1s ease-out, opacity 0.4s ease-out;
    }

    .list-enter {
      opacity: 0;
      transform: scale(1.1);

    }
    .list-leave-to {
      opacity: 0;
      transform: scale(1.2, 0.7);
    }
  }

   .v--modal-overlay.scrollable {
     z-index: 9999;
   }

  .v--modal-overlay {
    background: #333333d4;
  }
</style>
