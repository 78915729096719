<template>
  <div class="container-fluid">
  <div class="row"  v-if="tournamentOne.data  && tournamentOne.data.item">
    <div class="col-12">
      <card style="min-height: 70px" class="strpied-tabled-with-hover"
            body-classes="table-full-width table-responsive"
      >
        <template slot="header">
          <h4 class="card-title" v-if="tournamentOne.data ">{{tournamentOne.data.item.name}}</h4>
          <div style="padding: 20px 0px; " class="float-left">
             <span> <label class="control-label text-dark">
               <i class="nc-icon nc-spaceship"></i> Публікація:
              </label>
              <span v-if="tournamentOne.data.item.published == 1" style="margin-left: 10px;" class="badge badge-success">Опубліковано</span>
              <span v-if="tournamentOne.data.item.published == 0" style="margin-left: 10px;" class="badge badge-danger">Не опубліковано</span>
             </span>

            <span style="margin: 0 50px;"> <label class="control-label text-dark">
               <i class="nc-icon nc-time-alarm"></i> Дата:
              </label>
              <span style="margin-left: 10px;" class="text-dark">{{tournamentOne.data.item.date}}</span>
             </span>



            <span style="margin: 0 50px;"> <label class="control-label text-dark">
               <i class="nc-icon nc-square-pin"></i> Місто:
              </label>
              <span style="margin-left: 10px;" class="text-dark">{{tournamentOne.data.item.city}}</span>
             </span>

            <span style="margin: 0 50px;"> <label class="control-label text-dark">
               <i class="nc-icon nc-square-pin"></i> Адреса:
              </label>
              <span style="margin-left: 10px;" class="text-dark">{{tournamentOne.data.item.address}}</span>
             </span>

            <span style="margin: 0 50px;"> <label class="control-label text-dark">
               <i class="nc-icon nc-square-pin"></i> Контакти:
              </label>
              <span style="margin-left: 10px;" class="text-dark">{{tournamentOne.data.item.contacts}}</span>
             </span>

          </div>

          <div class="float-right">
            <button class="btn btn-sm  btn-warning btn-fill"
                    @click="updateTournament(tournamentOne.data.item.id)">
              <i class="nc-icon nc-ruler-pencil"  ></i> Редагувати
            </button>

          </div>

        </template>
      </card>

    </div>
  </div>
  </div>
</template>

<script>
  export default {
    name: "info-navbar",

    computed: {
      tournamentOne() {
        return this.$store.getters['tournament/tournamentOne'];
      },


    },
    methods: {
      updateTournament(id) {
        this.$router.replace({path: `/user/tournaments/update/${id}`}) // -> /user/123
        // this.$router.push({ name: 'TournamentFormUpdate', params: {id} });
        // router.push({ path: `/user/tournaments/update/1` })
      },
    }
  }
</script>

<style scoped>

</style>
