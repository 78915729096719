export const regions = [
  // TODO переробити збереження областей
  {val: '', name: 'Автономна Республіка Крим'}, {val: '', name: 'Вінницька область'}, {
    val: '',
    name: 'Волинська область'
  }, {val: '', name: 'Дніпропетровська область'}, {val: '', name: 'Донецька область'}, {
    val: '',
    name: 'Житомирська область'
  }, {val: '', name: 'Закарпатська область'}, {val: '', name: 'Запорізька область'}, {
    val: '',
    name: 'Івано-Франківська область'
  }, {val: '', name: 'Київська область'}, {val: '', name: 'Кіровоградська область'}, {
    val: '',
    name: 'Луганська область'
  }, {val: '', name: 'Львівська область'}, {val: '', name: 'Миколаївська область'}, {
    val: '',
    name: 'Одеська область'
  }, {val: '', name: 'Полтавська область'}, {val: '', name: 'Рівненська область'}, {
    val: '',
    name: 'Сумська область'
  }, {val: '', name: 'Тернопільська область'}, {val: '', name: 'Харківська область'}, {
    val: '',
    name: 'Херсонська область'
  }, {val: '', name: 'Хмельницька область'}, {val: '', name: 'Черкаська область'}, {
    val: '',
    name: 'Чернівецька область'
  }, {val: '', name: 'Чернігівська область'}, {val: '', name: 'Київ'}, {val: '', name: 'Севастополь'},];
