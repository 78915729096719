import axios from 'axios';


let baseUrl = '';
if (process.env.NODE_ENV == 'development') {
    baseUrl = 'https://app.taekwondo.com.ua';
} else {
    baseUrl = 'https://app.taekwondo.com.ua';
}
const base = baseUrl;
export default {

    getCategories() {
        return axios.get(base + '/wf-api/data/categories');
    },
}
