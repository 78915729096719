<template>
  <div class="tournament-content-tab" v-bind:class="{'tournament-content-active-tab':activeTab == 5}">
    <!--        <div class="row" style="padding-left: 500px;">-->
    <div class="row" style="">
      <div class="col-md-3 fixed-categories" style="position: relative; z-index:2; left: 0; top:0">
        <card>
          <div>
            <button v-bind:class="{'btn-warning':hideEmptyCategories}" @click="clickHideEmptyCategories()"
                    class="btn btn-sm btn-fill checkbox-list-button" style="font-size: 14px;">
                                                <span class="checkbox-container"><i class="nc-icon nc-check-2"
                                                                                    style="vertical-align: super"
                                                                                    v-if="hideEmptyCategories"></i> </span>
              Сховати пусті категорії
            </button>

          </div>
          <div style="    max-height: 600px;    overflow: auto;">
            <table class="table table-bordered">
              <tbody>
              <tr style="cursor: pointer" v-for="(item, index) in tournamentOne.data.item.protocols" :key="index"
                  v-if="!hideEmptyCategories || (hideEmptyCategories== true && item.count > 0)"
                  @click="selectDrawCategory(index)" v-bind:class="{'bg-info':selectedDrawCategory.id == item.id}">
                <!--                      <td>  <span>{{item.id}}</span></td>-->
                <td><i class="nc-icon nc-notes" v-if="index % 2"></i> <i class="nc-icon nc-bullet-list-67"
                                                                         v-else></i>
                  <span style="margin-left: 5px;">{{item.fullname}}</span>

                  <div class="dop-info">
                    <button v-if="item.type == 1" class="btn btn-sm btn-danger btn-fill" style="padding: 2px;">
                      керугі
                    </button>
                    <button v-if="item.type == 2" class="btn btn-sm btn-fill"
                            style="background: #9368e9; padding: 2px;">пумсе
                    </button>
                    <button v-if="item.show_type == 1" class="btn btn-sm btn-warning btn-fill"
                            style="padding: 2px;">сітка
                    </button>
                    <button v-if="item.show_type == 2" class="btn btn-sm  btn-fill" style="padding: 2px;">список
                    </button>
                    <button v-if="item.show_type == 1" class="btn btn-sm btn-dark btn-fill" style="padding: 2px;">
                      {{item.type == 2 && item.poomsae_type == 1 ? 'традиційне':''}}{{item.type == 2 &&
                      item.poomsae_type == 2 ? 'вільне':''}}
                    </button>

                    <span>{{item.sex==1 ? 'Чоловіки': ''}}{{item.sex==2 ? 'Жінки': ''}}</span>
                  </div>
                </td>
                <td
                  style="border-bottom: none;border-left: none; border-right: none;display: table-cell;width: 40px;">
                  <span>{{item.count}}</span></td>
              </tr>
              </tbody>
            </table>
          </div>
        </card>
      </div>

      <div class="col-md-3 fixed-categories" style="position: relative; z-index:2; left: 0; top:0">

        <card>
          <!--<div style="margin-bottom: 15px;">
            <button @click="clickHideEmptyCategories()"
                    class="btn btn-sm btn-fill  " >

              Напів-Автоматично жеребкувати
            </button>

          </div>-->


          <div style="    max-height: 600px;    overflow: auto;" id="pendingdrawingblock" class="pending-drawing-block">
                <div :data-sp="item.id" style="padding: 2px 5px" class="sp-item  sortable-drag-item" v-for="(item, index) in selectedDrawCategory.sportsmans" :key="selectedDrawCategory.id+'_pending_drawing_'+index" v-if="item.in_bracket < 1">
                  <div class="group-team group-team-top text-top-line "><span  class="tehnical-number"></span>{{ item.name }}                  </div>
                  <div class="group-team group-team-top text-bottom-line">{{ item.organization }}</div>
                  <div class="group-team group-team-top line-center"></div>
                </div>

          </div>
        </card>
      </div>




      <div class="col-md-6">
        <brackets-constructor v-if="selectedDrawCategory.show_type == 1"
                              :selectedDrawCategory.sync="selectedDrawCategory"></brackets-constructor>
        <list-constructor v-if="selectedDrawCategory.show_type == 2"
                          :selectedDrawCategory.sync="selectedDrawCategory"></list-constructor>
        <!--            <list-constructor-new ></list-constructor-new>-->
      </div>
    </div>
  </div>
</template>

<script>
  import UserBracketsContructor from 'src/pages/tools/UserBracketsContructor.vue';
  import ListContructor from 'src/pages/tools/ListContructor.vue';
  import ListContructorNew from 'src/pages/tools/ListContructorNew.vue';

  export default {
    name: "tab-results",
    props:['category'],
    components: {
      'list-constructor-new': ListContructorNew,
      'list-constructor': ListContructor,
      'brackets-constructor': UserBracketsContructor,
    },
    data() {
      return {
        selectedDrawCategory: {},
        hideEmptyCategories: false,
        showBracketCategories: false,
        regions: [],
        file: '',
      }
    },
    computed: {
      activeTab() {
        return this.$store.getters['settings/activeTournamentTab'];
      },
      tournamentOne() {
        return this.$store.getters['tournament/tournamentOne'];
      },
    },
    watch: {


      tournamentOne: function(newVal, oldVal) {

        // if (this.category != undefined) {
        //   if (this.selectedDrawCategory.id == undefined) {
        //     let length = this.tournamentOne.data.item.protocols.length;
        //     for (let i = 0; i < length; i++) {
        //
        //       if (this.tournamentOne.data.item.protocols[i].id === this.category){
        //         console.log(this.tournamentOne.data.item.protocols[i].id);
        //         // this.tournamentOne.data.item.sportsmans.splice(i, 1);
        //         // this.selectDrawCategory(i);
        //         this.selectedDrawCategory = this.tournamentOne.data.item.protocols[i];
        //         break;
        //       }
        //     }
        //   }
        //
        // }



      },
    },

    mounted(){
      this.openCategoryFromUrl();
    },

 updated(){
      // this.openCategoryFromUrl();
    },

    methods: {

      openCategoryFromUrl(){
        if (this.category != undefined) {
          console.log('this.category ', this.category );

          // this.$store.dispatch('settings/setActiveTournamentTab', 4);
          let length = this.tournamentOne.data.item.protocols.length;
          for (let i = 0; i < length; i++) {

            if (this.tournamentOne.data.item.protocols[i].id === this.category){
              // console.log(this.tournamentOne.data.item.protocols[i].id);
              // console.log('this.selectedDrawCategory ', this.selectedDrawCategory );
              // this.tournamentOne.data.item.sportsmans.splice(i, 1);
              // this.selectDrawCategory(i);
              // console.log('category index iiii',this.tournamentOne.data.item.protocols[i].id, this.category);
              console.log('category index iiii', i);


              // if()

              this.selectedDrawCategory = this.tournamentOne.data.item.protocols[i];
              // this.$forceUpdate();
              console.log('this.selectedDrawCategory ', this.selectedDrawCategory );
              // break;
            }
          }


        }
      },

      selectDrawCategory(category){
        console.log('selectDrawCategory1', category);
        let selectedDrawCategory = this.tournamentOne.data.item.protocols[category];
        this.selectedCategory = selectedDrawCategory.id;
        console.log('selectedDrawCategory', selectedDrawCategory);
        // this.selectedDrawCategory = category;
      },
      // selectDrawCategory(category) {
      //   // this.$destroy();
      //   // this.$destroy(UserBracketsContructor);
      //   // var vm = new tmp().$mount('#mount-point');
      //   // vm.$destroy();
      //
      //   console.log('category index', category);
      //
      //   // this.selectedDrawCategory = this.tournamentOne.data.item.protocols[category];
      //   let selectedDrawCategory = this.tournamentOne.data.item.protocols[category];
      //
      //   // this.$router.replace({ path: `/user/tournaments/overview/${this.tournamentOne.data.item.id}/bracket/${selectedDrawCategory.id}` }) // -> /user/123
      //   window.location = `/user/tournaments/overview/${this.tournamentOne.data.item.id}/bracket/${selectedDrawCategory.id}`;
      //   // http://localhost:8080/user/tournaments/overview/4/bracket/195
      //   // this.selectedDrawCategory = category;
      // },


      clickHideEmptyCategories() {
        // console.log(clickHideEmptyCategories)
        if (this.hideEmptyCategories == true) {
          this.hideEmptyCategories = false;
        } else {
          this.hideEmptyCategories = true;
        }
      },
    }

  }
</script>

<style scoped>

</style>
