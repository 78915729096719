<template>
  <div>
    <div class="super_container">

      <!-- Header -->




      <!-- Home -->

      <div class="home" style="max-height: 340px">
        <div data-v-5a90ec03="" data-parallax="scroll" data-image-src="images/index.jpg" data-speed="0.8" class="parallax_background parallax-window" style="">
          <div class="shadow" style="position: absolute;left: 0;top: 0;height: 100%;width: 100%;background: #000;opacity: 0.8;"></div>




        </div>
        <div class="home_container" style="">
          <div class="container align-items-center">
            <div class="row">
              <div class="col">
                <div class="home_content text-center center-block">
                  <div class="home_title">Вхід </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="contact" style="min-height: calc(100vh - 790px);">
        <div class="container">
          <div class="row" style="justify-content: center">

            <!-- Contact Info -->
            <div class="col-lg-4">
              <div class="contact_info_container text-center" style="margin-left: auto; margin-right: auto;">
                <div class="contact_title">Кабінет секретаря</div>
                <div class="contact_text">
<!--                  <p>Якщо Ви забули пароль, будь-ласка напишіть звернення в <a href="#">службу технічної підтримки </a></p>-->
                </div>

                <form action="/login" @submit.prevent="userLoginForm"  autocomplete="on">
                  <div class="contact_form" id="contact_form1">
                    <div class="row contact_row" >
                      <div class="col-lg-12"> <label for="login">E-mail або Логін</label> <input name="login" id="login" v-model="userLogin.email.value" type="text" class="contact_input" placeholder="E-mail or login" required="required"></div>
                      <div class="col-lg-12"><label for="password">Пароль</label> <input  name="password" id="password" v-model="userLogin.password.value" type="password" class="contact_input" placeholder="password" required="required"></div>
                    </div>
                    <div class="row">
                      <div class="col-lg-12 text-danger" v-if="hasErrorLogin">
                        <ul>
                          <li v-for="error in userLoginErrors">{{error[0]}}</li>
                        </ul>
                      </div>
                    </div>
                    <button class="contact_button trans_200" type="submit">Увійти</button>
                  </div>
                </form>


              </div>
            </div>

<!--
                        <div class="col-lg-7 offset-1 contact_form_col">
                          <div class="contact_form_container">
                            <div class="contact_title">Реєстрація</div>
                            <div class="contact_text">
                              <p>Реєстрація на даний момент закрита, але якщо у Вас є запитання - пишіть на пошту <a href="mailto:Taekwondo.com.ua@gmail.com">Taekwondo.com.ua@gmail.com</a>.  </p>
                            </div>

                          </div>
                        </div>-->

            <!-- Contact Form -->
            <div class="col-lg-7 offset-1 contact_form_col" v-if="1 < 0">
              <div class="contact_form_container">
                <div class="contact_title">Швидка реєстрація</div>
                <div class="contact_text">
                  <p>Якщо Вам цікавто спробувати функціонал без реєстрації - Ви можете скористатись <a href="/demo">Демо-режимом</a>.  </p>
                </div>
                <div class="contact_form" id="contact_form">
                  <div class="row contact_row">
                    <div class="col-lg-6"> <label for="username">Логiн</label> <input @focus="focusFieldSignup('username')" v-model="userSignup.username.value"  v-bind:class="{'has-error': !userSignup.username.valid}" type="text" id="username" class="contact_input" placeholder="Username" required="required"></div>
                    <div class="col-lg-6"> <label for="email">E-mail</label><input  @focus="focusFieldSignup('email')" v-model="userSignup.email.value" v-bind:class="{'has-error': !userSignup.email.valid}" type="email" id="email" class="contact_input" placeholder="E-mail" required="required"></div>

                    <div class="col-lg-6"> <label for="firstname">Ім'я</label> <input  @focus="focusFieldSignup('firstName')" v-model="userSignup.firstName.value" v-bind:class="{'has-error': !userSignup.firstName.valid}" type="text" id="firstname" class="contact_input" placeholder="Ім'я" required="required"></div>
                    <div class="col-lg-6"> <label for="lastname">Прізвище</label><input  @focus="focusFieldSignup('lastName')" v-model="userSignup.lastName.value" v-bind:class="{'has-error': !userSignup.lastName.valid}" type="text" id="lastname" class="contact_input" placeholder="Прізвище" required="required"></div>


                    <div class="col-lg-6"> <label for="passwordSignup">Пароль</label> <input  @focus="focusFieldSignup('password')" v-model="userSignup.password.value" v-bind:class="{'has-error': !userSignup.password.valid}" type="password" id="passwordSignup" class="contact_input" placeholder="" required="required"></div>
                    <div class="col-lg-6"> <label for="password2Signup">Повторіть пароль</label><input  @focus="focusFieldSignup('passwordRepeat')" v-model="userSignup.passwordRepeat.value" v-bind:class="{'has-error': !userSignup.passwordRepeat.valid}" type="password" id="password2Signup" class="contact_input" placeholder="" required="required"></div>

                  </div>

                  <div class="row">
                    <div class="col-lg-12 text-danger" v-if="hasErrorLogin">
                    <ul>
                      <li v-for="error in userSignupErrors">{{error[0]}}</li>
                    </ul>
                    </div>
                  </div>
                  <button class="contact_button trans_200" type="button" @click="userSignupForm">Реєстрація</button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>




      <!-- Footer -->
      <div class="c_right " style="padding-bottom: 30px"    >
        <div class="container">
          <div class="row row-lg-eq-height">


            <!-- Content Right Content -->
<!--            <div class="col-lg-12 order-lg-2 order-1 text-center">
              <div class=" ">
                <div class="section_title_container">
                  <div class="section_title"><h2>Taekwondo.com.ua</h2></div>
                </div>
                <div class="c_right_text">
                  <p>Сервіс допомагає організувати проведення турнірів. Організатор може в режимі реального часу слідкувати за ходом проведення турнірів, змагань.
                   </p>
                </div>
                <div class="content_list_1 c_right_list">
                  <ul>
                    <li>Реєстрація учасників</li>
                    <li>Допомога в проведенні жеребкування</li>
                    <li>Швидке формування необхідної звітності</li>
                    <li>Проведення закритих(приватних) турнірів</li>
                  </ul>
                </div>
                &lt;!&ndash;              <div class="button c_right_button"><a href="#">Створити чемпіонат</a></div>&ndash;&gt;
              </div>
            </div>-->
          </div>
        </div>
      </div>

    </div>
  </div>



</template>
<script>
  import Card from 'src/components/Cards/Card.vue'

  export default {
    components: {
      Card
    },
    data () {
      return {
        user: {
          company: '',
          username: '',
          email: '',
          firstName: '',
          lastName: '',
          address: '',
          city: '',
          region: '',
          country: '',
          aboutMe: ``
        },

        userSignup: {
          username:{ value:'', valid:true, error:''},
          email:{ value:'', valid:true, error:''},
          firstName:{ value:'', valid:true, error:''},
          lastName:{ value:'', valid:true, error:''},
          password:{ value:'', valid:true, error:''},
          passwordRepeat:{ value:'', valid:true, error:''},
        },
        userLogin: {
          email:{ value:'', valid:true, error:''},
          password:{ value:'', valid:true, error:''},
        },
        userSignupErrors:[],
        userLoginErrors:[]
      }
    },
    computed: {
      // isLoading() {
      //   return this.$store.getters['tournament/isLoading'];
      // },
      hasErrorSignup() {
        return this.$store.getters['user/hasErrorSignup'];
      },
      errorSignup() {
        return this.$store.getters['user/errorSignup'];
      },
      errorLogin() {
        return this.$store.getters['user/errorLogin'];
      },

      hasErrorLogin() {
        return this.$store.getters['user/hasErrorLogin'];
      },


    },
    watch: {
      // whenever question changes, this function will run
      errorLogin: function (newValue, oldValue) {
        if(newValue != null && newValue != undefined && newValue.messages != undefined){
          this.userLoginErrors = newValue.messages;
        }else{
          this.userLoginErrors   = '';
        }
      },
      // whenever question changes, this function will run
      errorSignup: function (newValue, oldValue) {
        if(newValue.messages != undefined){
          this.userSignupErrors = newValue.messages;
        }
        if(newValue.fields != undefined){
          if(newValue.fields.includes('username')){
            this.userSignup.username.valid = false;
          }else{
            this.userSignup.username.valid = true;
          }

          if(newValue.fields.includes('email')){
            this.userSignup.email.valid = false;
          }else{
            this.userSignup.email.valid = true;
          }

          if(newValue.fields.includes('firstName')){
            this.userSignup.firstName.valid = false;
          }else{
            this.userSignup.firstName.valid = true;
          }

          if(newValue.fields.includes('lastname')){
            this.userSignup.lastName.valid = false;
          }else{
            this.userSignup.lastName.valid = true;
          }

          if(newValue.fields.includes('password')){
            this.userSignup.password.valid = false;
          }else{
            this.userSignup.password.valid = true;
          }

          if(newValue.fields.includes('passwordRepeat')){
            this.userSignup.passwordRepeat.valid = false;
            this.userSignup.password.valid = false;
          }else{
            this.userSignup.passwordRepeat.valid = true;
            this.userSignup.password.valid = true;
          }

        }

      }
    },
    methods: {
      updateProfile () {
        alert('Your data: ' + JSON.stringify(this.user))
      },
      toRegistration(){
        this.$router.replace({ path: `/signUp` }) // -> /user/123
      },
      userSignupForm(){
        let params = {username:this.userSignup.username.value, email:this.userSignup.email.value, password:this.userSignup.password.value, passwordRepeat:this.userSignup.passwordRepeat.value, fname:this.userSignup.firstName.value, lname:this.userSignup.lastName.value};
        this.$store.dispatch('user/createUser', params);
        // alert('Your data: ' + JSON.stringify(this.userSignup));
      },
      userLoginForm(){
        let params = { email:this.userLogin.email.value, password:this.userLogin.password.value};
        let objthis =  this;
        this.$store.dispatch('user/loginUser', params).then(function (res){


          if(res.status == 200){
            // нужно делать так редирект чтобы добавился токен в хедере запроса
            location.href = '/user';
          }else{
            alert ('Server Error');
          }

        }) ;// -> /user/123);
        // alert('Your data: ' + JSON.stringify(this.userSignup));

        return false;
      },

      focusFieldSignup(field){
        if(field =='username'){
          this.userSignup.username.valid = true;
        }

        if(field =='email'){
          this.userSignup.email.valid = true;
        }

        if(field =='firstName'){
          this.userSignup.firstName.valid = true;
        }

        if(field =='lastname'){
          this.userSignup.lastName.valid = true;
        }

        if(field =='password'){
          this.userSignup.password.valid = true;
        }

        if(field =='passwordRepeat'){
          this.userSignup.passwordRepeat.valid = true;
        }

        this.userSignupErrors = '';
      }
    }
  }

</script>


<style scoped>

  /*@import "../assets/home/styles/bootstrap-4.1.2/bootstrap.min.css";*/
  /*@import "../assets/home/plugins/font-awesome-4.7.0/css/font-awesome.min.css";*/
  /*@import "../assets/home/styles/main_styles.css";*/
  /*@import "../assets/home/styles/main_styles.css";*/
  /*@import "../assets/home/styles/contact.css";*/
  /*@import "../assets/home/styles/responsive.css";*/
  /*@import "../assets/home/plugins/OwlCarousel2-2.2.1/animate.css";*/
</style>
