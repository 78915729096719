<template>
  <div>
    <div class="super_container">

      <!-- Header -->




      <!-- Home -->



      <div class="contact" style="min-height: calc(100vh - 790px); padding-top: 150px;">
        <div class="container">
          <div class="row">

            <!-- Contact Info -->
            <div class="col-lg-12">
              <div class="contact_title">ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ</div>
              <div class="contact_text">

                <p><strong>Введение</strong></p>
                <p>Благодарим вас за использование taekwondo.com.ua («Веб-сайт»). В ходе нашей совместной работы мы собираем и обрабатываем личные данные о вас и ваших клиентах, чтобы предоставить вам Услуги, которые вы запрашиваете или получить доступ к программному обеспечению Taekwondo.com.ua, и управлять нашими отношениями с вами. В этой Политике конфиденциальности («Политика») подробно описывается, как мы собираем и используем ваши персональные данные и ваших клиентов и как вы можете использовать свои права в этом отношении.</p>
                <p>В настоящей Политике конфиденциальности ссылки на «мы», «нас» или «наш» (или слова с аналогичным смыслом) означают платформу «Taekwondo.com.ua», которая обрабатывает ваши личные данные и взаимодействует с вами.</p>
                <p>Ссылки на «вы», «ваш» означают нашего клиента, человека, личную информацию которого мы собираем, используем и обрабатываем. Он может относиться к любому, кто может связаться с нами в отношении Услуг или иным образом взаимодействовать с нами.</p>
                <p>Все капитализированные условия, упомянутые ниже, имеют то же значение, что и в Условиях использования, если не указано иное.</p>
                <p>Настоящая Политика конфиденциальности применяется к вам в течение всего использования этого Веб-сайта, наших Услуг и программного обеспечения Taekwondo.com.ua.</p>
                <p>&nbsp;</p>
                <p><strong>Основные положения</strong></p>
                <p>Наша цель - гарантировать вам Услуги самого высокого качества. Для осуществления этого, мы стремимся иметь как простую, так и точную процедуру идентификации, как способа общения и предоставить вам возможность сделать выбор.</p>
                <p>В соответствии с законодательством EC о защите данных в отношении обработки личных данных наших прямых клиентов мы выступаем в качестве «контролера», то есть лица, ответственного за предоставленную вами информацию. В соответствии с Общими правилами защиты данных (ЕС) 2016/679 от 27 апреля 2016 года («GDPR») контролер - это лицо, которое определяет цели и средства обработки ваших персональных данных. Что касается обработки персональных данных ваших клиентов, мы выступаем в качестве обработчика, а это означает, что человек обрабатывает личные данные от вашего имени (вы действуете в качестве контроллера в этом отношении). Обратите внимание, что мы не будем задействовать другого обработчика без предварительного или общего письменного разрешения.</p>
                <p>Эта Политика охватывает личные данные, которые мы собираем: (I) о вас как о пользователе наших Услуг и Программного обеспечения Taekwondo.com.ua; (II) о ваших клиентах (обычно получателях сообщений, отправленных через Программное обеспечение Taekwondo.com.ua); и (III) через наш Веб-сайт, в обычном порядке нашего бизнеса, в том числе в связи с нашей деятельностью проведения мероприятий, найма, продаж и маркетинга. Поэтому мы просим вас потратить некоторое время на тщательный анализ этой Политики, поскольку она содержит важную информацию о том, как мы будем собирать, хранить и обрабатывать ваши личные данные и ваших клиентов.</p>
                <p>Если вы имеете какие-либо вопросы относительно этой Политики или нашей обработки ваших личных данных, пожалуйста, напишите нам по адресу taekwondo.com.ua@gmail.com.</p>
                <p>&nbsp;</p>
                <p><strong>Ваши клиенты</strong></p>
                <p>У нас нет никаких отношений с вашими клиентами, и мы обрабатываем любую информацию, относящуюся к ним, исключительно для целей предоставления наших Услуг вам.</p>
                <p>Что касается информации ваших клиентов, включая личные данные, которые вы предоставляете Taekwondo.com.ua, мы работаем с обработчиком данных; но в отношении ваших собственных клиентов вы являетесь контроллером данных, и если какой-либо из ваших клиентов является гражданином или резидентом страны в Европейском союзе (ЕС), вы должны убедиться, что соблюдаете требования законодательства и свои обязательства как контроллер данных в рамках GDPR; а именно, вы должны обеспечить предоставление своим клиентам прав, определенных в GDPR. Кроме того, вы несете ответственность за поиск надлежащей правовой базы, но не ограничиваясь условиями GDPR от ваших клиентов, чтобы использовать их данные.</p>
                <p>Обратите внимание, что мы будем обрабатывать личные данные ваших клиентов только на основании ваших инструкций, как от контроллера.</p>
                <p>&nbsp;</p>
                <p><strong>Дети</strong></p>
                <p>Наш сайт и наши услуги не предназначены для детей в возрасте до тринадцати (13) лет. Никто в возрасте до тринадцати (13) лет не может предоставить нам какую-либо личную информацию. Мы не собираем сознательно или намеренно личную информацию от детей до тринадцати (13). Если вам не исполнилось тринадцать (13), не используйте и не предоставляйте какую-либо информацию на этом Веб-сайте или через наши Услуги, не покупайте подписку на Веб-сайте и не предоставляйте нам какую-либо информацию о себе, включая ваше имя, адрес, номер телефона, адрес электронной почты или любой ник или имя пользователя, которые вы можете использовать. Если мы узнаем, что мы собрали или получили личную информацию от ребенка в возрасте до тринадцати (13), мы удалим эту информацию. Если вы считаете, что у нас может быть какая-либо информация от ребенка в возрасте до тринадцати (13) лет, пожалуйста, свяжитесь с нами по адресу taekwondo.com.ua@gmail.com.</p>
                <p>&nbsp;</p>
                <p><strong>Какую информацию мы собираем о вас и как</strong></p>
                <p>Мы собираем, храним и обрабатываем ваши персональные данные на основе различных правовых оснований (т. е. «правовая основа» для целей GDPR), которая зависит от цели, для которой эти данные обрабатываются. Если у нас есть контракт с вами, мы используем ваши личные данные для выполнения такого контракта. Кроме того, ваши личные данные могут обрабатываться на основе наших законных интересов, вашего согласия или для того, чтобы мы могли выполнить определенные юридические обязательства. В любом случае такая правовая основа зависит от определенных целей обработки ваших персональных данных, которые описаны ниже. Обратите внимание, что в случае, если требуется ваше согласие, мы отдельно попросим об этом.</p>
                <ol>
                  <li>Личные данные, которые мы можем собирать у вас для выполнения контракта с вами, могут включать:</li>
                </ol>
                <p>(a) Контактную информацию: например, ваше имя, адрес электронной почты, ваш номер телефона, привязанный к вашему профилю.</p>
                <p>(b) Информацию профиля: например, ваши контактные данные (как указано выше) и другая информация, которой вы делитесь при покупке подписки на нашем Веб-сайте.</p>
                <p>(c) Информацию об оплате и транзакции: мы будем сохранять способ оплаты, который вы используете для транзакции с нами. Мы можем работать с определенными третьими лицами в качестве поставщиков платежных услуг, и мы также можем получать от вас некоторую информацию о вас. Мы не просматриваем и не храним информацию о вашей платежной карте. Сторонние поставщики услуг  обрабатывают информацию о платежной карте при использовании платежной карты для покупки подписки на нашем Веб-сайте. Поэтому мы рекомендуем вам внимательно ознакомиться с политикой конфиденциальности соответствующего поставщика платежных услуг. Однако они могут предоставить нам определенные ваши личные данные, которые могут включать:</p>
                <p>- имя владельца платежной карты;</p>
                <p>- последние четыре цифры вашей кредитной / дебетовой карты;</p>
                <p>- зарегистрированную электронную почту и</p>
                <p>- ваш адрес.</p>
                <p>Мы требуем информацию, изложенную в этом разделе, чтобы предоставить вам наши Услуги, в том числе для проверки вашей личности и ведения записей о ваших транзакциях и взаимодействиях с нами. В основном это информация, которую мы получаем непосредственно от вас.</p>
                <ol>
                  <li>У нас также есть законный интерес для обработки определенных ваших личных данных, которые могут включать:</li>
                </ol>
                <p>(a) Информацию для входа в систему: ваш логин (электронная почта) и пароль. Вы также можете подписаться на наш Веб-сайт через сторонние ресурсы (например, Facebook, Google+, VK, Telegram, Viber и т. д.). Как всегда, вас спросят, хотите ли вы, чтобы такие сторонние ресурсы предоставили нам информацию о вас. Если вы решите это сделать, мы получим информацию о профиле, такие как ваш логин, имя пользователя и фото (аватар), пол и другая информация из этой службы, как разрешено вами и этим ресурсом.</p>
                <p>Нам может понадобиться эта информация для защиты безопасности вашей учетной записи (профиля) и предоставления вам альтернативных способов входа в вашу учетную запись (профиль).</p>
                <p>(b) Личные данные, которые мы собираем для общения с вами для целей, связанных с нашими Услугами:</p>
                <p>- ваши контактные данные (адрес электронной почты, номер телефона);</p>
                <p>- информацию, которую вы можете предоставить нам, когда вы взаимодействуете с нашей службой поддержки клиентов или используете нашу систему обмена сообщениями;</p>
                <p>- для улучшения обслуживания и поддержки клиентов мы также можем записывать ваши разговоры с нашими сотрудниками.</p>
                <p>Мы можем использовать эту информацию о вас для связи с вами касательно наших Услуг и Программного обеспечения Taekwondo.com.ua (в том числе, чтобы сообщить вам о любых изменениях, внесенных в наши Услуги и / или Программное обеспечение Taekwondo.com.ua) и предоставить вам качественную поддержку клиентов.</p>
                <p>(c) Когда вы используете наши Услуги или получаете доступ к нашему Веб-сайту, мы можем автоматически собирать неличную информацию, но которая потенциально может быть использована для идентификации вас лично. Это включает данные о вашем устройстве, браузере, ОС, вашем IP-адресе и общей информации о местоположении (стране) и часовом поясе.</p>
                <p>Эта информация может быть получена через ваш браузер и / или различные технологии, включая журналы сервера, файлы cookies и аналогичные технологии (например, веб-маяки).</p>
                <p>(d) Определенные аналитические данные, которые мы можем получать о вас от сторонних поставщиков, таких как Google Analytics и аналогичные ресурсы.</p>
                <p>Мы используем доверенных сторонних поставщиков, таких как Google, для отслеживания вашей активности на нашем Веб-сайте, отслеживания использования сайта с помощью Google Analytics и показов рекламы на Веб-сайте в Интернете. Если вы решили включить Google для сопоставления истории веб-поиска и просмотра приложений в своей учетной записи Google и использовать информацию из своего аккаунта Google для персонализации рекламы, Google будет использовать ваши данные из вашей учетной записи Google вместе с нашими данными Google Analytics, для создания списков аудитории для целей ремаркетинга с использованием нескольких устройств. Это означает, что с помощью входных пользовательских данных объявления могут отображаться на любом устройстве, которое связано с вашей учетной записью Google.</p>
                <p>Для поддержки этой функции, произойдут две вещи:</p>
                <ul>
                  <li>во-первых, для пользователей на нашем Веб-сайте, Google Analytics будет собирать идентификаторы, подтвержденные Google, связанные с учетными записями пользователей Google (и, следовательно, с личной информацией);</li>
                  <li>во-вторых, Google Analytics временно присоединится к этим идентификаторам к нашим данным Google Analytics, чтобы поддержать нашу аудиторию.</li>
                </ul>
                <p>Обратите внимание, что мы не собираем и не храним любую личную информацию от посетителей нашего сайта в рамках нашей программы ремаркетинга. Для получения дополнительной информации ознакомьтесь с страницами политики конфиденциальности Google и Analytics. Вы можете управлять своими объявлениями (или вообще отказаться от персонализированных объявлений) в своей учетной записи Google.</p>
                <p>Мы используем ремаркетинг только для предоставления вам дополнительной информации и рекламных акций, которые согласуются с прошлым поведением на нашем Веб-сайте.</p>
                <p>&nbsp;</p>
                <p>III. С вашего предварительного согласия на отказ мы можем обработать следующую информацию для следующих целей:</p>
                <p>(а) ваши контактные данные (адрес электронной почты, номер телефона), которые мы можем обрабатывать для целей прямого маркетинга, включая отправку вам маркетинговой информации о наших Услугах и / или услугах или продуктах наших партнеров, которые могут вас заинтересовать. Юридическая основа, на которой мы можем обрабатывать ваши персональные данные для маркетинговых целей, - это ваше прямое согласие, за исключением того, что вы уже являетесь нашим существующим клиентом, а маркетинговое сообщение касается Услуг, аналогичных тем, которые вы уже приобрели у нас. В этом случае наша правовая основа для этой обработки - наша законная заинтересованность в развитии нашего бизнеса.</p>
                <p>Мы используем эту информацию, чтобы предоставить вам информацию о наших продуктах и третьих лиц, услугах, рекламных акциях и специальных предложениях, которые могут вас заинтересовать. Мы никогда не будем предоставлять третьим лицам ваши личные данные для целей прямого маркетинга без вашего предварительного согласия.</p>
                <p>(b) если вы проживаете в Европейском союзе (ЕС) или Европейской экономической зоне («EЭЗ») или любой другой юрисдикции, которая может потребовать от нас получить ваше предварительное согласие на использование cookies (и / или других аналогичных технологий) на нашем Веб-сайте, мы предоставим вам возможность управлять вашими предпочтениями cookies на Веб-сайте. Используя файлы cookies и другие подобные технологии, мы можем собирать у вас:</p>
                <p>- информацию о том, как вы используете и подключаетесь к нашему Веб-сайту.</p>
                <p>- как вы используете Веб-сайт, страницы и ссылки, к которым вы обращаетесь, время, когда вы получаете доступ к Веб-сайту, и продолжительность, на протижении которой вы находитесь на Веб-сайте, сайт, с которого вы приходите или уходите после выхода с Веб-сайта, а также варианты выбора, которые вы делаете при использовании Веб-сайта,</p>
                <p>- информацию о компьютере или других электронных устройствах, которые вы используете для подключения к Веб-сайту, такую как сведения о типе устройства (это может включать идентификацию уникального номера устройства), его операционной системе, браузере и приложениях, подключенных к Веб-сайту, посредством устройства, ваш интернет-провайдер или мобильная сеть, ваш IP-адрес и номер телефона вашего устройства (если он есть).</p>
                <p>Как правило, информация, которую мы собираем о том, как вы используете или подключаетесь к Веб-сайту, связана с вашим устройством, а не с вами как с личностью. Тем не менее, если вы вошли в зарегистрированную учетную запись на нашем Веб-сайте, эта информация также может быть напрямую связана с вами.</p>
                <p>- Информацию о вашем местонахождении: кроме информации, которую вы выбрали для нас, мы никогда не собираем информацию о том, где вы находитесь. IP-адрес вашего устройства на вашем электронном устройстве может помочь нам определить только ваше приблизительное местоположение, но это будет не более точно, чем город, штат или страна, в которой вы используете свое устройство.</p>
                <p>Мы требуем эту информацию для обеспечения того, чтобы контент с нашего Веб-сайта был представлен наиболее эффективным образом для вас и для вашего компьютера (устройства), а также для того, чтобы помочь нам проанализировать, как вы и другие посетители используете наш Веб-сайт. Вы можете больше узнать о cookies, которые мы используем на нашем Веб-сайте, из нашей политики Cookies.</p>
                <p>Вы в основном представляете всю информацию лично. Но мы можем получить информацию самостоятельно с вашего согласия. Информация может быть представлена после того как вы:</p>
                <ul>
                  <li>заполните любые анкеты или любые формы;</li>
                  <li>свяжитесь с нами любым способом;</li>
                  <li>идентифицируете свою личность.</li>
                </ul>
                <p>&nbsp;</p>
                <p>Мы также можем получить информацию с помощью:</p>
                <ul>
                  <li>отслеживания ваших действий и взаимодействий на нашем Веб-сайте;</li>
                  <li>отслеживания транзакций на этом Веб-сайте;</li>
                  <li>использования файлов cookies или любых подобных технологий.</li>
                </ul>
                <p>&nbsp;</p>
                <p>Мы никогда не получаем информацию, которая касается или раскрывает ваши:</p>
                <ul>
                  <li>политические взгляды;</li>
                  <li>этническое или расовое происхождение;</li>
                  <li>философские, религиозные или любые другие убеждения подобного рода;</li>
                  <li>сексуальную жизнь;</li>
                  <li>ваше психическое или физическое состояние, или здоровье;</li>
                </ul>
                <p>&nbsp;</p>
                <p><strong>Как долго мы можем хранить вашу информацию</strong></p>
                <p>Мы будем хранить вашу информацию только до тех пор, пока ваша учетная запись (профиль) будет считаться активной и до тех пор, пока нам нужно сохранить ее для целей, описанных в настоящей Политике конфиденциальности. Это также включает всех, с кем мы делим ваши данные, и кто выполняет услуги от нашего имени. Если нам больше не нужно обрабатывать и хранить ваши данные, и у нас нет необходимости соблюдения наших юридических и / или нормативных обязательств, мы либо удалим их, либо обезличим, и тогда мы не сможем идентифицировать вас.</p>
                <p>&nbsp;</p>
                <p><strong>Информация о вас, которой мы делимся с третьими лицами. Категории получателей</strong></p>
                <p>Мы можем раскрыть ваши личные данные третьим лицам, в том числе в следующих случаях:</p>
                <ul>
                  <li>Поставщики услуг. Мы используем различные третьи стороны для предоставления нам услуг или для выполнения определенных видов деятельности от нашего имени, связанных с обработкой ваших персональных данных. Эти сторонние поставщики имеют доступ к вашим личным данным только на основе «необходимости знать», исключительно для целей выполнения этих задач и в той мере, в какой это необходимо для предоставления своих услуг.</li>
                  <li>Наши филиалы. Для поддержки в предоставлении Услуг или доступа к нашей Платформе, мы можем передавать ваши личные данные между нашими дочерними компаниями.</li>
                  <li>Третьи лица (в случае смены контроля). Мы можем продавать, раскрывать или передавать информацию о вас в рамках корпоративной бизнес-операции, такой как приобретение или слияние, финансирование, корпоративная реорганизация, совместное предприятие, включая продажу активов компании, или в маловероятном случае банкротства, конкурсного производства или несостоятельности, в которой такая информация может быть раскрыта любым третьим сторонам в качестве бизнес-актива в сделке.</li>
                  <li>Третьи лица (в случае защиты наших прав). Мы можем раскрыть ваши личные данные, если считаем, что это необходимо для защиты наших законных прав и интересов.</li>
                  <li>Суды, правоохранительные и государственные органы. Мы можем передавать вашу личную информацию третьим лицам, если мы обязаны раскрывать вашу личную информацию или делиться ею, чтобы выполнять любые юридические обязательства (в том числе в связи с постановлением суда).</li>
                  <li>Партнеры. Мы можем делиться неличными статистическими (групповыми) данными о посетителях на нашем Веб-сайте, структурах продаж и трафика с нашими партнерами и другими сторонами.</li>
                  <li>Другие третьи стороны. С вашего согласия мы можем раскрыть личную информацию о вас третьим лицам. Мы можем получить ваше согласие в письменной форме; онлайн, через лицензионные соглашения; когда вы принимаете условия раскрытия информации для определенных Услуг; устно, когда вы взаимодействуете с нашими представителями поддержки клиентов.</li>
                </ul>
                <p>&nbsp;</p>
                <p><strong>Где может обрабатываться ваша личная информация</strong></p>
                <p>Мы можем передавать персональные данные, которые мы собираем о вас, третьим лицам, расположенным в других странах, кроме тех, в которых вы находитесь, для целей, для которых они были собраны. Это также включает страны, которые не рассматриваются как часть ЕЭЗ и которые не могут предоставлять такие же гарантии защиты персональных данных, как и в ЕЭЗ.</p>
                <p>Любая такая передача будет происходить только в тех случаях, когда, по крайней мере:</p>
                <p>І. Сторонний получатель находится в стране, которая, согласно Европейской комиссии, обеспечивает адекватный уровень защиты вашей личной информации; и / или</p>
                <ol>
                  <li>В соответствии с соглашением, которое удовлетворяет требования ЕС для передачи персональных данных обработчикам данных или контроллерам данных за пределами ЕЭЗ, такие как стандартные договорные положения, одобренные Европейской комиссией.</li>
                </ol>
                <p>&nbsp;</p>
                <p><strong>Cookies</strong></p>
                <p>Использование файлов cookies на нашем Веб-сайте нацелено на то, чтобы улучшить вашу работу пользования. Вы можете найти более подробную информацию о файлах cookies в нашей <strong>Политике Cookies</strong>.</p>
                <p>&nbsp;</p>
                <p><strong>Ваши права конфиденциальности</strong></p>
                <p>Если вы являетесь физическим лицом, у вас есть следующие права в соответствии с GDPR в отношении любых ваших личных данных, которые мы сохраняем о вас:</p>
                <ul>
                  <li>право доступа к персональным данным, которые у нас есть. Обратите внимание, что ваше право на доступ к вашим личным данным может быть ограничено некоторыми законодательными ограничениями, защитой конфиденциальности других лиц.</li>
                  <li>право запрашивать исправление неверных и / или неполных данных. Если предоставленные вами данные являются неправильными и / или неполными, вы имеете право просить нас исправить, изменить, обновить ваши личные данные. Прежде чем делать такой запрос, обратите внимание, что некоторые ваши данные могут быть обновлены через вашу учетную запись.</li>
                  <li>право требовать стирания («право на забвение»). Вы имеете право попросить нас удалить некоторые или все ваши данные в случае если:</li>
                </ul>
                <p>- персональные данные больше не нужны в отношении целей, для которых они были собраны и / или обработаны</p>
                <p>- вы отказываетесь от своего согласия на обработку, и нет другой законной причины для обработки;</p>
                <p>- вы против обработки, и нет никаких основополагающих законных оснований для обработки;</p>
                <p>- вы против обработки для прямого маркетинга,</p>
                <p>- ваши личные данные были незаконно обработаны или</p>
                <p>- обработаны персональные данные детей.</p>
                <p>Тем не менее, мы можем сохранить некоторые ваши личные данные для целей соблюдения закона или если у нас есть законная заинтересованность в этом.</p>
                <ul>
                  <li>право ограничивать обработку ваших персональных данных. Вы имеете право ограничить обработку ваших данных в определенных обстоятельствах (например, вы имеете право ограничить нас при обработке ваших данных на период, позволяющий нам проверять точность ваших личных данных в случае, если вы оспариваете точность ваших персональных данных)</li>
                  <li>право возражать против обработки ваших личных данных, которое основано на наших законных интересах. Вы можете в любое время возражать против обработки ваших личных данных для прямого маркетинга, который включает профилирование в той мере, в какой это связано с таким прямым маркетингом.</li>
                  <li>право на переносимость данных. Если ваши личные данные были обработаны на основе согласия и автоматизированными средствами, вы имеете право на получение персональных данных, которые вы нам предоставили, в структурированном, обычно используемом и машиночитаемом формате. Если технически возможно, ваши данные могут быть переданы другому контроллеру данных.</li>
                </ul>
                <p>Во всех случаях вы можете сделать свой запрос, отправив нам электронное письмо по адресу taekwondo.com.ua@gmail.com.</p>
                <p>&nbsp;</p>
                <p><strong>Безопасность</strong></p>
                <p>Для нас очень важно обеспечить безопасность собранной информации, и мы предприняли множество защитных мер на Веб-сайте, а также используем наши Услуги, чтобы предотвратить несанкционированный доступ к вашим личным данным.</p>
                <p>Для защиты ваших личных данных доступ к информации о вас ограничен сотрудниками, агентами и подрядчиками, которым эти данные необходимы для выполнения своих обязанностей. Те лица, которые имеют доступ к вашим личным данным, подлежат строгому соблюдению договорной конфиденциальности.</p>
                <p>Мы также поддерживаем последние рекомендуемые защищенные пакеты и протоколы для шифрования всего трафика в пути. Мы шифруем передачу всей информации с использованием технологии уровня защищенных сокетов (SSL).</p>
                <p>&nbsp;</p>
                <p><strong>Ваше согласие</strong></p>
                <p>Для некоторых целей и в определенных ситуациях мы можем потребовать ваше согласие на обработку ваших личных данных. Примерами таких ситуаций являются обработка ваших данных в маркетинговых целях (за некоторыми исключениями). Если вы ранее дали нам свое согласие на обработку ваших личных данных, вы можете в любое время отказаться от такого согласия, написав нам по электронной почте taekwondo.com.ua@gmail.com.</p>
                <p>Если вы откажетесь от своего согласия, и если у нас нет другого юридического основания для обработки, мы прекратим обработку ваших личных данных.</p>
                <p>Обратите внимание, что если нам необходимо обработать ваши личные данные, чтобы управлять Веб-сайтом и / или предоставлять наши Услуги, и вы возражаете или не соглашаетесь с нами обрабатывать ваши персональные данные, Веб-сайт и / или эти Услуги могут быть недоступны для вас.</p>
                <p>У ВАС ЕСТЬ ПРАВО В ЛЮБОЕ ВРЕМЯ ЗАПРОСИТЬ ОСТАНОВИТЬ ОБРАБОТКУ ВАШЕЙ ИНФОРМАЦИИ ДЛЯ ПРЯМЫХ МАРКЕТИНГОВЫХ ЦЕЛЕЙ. Если вы хотите воспользоваться этим правом, вы должны связаться с нами, отправив электронное письмо на адрес taekwondo.com.ua@gmail.com или по ссылке отписки в нижней части наших маркетинговых сообщений.</p>
                <p>&nbsp;</p>
                <p><strong>Жалобы</strong></p>
                <p>Если у вас есть какие-либо опасения по поводу того, как мы собираем или обрабатываем ваши данные, вы имеете право подать жалобу в надзорный орган:</p>
                <p>Украина</p>
                <p>Представитель Уполномоченного лица по вопросам защиты персональных данных</p>
                <p>Секретариата Уполномоченого Верховной Рады Украины по правам человека</p>
                <p>Электронная почта: hotline@ombudsman.gov.ua</p>
                <p><strong>&nbsp;</strong></p>
                <p><strong>Заключительные положения</strong></p>
                <p>Мы приложим разумные усилия для обеспечения вашей безопасности и предотвращения доступа третьих лиц к собранной личной информации. Мы будем постоянно обучать наших сотрудников и давать указания поддерживать вашу информацию в безопасности.</p>
                <p>Эта Политика конфиденциальности создает полное понимание между нами и вами. Если в настоящей Политике конфиденциальности прямо не указано, ни одна из сторон, то есть вы и мы, не должны опираться на любую письменную или устную информацию.</p>
                <p>Отчеты и уведомления в соответствии с настоящим документом считаются прочитанными в течение 1 дня с момента отправки уведомления или отчета.</p>
                <p>Этот документ может быть изменен или дополнен по нашему единственному и абсолютному усмотрению без предупреждения или уведомлений.</p>
                <p>&nbsp;</p>
                <p><strong>Как с нами связаться</strong></p>
                <p>Вопросы, комментарии и запросы в отношении этой Политики конфиденциальности приветствуются и должны быть адресованы на taekwondo.com.ua@gmail.com.</p>
                <p>Наш сотрудник по защите данных отвечает за обеспечение соответствия с GDPR и этой Политикой. Любые вопросы о работе настоящей Политики или любых проблемах, связанных с Политикой, должны быть сначала переданы нашему сотруднику по адресу taekwondo.com.ua@gmail.com.</p>
                <p>Это соглашение о Политике конфиденциальности заключено в электронной форме, которая имеет такую же юридическую силу, как если бы она была подписана чернилами.</p>


              </div>


            </div>

          </div>

        </div>
      </div>





    </div>
  </div>



</template>
<script>
  import Card from 'src/components/Cards/Card.vue'

  export default {
    components: {
      Card
    },
    data () {
      return {
        user: {
          company: '',
          username: '',
          email: '',
          firstName: '',
          lastName: '',
          address: '',
          city: '',
          region: '',
          country: '',
          aboutMe: ``
        },

        userSignup: {
          username:{ value:'', valid:true, error:''},
          email:{ value:'', valid:true, error:''},
          firstName:{ value:'', valid:true, error:''},
          lastName:{ value:'', valid:true, error:''},
          password:{ value:'', valid:true, error:''},
          passwordRepeat:{ value:'', valid:true, error:''},
        },
        userLogin: {
          email:{ value:'', valid:true, error:''},
          password:{ value:'', valid:true, error:''},
        },
        userSignupErrors:[],
        userLoginErrors:[]
      }
    },
    computed: {
      // isLoading() {
      //   return this.$store.getters['tournament/isLoading'];
      // },
      hasErrorSignup() {
        return this.$store.getters['user/hasErrorSignup'];
      },
      errorSignup() {
        return this.$store.getters['user/errorSignup'];
      },
      errorLogin() {
        return this.$store.getters['user/errorLogin'];
      },

      hasErrorLogin() {
        return this.$store.getters['user/hasErrorLogin'];
      },


    },
    watch: {
      // whenever question changes, this function will run
      errorLogin: function (newValue, oldValue) {
        console.log('change errorLogin', newValue);
        // console.log('newValue.messages', newValue.messages);
        if(newValue != undefined && newValue.messages != undefined){
          this.userLoginErrors = newValue.messages;
        }else{
          this.userLoginErrors = '';
        }

        console.log('userLoginErrors', this.userLoginErrors);
      },
      // whenever question changes, this function will run
      errorSignup: function (newValue, oldValue) {
        if(newValue.messages != undefined){
          this.userSignupErrors = newValue.messages;
        }
        console.log(newValue);
        if(newValue.fields != undefined){
          if(newValue.fields.includes('username')){
            this.userSignup.username.valid = false;
          }else{
            this.userSignup.username.valid = true;
          }

          if(newValue.fields.includes('email')){
            this.userSignup.email.valid = false;
          }else{
            this.userSignup.email.valid = true;
          }

          if(newValue.fields.includes('firstName')){
            this.userSignup.firstName.valid = false;
          }else{
            this.userSignup.firstName.valid = true;
          }

          if(newValue.fields.includes('lastname')){
            this.userSignup.lastName.valid = false;
          }else{
            this.userSignup.lastName.valid = true;
          }

          if(newValue.fields.includes('password')){
            this.userSignup.password.valid = false;
          }else{
            this.userSignup.password.valid = true;
          }

          if(newValue.fields.includes('passwordRepeat')){
            this.userSignup.passwordRepeat.valid = false;
            this.userSignup.password.valid = false;
          }else{
            this.userSignup.passwordRepeat.valid = true;
            this.userSignup.password.valid = true;
          }


        }

      }
    },
    methods: {
      updateProfile () {
        alert('Your data: ' + JSON.stringify(this.user))
      },
      toRegistration(){
        this.$router.replace({ path: `/signUp` }) // -> /user/123
      },
      userSignupForm(){
        let params = {username:this.userSignup.username.value, email:this.userSignup.email.value, password:this.userSignup.password.value, passwordRepeat:this.userSignup.passwordRepeat.value, fname:this.userSignup.firstName.value, lname:this.userSignup.lastName.value};
        this.$store.dispatch('user/createUser', params);
        // alert('Your data: ' + JSON.stringify(this.userSignup));
      },
      userLoginForm(){
        let params = { email:this.userLogin.email.value, password:this.userLogin.password.value};
        let objthis =  this;
        this.$store.dispatch('user/loginUser', params).then(function (){

          location.href = '/user/';
          // objthis.$store.dispatch('user/fetchUserCurrent').then(()=>objthis.$router.replace({ path: `/user/` }));




        }) ;// -> /user/123);
        // alert('Your data: ' + JSON.stringify(this.userSignup));
      },

      focusFieldSignup(field){
        if(field =='username'){
          this.userSignup.username.valid = true;
        }

        if(field =='email'){
          this.userSignup.email.valid = true;
        }

        if(field =='firstName'){
          this.userSignup.firstName.valid = true;
        }

        if(field =='lastname'){
          this.userSignup.lastName.valid = true;
        }

        if(field =='password'){
          this.userSignup.password.valid = true;
        }

        if(field =='passwordRepeat'){
          this.userSignup.passwordRepeat.valid = true;
        }

        this.userSignupErrors = '';
      }
    }
  }

</script>


<style scoped>

  /*@import "../assets/home/styles/bootstrap-4.1.2/bootstrap.min.css";*/
  /*@import "../assets/home/plugins/font-awesome-4.7.0/css/font-awesome.min.css";*/
  /*@import "../assets/home/styles/main_styles.css";*/
  /*@import "../assets/home/styles/main_styles.css";*/
  /*@import "../assets/home/styles/contact.css";*/
  /*@import "../assets/home/styles/responsive.css";*/
  /*@import "../assets/home/plugins/OwlCarousel2-2.2.1/animate.css";*/
</style>
