<template>


  <div class="content" style="padding-top: 100px;">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6 offset-md-3">
          <card>
            <h4 slot="header" class="card-title">Реєстрація</h4>
            <form>
              <div class="row">
                <div class="col-md-6">
                  <base-input type="email"
                              label="Email*"
                              :disabled="false"
                              placeholder="user@gmail.com"
                              v-model="user.email">
                  </base-input>
                </div>
                <div class="col-md-6">
                  <base-input type="text"
                              label="Логін*"
                              placeholder="Логін"
                              v-model="user.username">
                  </base-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <base-input type="password"
                              label="Пароль*"
                              placeholder="Пароль"
                              v-model="user.password">
                  </base-input>
                </div>
                <div class="col-md-6">
                  <base-input type="password"
                              label="Повторіть пароль*"
                              placeholder="Пароль"
                              v-model="user.repeatpassword">
                  </base-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <base-input type="text"
                              label="Ім'я"
                              placeholder="Ім'я"
                              v-model="user.firstName">
                  </base-input>
                </div>
                <div class="col-md-6">
                  <base-input type="text"
                              label="Прізвище"
                              placeholder="Прізвище"
                              v-model="user.lastName">
                  </base-input>
                </div>
              </div>

              <div class="row">

                <div class="col-md-4">
                  <base-input type="text"
                              label="Країна"
                              placeholder="Країна"
                              v-model="user.country">
                  </base-input>
                </div>
                <div class="col-md-4">
                  <base-input type="text"
                              label="Область"
                              placeholder="Область"
                              v-model="user.region">
                  </base-input>
                </div>
                <div class="col-md-4">
                  <base-input type="text"
                              label="Місто"
                              placeholder="Місто"
                              v-model="user.city">
                  </base-input>
                </div>



              </div>



              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label>Розкажіть трохи про себе</label>
                    <textarea rows="5" class="form-control border-input"
                              placeholder="Тут можете коротко описати про себе"
                              v-model="user.aboutMe">
              </textarea>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <button type="submit" class="btn btn-info btn-fill float-left" @click.prevent="toLogin">
                  Увійти
                </button>
                <button type="submit" class="btn btn-warning btn-fill float-right" @click.prevent="updateProfile">
                  Реєстрація
                </button>
              </div>
              <div class="clearfix"></div>
            </form>
          </card>
        </div>
        <div class="col-md-4">

        </div>
      </div>
    </div>
  </div>


</template>
<script>
  import Card from 'src/components/Cards/Card.vue'
  import UserAPI from 'src/api/user';

  export default {
    components: {
      Card
    },
    data () {
      return {
        user: {
          username: '',
          repeatpassword: '',
          password: '',
          email: '',
          firstName: '',
          lastName: '',
          city: '',
          region: '',
          country: '',
          aboutMe: ``
        }
      }
    },
    methods: {
      updateProfile () {
        // alert('Your data: ' + JSON.stringify(this.user));

        this.$store.dispatch('user/createUser', this.user);

      },
      toLogin(){
        this.$router.replace({ path: `/login` }) // -> /user/123
      }
    }
  }

</script>
<style>

</style>
