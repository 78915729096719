/*!

 =========================================================
 * Vue Light Bootstrap Dashboard - v2.0.0 (Bootstrap 4)
 =========================================================

 * Product Page: http://www.creative-tim.com/product/light-bootstrap-dashboard
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import VueMeta from 'vue-meta'
import VModal from 'vue-js-modal'

// LightBootstrap plugin
import LightBootstrap from './light-bootstrap-main'

// router setup
import routes from './routes/routes'

import './registerServiceWorker'
import store from './store';
import Notifications from 'vue-notification';
import vSelect from 'vue-select';

import vmodal from 'vue-js-modal'
Vue.use(vmodal);
Vue.use(VueMeta);
Vue.use(VModal)

import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
Vue.use(VueFlatPickr);


// авторизация
import Axios from 'axios'
Vue.prototype.$http = Axios;
const token = localStorage.getItem('jwt')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer '+token;
  Vue.prototype.$http.defaults.headers.common['WWW-Authenticate'] = 'Bearer '+token;
}


// plugin setup
Vue.use(VueRouter);
Vue.use(LightBootstrap);
Vue.use(Notifications);
Vue.component('v-select', vSelect);

// configure router
const router = new VueRouter({
  mode: 'history',
  routes, // short for routes: routes
  linkActiveClass: 'nav-item active',
  scrollBehavior: (to) => {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  // if(to.matched.some(record => record.meta.requiresNotAuth)) {
  //   next({
  //     path: '/user/',
  //     params: { nextUrl: to.fullPath }
  //   })
  // }
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      let user = JSON.parse(localStorage.getItem('user'))
      if(to.matched.some(record => record.meta.is_admin)) {
        if(user.is_admin == 1){
          next()
        }
        else{
          next({ name: 'userboard'})
        }
      }else {
        next()
      }
    }
  } else if(to.matched.some(record => record.meta.guest)) {
    if(localStorage.getItem('jwt') == null){
      next()
    }
    else{
      next({ name: 'userboard'})
    }
  }else {
    next()
  }
})



//
//
// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     // this route requires auth, check if logged in
//     // if not, redirect to login page.
//     if (store.getters["security/isAuthenticated"]) {
//       next();
//     } else {
//       next({
//         path: "/login",
//         query: { redirect: to.fullPath }
//       });
//     }
//   } else {
//     next(); // make sure to always call next()!
//   }

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: h => h(App),
  router,
  store,
})
