<template>
  <div class="home-layout">
    <div class="super_container">

      <!-- Header -->

      <header class="header trans_400">
        <div class="header_content d-flex flex-row align-items-center justify-content-start trans_400">
          <div class="logo">
            <a href="javascript:;"  @click="toHome()"><div style="float: left;"> <img src="/logo.jpg" style="    width: 90px;    border-radius: 15px;    margin-right: 10px;">   </div>
            </a>
            <div style="padding-top: 20px; float: left"><a href="/" >Taekwondo.com.ua</a>
            </div>
<!--            <br>-->
<!--            <br>-->
<!--            <a href="mailto:taekwondo.com.ua@gmail.com" style="font-size: 17px;">taekwondo.com.ua@gmail.com</a>-->
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-10 offset-lg-2">
                <nav class="main_nav">
                  <ul class="d-flex flex-row align-items-center justify-content-start">
                    <!--                    <li class="active"><a href="/">Головна</a></li>-->
                    <!--                    <li><a href="about.html">Про нас</a></li>-->
                    <!--                    <li><a href="services.html">Сервіси</a></li>-->
                    <!--                    <li><a href="blog.html">Термінологія</a></li>-->
                    <!--                    <li><a href="blog.html">Новини</a></li>-->
                    <!--                    <li><a href="contact.html">Контакти</a></li>-->
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          <div class="header_right d-flex flex-row align-items-center justify-content-start">

            <!-- Header Links -->
            <!--            <div class="header_links">-->
            <!--              <ul class="d-flex flex-row align-items-center justify-content-start">-->
            <!--                <li><a href="#">Webmail</a></li>-->
            <!--                <li><a href="#">Chat</a></li>-->
            <!--                <li><a href="#">Login</a></li>-->
            <!--              </ul>-->
            <!--            </div>-->

            <!-- Phone -->
            <a href="javascript:;" @click="toLogin()"  v-if="!auth">
            <div class="phone d-flex flex-row align-items-center justify-content-start" style="cursor:pointer;" >
              <div>Кабінет секретаря</div>
            </div>
            </a>

            <a v-else href="javascript:;" @click="toProfile()" >
            <div class="phone d-flex flex-row align-items-center justify-content-start" style="cursor:pointer;" >
              <i class="fa fa-user" aria-hidden="true"></i>
              <div>Мій профіль</div>
            </div>
            </a>

            <!-- Hamburger -->
            <div class="hamburger"><i class="fa fa-bars" aria-hidden="true"></i></div>
          </div>
        </div>
      </header>



      <!-- Menu -->

      <div class="menu trans_500">
        <div class="menu_content d-flex flex-column align-items-center justify-content-center">
          <div class="menu_nav trans_500">
            <ul class="text-center">
              <li><a href="index.html">Головна</a></li>
              <li><a href="about.html">Про нас</a></li>
              <!--              <li><a href="services.html">Сервіси</a></li>-->
              <!--              <li><a href="blog.html">Новини</a></li>-->
              <!--              <li><a href="contact.html">Контакти</a></li>-->
            </ul>
          </div>
          <!--          <div class="phone menu_phone d-flex flex-row align-items-center justify-content-start">-->
          <!--            <i class="fa fa-phone" aria-hidden="true"></i>-->
          <!--            <div>652-345 3222 11</div>-->
          <!--          </div>-->
        </div>
      </div>


      <public-content>





      </public-content>


      <!-- Footer -->

      <footer class="footer">


        <div class="copyright_bar text-center">

          Copyright &copy;
          All rights reserved  </div>
      </footer>

    </div>
  </div>
</template>




<style scoped lang="scss">
  /*@import "../assets/home/styles/main.scss";*/
/*@import "../assets/home/styles/bootstrap-4.1.2/bootstrap.min.css";*/

  .home-layout{

    @import "../assets/home/plugins/font-awesome-4.7.0/css/font-awesome.min.css";
    /*@import "../assets/home/styles/main_styles.css";*/
    /*@import "../assets/home/styles/main.scss";*/
    /*@import "../assets/home/styles/responsive.css";*/
    /*@import "../assets/home/styles/contact.css";*/
    @import "../assets/home/plugins/OwlCarousel2-2.2.1/animate.css";

  }

</style>


<style lang="scss">

</style>
<script>
  import TopNavbar from './TopNavbar.vue'
  import ContentFooter from './ContentFooter.vue'
  import PublicContent from './Content.vue'
  import MobileMenu from './MobileMenu.vue'
  export default {
    data: function(){
      return {
        auth:false
      };
    },
    components: {
      // TopNavbar,
      // ContentFooter,
      PublicContent,
      // MobileMenu
    },
    computed: {
      userOne() {
        console.log(this.$store.getters['user/userOne']);
        return this.$store.getters['user/userOne'];
      },
    },
    created(){
      let user = JSON.parse(localStorage.getItem('user'));
      let hasJwt = localStorage.getItem('jwt');
      this.auth = hasJwt != null && hasJwt != undefined && hasJwt != '' && hasJwt.length > 0 ? true : false;
      console.log(hasJwt);
    },
    methods: {
      // toggleSidebar () {
      //   if (this.$sidebar.showSidebar) {
      //     this.$sidebar.displaySidebar(false)
      //   }
      // }

      toLogin(){
        console.log('toLogin');
        this.$router.replace({ path: `/login` });
      },
      toProfile(){
        console.log('user');
        // this.$router.replace({ path: `/user` });
        location.href = '/user/profile';
        // this.$router.replace({path: `/user/tournaments/`})
        // this.$router.replace({ path: `/user` });
      },

      toHome(){
        this.$router.replace({ path: `/` });
      }
    }
  }

</script>
