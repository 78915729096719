<template>
  <div class="tournament-content-tab" v-bind:class="{'tournament-content-active-tab':activeTab == 2}">
    <div class="row">
      <div class="col-md-12">
        <card>
          <template slot="header">
            <h4 class="title">Спортсмени ({{Object.keys(tournamentOne.data.item.sportsmans).length}})</h4>
            <div>
              <button class="btn btn-sm btn-primary btn-fill  "  style="margin: 0 5px 0 0;" @click="toogleAddSportsman()">
                <i class="nc-icon nc-simple-add" style="vertical-align: sub;"></i>  Додати
              </button>

              <button class="btn btn-sm btn-warning btn-fill  "  style="margin: 0 5px 0 0;" @click="syncData()">
                <i class="nc-icon nc-atom" style="vertical-align: sub;"></i>  Синхронізація
              </button>
            </div>
          </template>

          <hr>

<!--          Форма добавления спортсмена-->
          <modal name="sportsman-form-modal" :adaptive=true  :minHeight=350 height="auto" :minWidth=400 width="80%" :scrollable=true >
            <form-sportsman :formAddSportsman="formAddSportsman"></form-sportsman>
          </modal>

<!--          Форма добавления спортсмена-->
          <modal name="sportsmansync-form-modal" :adaptive=true  :minHeight=350 height="auto" :minWidth=400 width="80%" :scrollable=true >
            <div class="mr-5">
              Синхронізація даних. Зачекайте...
            </div>
          </modal>


          <div class="row">
            <div class="col-md-3">
              <div class="form-group"><label class="control-label">
                Область
              </label>
                <v-select label="name" :filterable="true" :options="regionsList"
                          v-model="filterSportsmansRegionVal" >
                  <template slot="no-options">Укажіть область
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      <span class="name-city">{{option.count > 0 ? option.count+' | ' : ''}}{{ option.name }}</span>
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      <span class="name-city">{{ option.name }}</span>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group"><label class="control-label">
                Категорія
              </label>
                <v-select label="name" :filterable="true" :options="filterProtocols"
                          v-model="filterSportsmansCategoryVal" >
                  <template slot="no-options">Укажіть категорію
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      <span class="name-city">{{option.count > 0 ? option.count+' | ' : ''}}{{ option.name }}</span>
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      <span class="name-city">{{ option.name }}</span>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group"><label class="control-label">
                Організація
              </label>

                <v-select label="name" :filterable="true" :options="filterOrganization"
                          v-model="filterSportsmansOrganizationVal" >
                  <template slot="no-options">Укажіть категорію
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      <span class="name-city">{{ option.name }}</span>
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      <span class="name-city">{{ option.name }}</span>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group"><label class="control-label">
                Тренер
              </label>

                <v-select label="name" :filterable="true" :options="filterTrainer"
                          v-model="filterSportsmansTrainerVal" >
                  <template slot="no-options">Укажіть категорію
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      <span class="name-city">{{ option.name }}</span>
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      <span class="name-city">{{ option.name }}</span>
                    </div>
                  </template>
                </v-select>
              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-md-12">
              <table class="table table-sm table-hover " v-if="filteredSportsmans.length > 0">
                <thead>
                <slot name="columns">
                  <tr>
                    <th>ID</th>
                    <th>Ім'я</th>
                    <th>Область</th>
                    <th>Рік народження</th>
                    <th>Стать</th>
                    <th>Категорія</th>
                    <th>Організація</th>
                    <th>Кваліфікація</th>
                    <th>Тренер</th>
                    <th>Страховка</th>
                    <!--                        <th>Бере участь</th>-->
                    <th style="text-align: right;"></th>
                  </tr>
                </slot>
                </thead>
                <tbody>
                <tr :class="(item.verification_status == 1 ? 'verified-sportsman': '')" v-for="(item, index) in filteredSportsmans" :key="index">
                  <td>{{item.id}}</td>
                  <td>{{item.name}}<span class="text-danger" v-if="!item.name">не вказано</span></td>
                  <td>{{item.oblast}}<span class="text-danger" v-if="!item.oblast">не вказано</span></td>
                  <td>{{item.bdate}}<span class="text-danger" v-if="!item.bdate">не вказано</span></td>
                  <td><span class="text-danger" v-if="!item.sex">не вказано</span><span class="text-success" v-if="item.sex == 1">чоловік</span><span class="text-primary" v-if="item.sex == 2">жінка</span></td>
                  <td>{{item.category}}<span class="text-danger" v-if="!item.category">не вказано</span></td>
                  <td>{{item.organization}}<span class="text-danger" v-if="!item.organization">не вказано</span></td>
                  <td>{{item.qualification}}<span class="text-danger" v-if="!item.qualification">не вказано</span></td>
                  <td>{{item.coach}}<span class="text-danger" v-if="!item.coach">не вказано</span></td>

                  <td>{{item.insurence}} <span class="text-danger" v-if="!item.insurence"></span></td>

                  <td>
<!--                    {{item.verification_status}}-->


                    <input :v-model="item.verification_status" @click="verificationStatusChanged(item)" class="input-checkbox" type="checkbox" :checked="item.verification_status == 1 ? true:false">
<!--                    <base-checkbox :v-model="item.verification_status"></base-checkbox>-->

                  </td>
                  <!--                      <td><span class="text-danger"><span class="text-danger"  v-if="item.is_not_found == 1">ні</span> <span class="text-primary"  v-else>так <span style="font-size: 12px!important; display: block"><span v-if="!item.protocol_name" class="text-warning"><b>??????????</b></span> <span v-else>{{item.protocol_name}} </span> </span></span></span></td>-->
                  <td style="justify-content: right;">
                    <button style="margin-right: 10px" class="btn btn-sm btn-warning btn-fill    " @click="updateOneSportsman(item)">
                      <i class="nc-icon nc-ruler-pencil" style="vertical-align: sub;"></i>
<!--                      Редагувати-->
                    </button>

                    <button class="btn btn-sm btn-danger btn-fill    " @click="deleteOneSportsman(item.id)">
                      <i class="nc-icon nc-simple-remove" style="vertical-align: sub;"></i>
<!--                      Видалити-->
                    </button>
                  </td>
                </tr>
                </tbody>
              </table>

              <div v-else> Інформація відсутня</div>
              <!--                TODO Додати перевiрку на JS при створенні-редагуванні-->
            </div>
          </div>

        </card>

      </div>
    </div>
  </div>
</template>

<script>
  import FormSportsman from  'src/pages/tournament/_formSportsman.vue';
  export default {
    name: "tab-sportsmans",
    components:{
      'form-sportsman': FormSportsman
    },
    data: function(){
      return {
        formAddSportsman:{
          id:'',
          tournament:'',
          name:'',
          bdate:'',
          organization:'',
          qualification:'',
          sex:'',
          weight:'',
          coach:'',
          trainer2:'',
          category_id:'',
          oblast:'',
        },
        filterSportsmansEnabled:false,
        filterSportsmansOrganizationEnabled:false,
        filterSportsmansOrganizationVal:'',
        filterSportsmansTrainerVal:'',

        filterSportsmansCategoryEnabled:false,
        filterSportsmansCategoryVal:'',
        filterSportsmansRegionVal:'',
      }
    },
    computed: {
      activeTab() {
        return this.$store.getters['settings/activeTournamentTab'];
      },
      tournamentOne() {
        return this.$store.getters['tournament/tournamentOne'];
      },
      regionsList() {
        let regions = {};


        for (const sportsman of this.tournamentOne.data.item.sportsmans){

          if(regions[sportsman.oblast] != undefined){
            regions[sportsman.oblast]['count']++;
          }else{
            regions[sportsman.oblast] = {
              name: sportsman.oblast,
              count:  1
            }
          }
        }

        let regionsArr = [];
        let keys = Object.keys(regions);
        console.log('keys', keys)

        for (let i  in keys) {
          regionsArr.push(regions[keys[i]])
        }


        return  regionsArr.sort(function (a, b){
          if ( a.count > b.count ){
            return -1;
          }
          if ( a.count < b.count ){
            return 1;
          }
          return 0;
        });
      },
      filterProtocols: function() {
        let filters = [];
        let checkedFiters = this.tournamentOne.data.item.protocols;
        if(checkedFiters != undefined && checkedFiters != null && checkedFiters != ''){
          checkedFiters.forEach(element => {
            filters.push({name:element.fullname, val:element.id, count:element.count});
          });
        }

        return filters;
      },

      filteredSportsmans: function() {
        console.log('filteredSportsmans');
        let filters = [];
        if(this.tournamentOne.data.item.sportsmans){

          let sportsmansList = this.tournamentOne.data.item.sportsmans;

          let organization = this.filterSportsmansOrganizationVal;
          let category = this.filterSportsmansCategoryVal;
          let region = this.filterSportsmansRegionVal;
          let trainer = this.filterSportsmansTrainerVal;

          if (region != '' && region != undefined) {
            sportsmansList = sportsmansList.filter(function (el) {
              return el.oblast == region.name;
            });
          }


          if (organization != '' && organization != undefined) {
            sportsmansList = sportsmansList.filter(function (el) {
              return el.organization == organization.name;
            });
          }


          if (category != '' && category != undefined) {
            sportsmansList = sportsmansList.filter(function (el) {
              return  el.category_id == category.val;;
            });
          }


          if (trainer != '' && trainer != undefined) {
            sportsmansList = sportsmansList.filter(function (el) {
              return  el.coach == trainer.val || el.trainer2 == trainer.val || el.trainer3 == trainer.val;
            });
          }

          return sportsmansList;

          // если включен фильтр по организациям
          if(this.filterSportsmansOrganizationVal != '' && this.filterSportsmansOrganizationVal != undefined && this.filterSportsmansCategoryVal != '' && this.filterSportsmansCategoryVal != undefined ){

            let filtersTmp = [];
            let checkedFiters = this.tournamentOne.data.item.sportsmans.filter(function(el) {
              return el.organization == organization.name;
            });
            checkedFiters.forEach(element => {
              filtersTmp.push(element);
            });

            checkedFiters = filtersTmp.filter(function(el) {
              return el.category_id == category.val;
            });
            checkedFiters.forEach(element => {
              filters.push(element);
            });

          }else if(this.filterSportsmansOrganizationVal != '' && this.filterSportsmansOrganizationVal != undefined ){

            let checkedFiters = this.tournamentOne.data.item.sportsmans.filter(function(el) {
              return el.organization == organization.name;
            });
            checkedFiters.forEach(element => {
              filters.push(element);
            });

          }else if(this.filterSportsmansCategoryVal != '' && this.filterSportsmansCategoryVal != undefined){

            let checkedFiters = this.tournamentOne.data.item.sportsmans.filter(function(el) {
              return el.category_id == category.val;
            });

            checkedFiters.forEach(element => {
              filters.push(element);
            });


            // return this.tournamentOne.data.item.sportsmans;
          }else {
            return this.tournamentOne.data.item.sportsmans;
          }

          // если включен фильтр по категориям


        }
        // filters = this.tournamentOne.data.item.sportsmans;

        return filters;
      },



      filterOrganization: function() {
        let filters = [];
        let checkedFiters = this.tournamentOne.data.item.organizations;

        console.log(checkedFiters);

        if(checkedFiters != undefined && checkedFiters != null && checkedFiters != ''){
          checkedFiters.forEach(element => {
            filters.push({name:element.organization, val:element.oblast});
          });
        }

        return filters;
      },

       filterTrainer: function() {
        let filters = [];

         const isShallowEqualWith = (a) => (b) => {
           const keysA = Object.keys(a);
           const keysB = Object.keys(b);
           return keysA.length === keysB.length && keysA.every(k => a[k] === b[k]);
         }

         for (const sportsman of this.tournamentOne.data.item.sportsmans) {

           console.log('sportsman', sportsman);
           if (sportsman.coach) {
             let temp = {name: sportsman.coach, val: sportsman.coach}
             if(!filters.find(isShallowEqualWith(temp))) {
               filters.push(temp);
             }
             // filters.push(temp);
           }
           if (sportsman.trainer2) {
             let temp = {name: sportsman.trainer2, val: sportsman.trainer2}
             if(!filters.find(isShallowEqualWith(temp))) {
               filters.push(temp);
             }
             // filters.push({name: sportsman.trainer2, val: sportsman.trainer2});
           }
           if (sportsman.trainer3) {
             let temp = {name: sportsman.trainer3, val: sportsman.trainer3}
             // filters.push({name: sportsman.trainer3, val: sportsman.trainer3});
             if(!filters.find(isShallowEqualWith(temp))) {
               filters.push(temp);
             }
           }

         }

        return filters;
      },

      actionTournamentAddSportsman() {
        return this.$store.getters['settings/actionTournamentAddSportsman'];
      },

    },
    methods:{



      toogleAddSportsman(){
        // добавление участника
        // if(this.actionTournamentAddSportsman == true){
        //   this.$store.dispatch('settings/setActionTournamentAddSportsman',false);
        // }else{
        //   this.$store.dispatch('settings/setActionTournamentAddSportsman',true);
        // }

        this.$store.dispatch('settings/setActionTournamentAddSportsman',true);
        this.$modal.show('sportsman-form-modal');

      },

      syncData(){
        // добавление участника
        // if(this.actionTournamentAddSportsman == true){
        //   this.$store.dispatch('settings/setActionTournamentAddSportsman',false);
        // }else{
        //   this.$store.dispatch('settings/setActionTournamentAddSportsman',true);
        // }

        this.$modal.show('sportsmansync-form-modal');
        // this.$store.dispatch('settings/setActionTournamentAddSportsman',true);
        let params = {'id': this.tournamentOne.data.item.id};
        this.$store.dispatch('tournament/syncTournamentSportsmans', params).then(() => this.fetchTournamentOneFull());
      },

      fetchTournamentOneFull(){
        console.log('fetchTournamentOneFull!!');
        console.log('tournamentOnetournamentOne',this.tournamentOne.data);
        this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.tournamentOne.data.item.id});
        this.$modal.hide('sportsmansync-form-modal');
      },


      updateOneSportsman(sportsman){
        console.log(sportsman);

        // this.formAddSportsman.first_name = sportsman.first_name;
        // this.formAddSportsman.last_name = sportsman.last_name;
        this.formAddSportsman.name = sportsman.name;
        this.formAddSportsman.tournament = sportsman.tournament;
        this.formAddSportsman.organization = sportsman.organization;
        this.formAddSportsman.qualification = sportsman.qualification;
        this.formAddSportsman.sex = sportsman.sex;

        if(sportsman.sex == 0){
          this.formAddSportsman.sex = {id:0, name:'-'};
        }else if(sportsman.sex == '1'){
          this.formAddSportsman.sex =  {id:1, name:'чоловік'};
        }else if(sportsman.sex == '2'){
          this.formAddSportsman.sex = {id:2, name:'жінка'};
        }

        this.formAddSportsman.weight = sportsman.weight;
        this.formAddSportsman.coach = sportsman.coach;
        this.formAddSportsman.trainer2 = sportsman.trainer2;
        this.formAddSportsman.category_id = sportsman.category_id;
        this.formAddSportsman.oblast = sportsman.oblast;
        this.formAddSportsman.bdate = sportsman.bdate;
        this.formAddSportsman.id = sportsman.id;


        for (let i = 0; i <  this.tournamentOne.data.item.protocols.length ; i++) {
          if(this.tournamentOne.data.item.protocols[i].id == sportsman.category_id){
            this.formAddSportsman.category_id = this.tournamentOne.data.item.protocols[i];
            break;
          }

        }

        console.log(this.formAddSportsman);




        this.$store.dispatch('settings/setActionTournamentAddSportsman',true);
        this.$modal.show('sportsman-form-modal');
        // form

      },

      // удаление одного спортсмена  с турнира
      deleteOneSportsman(id){
        if (window.confirm(" Після видалення дані неможливо буде відновити. Ви впевнені, що хочете видали спортсмена з турніру?")) {
          let params = {'id': id};
          this.$store.dispatch('tournament/deleteOneSportsman', params)
            .then(() => this.deleteFromListSportsman(id));
        }
      },

      verificationStatusChanged(item){
        if(item.verification_status == 1) item.verification_status = 0;
        if(item.verification_status == 0) item.verification_status = 1;


        let params = {'id': item.id, 'verification_status':item.verification_status};


        this.$store.dispatch('tournament/verifyOneSportsman', params)
          .then(() => this.fetchTournamentOneFull(item.tournament_id)).then(()=>this.sportsmanVerificationStatusChanged());
      },

      sportsmanVerificationStatusChanged(){
        this.$notify(
          {
            text: 'Статус перевірк оновлено',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            duration: 10000,
            speed: 1000,
            type: 'success'
          })
      },

      deleteFromListSportsman(id){
        let length = this.tournamentOne.data.item.sportsmans.length;
        for (let i = 0; i < length; i++) {
          console.log(this.tournamentOne.data.item.sportsmans[i].id);
          if (this.tournamentOne.data.item.sportsmans[i].id === id){
            this.tournamentOne.data.item.sportsmans.splice(i, 1);
            break;
          }
        }
      },

    }
  }
</script>

<style scoped>

.input-checkbox{
  width: 20px;
  height: 20px;
}

.verified-sportsman{
  background: rgba(58, 171, 58, 0.62);
}

</style>
