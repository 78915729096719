<template>
  <div class="content_">

    <div class="container-fluid_ poomsae-queues"  >
      <div class="card" style="padding: 10px 10px 10px 10px">
      <div class="row">
        <div class="col-md-12">
          <button class="btn btn-fill btn-sm">протокол жеребкування PDF</button> |
          <button class="btn btn-fill btn-sm">протокол жеребкування WORD</button>
        </div>
        <div class="col-md-4">
          <h4 class="title">Попереднє коло </h4>
          <div class="previous-queue poomsae-queue" id="preround">
<!--

            <div style="cursor:pointer; user-select: none" :data-sp-id="item.sp_id" :data-sp-name="item.name" :data-sp-oblast="item.oblast" :data-sp-organization="item.organization" :data-t-id="item.sp_id" class="poomsae-queue-item sortable-category-drag-item" v-for="(item, index) in categoryOne.data.roundsData" :key="'preround_'+index" v-if="item.round == 2" >
              <div>
                {{item.name}}<span class="text-danger" v-if="!item.name">не вказано</span>
              </div>
              <div class="dop-info">
                <button   class="btn btn-sm btn-warning btn-outline" style="padding: 2px;"> <span class="text-dark">{{item.oblast}}</span></button>
                <button   class="btn btn-sm btn-dark btn-outline" style="padding: 2px;"> <span class="text-dark">{{item.organization}}</span></button>
              </div>

            </div>-->
          </div>
        </div>


        <div class="col-md-4">
          <h4 class="title">Півфінал </h4>
         <!-- <div class="previous-queue poomsae-queue" id="semifinal">
            <div style="cursor:pointer; user-select: none" :data-sp-id="item.sp_id" :data-sp-name="item.name" :data-sp-oblast="item.oblast" :data-sp-organization="item.organization" :data-t-id="item.sp_id" class="poomsae-queue-item sortable-category-drag-item" v-for="(item, index) in categoryOne.data.roundsData" :key="'preround_'+index" v-if="item.round == 1" >
              <div>
                {{item.name}}<span class="text-danger" v-if="!item.name">не вказано</span>
              </div>
              <div class="dop-info">
                <button   class="btn btn-sm btn-warning btn-outline" style="padding: 2px;"> <span class="text-dark">{{item.oblast}}</span></button>
                <button   class="btn btn-sm btn-dark btn-outline" style="padding: 2px;"> <span class="text-dark">{{item.organization}}</span></button>
              </div>

            </div>

          </div>-->
        </div>


        <div class="col-md-4">
          <h4 class="title">Фінал </h4>
          <div class="previous-queue poomsae-queue" id="final">

           <!-- <div style="cursor:pointer; user-select: none" :data-sp-id="item.sp_id" :data-sp-name="item.name" :data-sp-oblast="item.oblast" :data-sp-organization="item.organization" :data-t-id="item.sp_id" class="poomsae-queue-item sortable-category-drag-item" v-for="(item, index) in categoryOne.data.roundsData" :key="'preround_'+index" v-if="item.round == 0" >
              <div>
                {{item.name}}<span class="text-danger" v-if="!item.name">не вказано</span>
              </div>
              <div class="dop-info">
                <button   class="btn btn-sm btn-warning btn-outline" style="padding: 2px;"> <span class="text-dark">{{item.oblast}}</span></button>
                <button   class="btn btn-sm btn-dark btn-outline" style="padding: 2px;"> <span class="text-dark">{{item.organization}}</span></button>
              </div>

            </div>-->



           <!-- <div style="cursor:pointer; user-select: none" :data-category-id="item.id" class="category-item sortable-category-drag-item" v-for="(item, index) in filteredCategories" :key="'day1_'+index" v-if="item.day == 1">
              <div>
                {{item.fullname}}<span class="text-danger" v-if="!item.fullname">не вказано</span>
              </div>
              <div class="dop-info">
                <button v-if="item.type == 1" class="btn btn-sm btn-danger btn-fill" style="padding: 2px;">керугі</button><button v-if="item.type == 2" class="btn btn-sm btn-fill" style="background: #9368e9; padding: 2px;">пумсе</button>
                <button v-if="item.show_type == 1" class="btn btn-sm btn-warning btn-fill" style="padding: 2px;">сітка</button><button v-if="item.show_type == 2" class="btn btn-sm  btn-fill" style="padding: 2px;"  >список</button>
                <button  class="btn btn-sm btn-dark btn-fill" style="padding: 2px;"> {{item.type == 2 && item.poomsae_type == 1 ? 'традиційне':''}}{{item.type == 2 && item.poomsae_type == 2 ? 'вільне':''}}</button>
                <button   class="btn btn-sm btn-dark btn-outline" style="padding: 2px;"> {{item.count}}</button>
              </div>

            </div>-->


          </div>
        </div>


      </div>
      </div>
    </div>



  </div>
</template>

<script>
  import html2canvas from 'html2canvas';
  import {Sortable, Plugins, DragStartEvent, DragStopEvent} from '@shopify/draggable';
  import bracketWinners from './bracket-winners.vue';
  export default {
    name: 'list-constructor',
    components: {
      Sortable, Plugins, DragStartEvent, DragStopEvent, 'bracket-winners':bracketWinners
    },
    props:{
      selectedDrawCategory:Object
    },
    data() {
      return {
        swappable: null,

        results:{
          protocol_type:'list',
          tournament_id:'',
          protocolid_id:'',
          start_round:'',
          plases:{
            place1:'',
            place2:'',
          },
          rounds:[
            {
              type:'preround',
              sps:[
                {
                  sp_id: '',
                  name: '',
                  oblast: '',
                  organization: '',
                  teh1: '',
                  pres1: '',
                  total1: '',
                  teh2: '',
                  pres2: '',
                  total2: '',
                  totalAll: ''
                },
              ],
            },
            {
              type:'semifinal',
              sps:[
                {
                  sp_id: '',
                  teh1: '',
                  pres1: '',
                  total1: '',
                  teh2: '',
                  pres2: '',
                  total2: '',
                  totalAll: ''
                },
              ],
            },
            {
              type:'final',
              sps:[
                {
                  sp_id: '',
                  teh1: '',
                  pres1: '',
                  total1: '',
                  teh2: '',
                  pres2: '',
                  total2: '',
                  totalAll: ''
                },
              ],
            },
          ],

        },

        sortableCategory:null,
        colorSheme: true,
        showBracket32:false,
        showBracket16:false,
        showBracket8:false,
        showBracket4:false,
        showBracket2:true,
        sortableSheme: false,
        sportsmans:[],
        bracketFigths:{},
        bracketEl:null,
        selectedFight:{f:'', number:'', decision:'', winSpId:'', winSpName:'', red: {spId: '', spName: '', points: '', spOrg: ''},blue: {spId: '', spName: '', points: '', spOrg: ''}},
      }
    },

    watch: {
      selectedDrawCategory: function(newVal) {

        this.selectedDrawCategory = newVal;

        this.init();
        this.getCategoryData();



        // this.resortBracketInit();
      }
    },

    mounted(){
      this.init();

      if( this.results.tournament_id != '' &&  this.results.tournament_id != undefined){
        if(this.results.start_round == 'preround' || this.results.start_round == 'semifinal' || this.results.start_round == 'final'){

        }else{

          // this.resortCategoryDays();
        }
      }

    },

    computed:{
      categoryOne: function () {
        return this.$store.getters['tournament/categoryOne'];
      }
    },



    methods:{
      getCategoryData(){
        let saveData = {
          tournament:this.results.tournament_id,
          category:this.selectedDrawCategory.id,
        };
        this.$store.dispatch('tournament/fetchCategoryOne', saveData);
      },

      init(){
        if(this.selectedDrawCategory != undefined && this.selectedDrawCategory.id != undefined) {
          // раскидываем спорстменов по кругам
          this.sportsmans = this.selectedDrawCategory.sportsmans;
          this.results.protocolid_id = this.selectedDrawCategory.id;
          this.results.tournament_id = this.selectedDrawCategory.tournament_id;
          this.results.start_round = this.selectedDrawCategory.start_round;


          console.log('this.selectedDrawCategory', this.selectedDrawCategory);

          let countSp = Object.keys(this.sportsmans).length;
          let i = 0;

          let saveData = {
            tournament:this.results.tournament_id,
            category:this.results.protocolid_id,
          };

          // если это первая жеребьевка и не задано start_round - закидваем в предыдущий круг и сохраняем сразу
          if(this.results.start_round == 'preround' || this.results.start_round == 'semifinal' || this.results.start_round == 'final'){
            // закидываем спорсменов так как они уже раскинутые

            // this.$store.dispatch('tournament/fetchCategoryListSportsmans', saveData);
          }
          else {
            this.$store.dispatch('tournament/createCategoryListFirst', saveData);
            // this.results.rounds[0].sps = [];
            // this.sportsmans.forEach(element => {
            //
            //   let obj = {
            //     sp_id: element.id,
            //     name: element.name,
            //     oblast: element.oblast,
            //     organization: element.organization,
            //     teh1: '',
            //     pres1: '',
            //     total1: '',
            //     teh2: '',
            //     pres2: '',
            //     total2: '',
            //     totalAll: ''
            //   };
            //
            //   this.results.rounds[0].sps.push(obj);
            // });


            console.log(this.results.rounds[0]);
            // this.results.rounds[0].sps

            // this.resortCategoryDays();
          }


        }

        this.setSortableCategoryDay();
      },
      setSortableCategoryDay(){
        // console.log('setSortableCategoryDay');

        let containersDays = document.querySelectorAll('.poomsae-queue');
        // console.log('poomsae-queuepoomsae-queuepoomsae-queuepoomsae-queue',containersDays);
        if(containersDays.length > 0){
          //  инициализация разбивки по дням категорий

          if(this.sortableCategory !== null){
            // debugger;
            this.sortableCategory.destroy();
          }

          this.sortableCategory = new Sortable(containersDays, {
            draggable: '.sortable-category-drag-item',
            mirror: {
              constrainDimensions: true,
            },
            delay: 0,
            plugins: [Plugins.ResizeMirror],
            dragClass: "sortable-category-drag-item",
          });
          this.sortableCategory.on('drag:stop', () => this.resortCategoryDays());
        }else{
          this.sortableCategory = null;
        }
      },


      resortCategoryDays(){
        console.log('resortCategoryDays');
        let preround = document.getElementById('preround');
        let semifinal = document.getElementById('semifinal');
        let final = document.getElementById('final');

        let itemsPreround = preround.getElementsByClassName('poomsae-queue-item');
        let itemsSemifinal = semifinal.getElementsByClassName('poomsae-queue-item');
        let itemsFinal = final.getElementsByClassName('poomsae-queue-item');


        let itemsPreroundIds = [];
        let itemsSemifinalIds = [];
        let itemsFinalIds = [];
        let itemsAllIds = [];

        let i = 0;
        for (i = 0; i<itemsPreround.length; i++){
          if(itemsPreround[i].classList.contains('draggable-mirror')){

          }else if(itemsPreround[i].classList.contains('draggable-mirror')){

          }else if(itemsPreround[i].classList.contains('draggable--original')){

          }else{

            console.log(itemsPreround[i]);
            let spID = itemsPreround[i].getAttribute('data-sp-id');
            let name = itemsPreround[i].getAttribute('data-sp-name');
            let organization = itemsPreround[i].getAttribute('data-sp-organization');
            let oblast = itemsPreround[i].getAttribute('data-sp-oblast');
            itemsPreroundIds.push({id:spID, position:i, name:name, organization: organization, oblast:oblast });
            itemsAllIds.push({id:spID, position:i, name:name, organization: organization, oblast:oblast });
          }

        }

        i = 0;
        for (i = 0; i<itemsSemifinal.length; i++){
          if(itemsSemifinal[i].classList.contains('draggable-mirror')){

          }else if(itemsSemifinal[i].classList.contains('draggable-mirror')){

          }else if(itemsSemifinal[i].classList.contains('draggable--original')){

          }else{

            let spID = itemsPreround[i].getAttribute('data-sp-id');
            let name = itemsPreround[i].getAttribute('data-sp-name');
            let organization = itemsPreround[i].getAttribute('data-sp-organization');
            let oblast = itemsPreround[i].getAttribute('data-sp-oblast');
            itemsPreroundIds.push({id:spID, position:i, name:name, organization: organization, oblast:oblast });
            itemsAllIds.push({id:spID, position:i, name:name, organization: organization, oblast:oblast });
          }

        }


        i = 0;
        for (i = 0; i<itemsFinal.length; i++){
          if(itemsFinal[i].classList.contains('draggable-mirror')){

          }else if(itemsFinal[i].classList.contains('draggable-mirror')){

          }else if(itemsFinal[i].classList.contains('draggable--original')){

          }else{

            let spID = itemsPreround[i].getAttribute('data-sp-id');
            let name = itemsPreround[i].getAttribute('data-sp-name');
            let organization = itemsPreround[i].getAttribute('data-sp-organization');
            let oblast = itemsPreround[i].getAttribute('data-sp-oblast');
            itemsPreroundIds.push({id:spID, position:i, name:name, organization: organization, oblast:oblast });
            itemsAllIds.push({id:spID, position:i, name:name, organization: organization, oblast:oblast });
          }
        }

        let saveData = {
          tournament:this.results.tournament_id,
          category:this.results.protocolid_id,
          itemsPreroundIds:itemsPreroundIds,
          itemsSemifinalIds:itemsSemifinalIds,
          itemsFinalIds:itemsFinalIds,
          itemsAllIds:itemsAllIds,
        };

        console.log('saveData', saveData);


        this.$store.dispatch('tournament/sortableCategoryListSave', saveData)
        // .then(() => this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.id}))
          .catch(error => {
            alert('Помилка сервера');
          });

        console.log('saveData', saveData);



        console.log(itemsPreroundIds);
        console.log(itemsSemifinalIds);
        console.log(itemsFinalIds);

        // send data to
      },

      saveData(){
        // send data to

        console.log('saveData');
      },

    }
  }
</script>

<style lang="scss">

</style>
