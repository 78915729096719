import DashboardLayout from '../layout/DashboardLayout.vue'
import JudgeLayout from '../layout/JudgeLayout.vue'
import PublicLayout from '../layout/PublicLayout.vue'
// GeneralViews
import NotFound from '../pages/NotFoundPage.vue'

// Admin pages
import Overview from 'src/pages/Overview.vue'
import UserProfile from 'src/pages/UserProfile.vue'
import TableList from 'src/pages/TableList.vue'
import Typography from 'src/pages/Typography.vue'
import Icons from 'src/pages/Icons.vue'
import Notifications from 'src/pages/Notifications.vue'
import Upgrade from 'src/pages/Upgrade.vue'
import Tournaments from 'src/pages/tournament/Tournaments.vue'
import TournamentForm from 'src/pages/tournament/TournamentForm.vue'
import TournamentOverview from 'src/pages/tournament/TournamentOverview.vue'
import Login from 'src/pages/Login.vue'
import SignUp from 'src/pages/SignUp.vue'

import Disclaimer from 'src/pages/Disclaimer.vue'
import PrivacyPolicy from 'src/pages/PrivacyPolicy.vue'
import SubscriptionPolicy from 'src/pages/SubscriptionPolicy.vue'
import PolicyCookies from 'src/pages/PolicyCookies.vue'

import HomePage from 'src/pages/Home.vue'
// import HapkidoJudgeCalc from 'src/pages/hapkido/HapkidoJudgeCalc.vue'
import DocumentTemplates from 'src/pages/user/DocumentTemplates.vue'


import UserRoleProfile from 'src/pages/user/UserProfile.vue'
import UserTeam from 'src/pages/user/UserTeam.vue'
import ParticipationInTournament from 'src/pages/user/ParticipationInTournament.vue'
import UserBracketsContructor from 'src/pages/tools/UserBracketsContructor.vue'

const routes = [

  {
    path: '/',
    component: PublicLayout,
    // redirect: '/home',
    children: [
      {
        path: '',
        name: 'HomePage',
        component: HomePage,
        props: true,
        meta: {title: 'Taekwondo.com.ua'}

      },
      {
        path: 'login',
        name: 'Login',
        component: Login,
        props: true,
        meta: {title: 'Taekwondo.com.ua'}

      },
      {
        path: 'disclaimer',
        name: 'Disclaimer',
        component: Disclaimer,
        props: true,
        meta: {title: 'Taekwondo.com.ua'}

      },
      {
        path: 'privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        props: true,
        meta: {title: 'Taekwondo.com.ua'}

      },
      {
        path: 'subscription-policy',
        name: 'SubscriptionPolicy',
        component: SubscriptionPolicy,
        props: true,
        meta: {title: 'Taekwondo.com.ua'}

      },
      {
        path: 'policy-cookies',
        name: 'PolicyCookies',
        component: PolicyCookies,
        props: true,
        meta: {title: 'Taekwondo.com.ua'}

      },


      {
        path: 'overview',
        name: 'Overview',
        component: Overview,
        props: true,
        meta: {
          requiresAuth: true,
          title: 'Taekwondo.com.ua'}

      },
      {
        path: 'user-profile',
        name: 'UserProfile',
        component: UserProfile,
        props: true,
        meta: {title: 'Taekwondo.com.ua'}

      },
      {
        path: 'tableList',
        name: 'tableList',
        component: TableList,
        props: true,
        meta: {title: 'Taekwondo.com.ua'}

      },
      {
        path: 'typography',
        name: 'typography',
        component: Typography,
        props: true,
        meta: {title: 'Taekwondo.com.ua'}

      },
      {
        path: 'icons',
        name: 'icons',
        component: Icons,
        props: true,
        meta: {title: 'Taekwondo.com.ua'}

      },
      {
        path: 'notifications',
        name: 'notifications',
        component: Notifications,
        props: true,
        meta: {title: 'Taekwondo.com.ua'}

      },
      {
        path: 'upgrade',
        name: 'Upgrade',
        component: Upgrade,
        props: true,
        meta: {title: 'Taekwondo.com.ua'}

      }
    ]
  },


  {
    path: '/signUp',
    component: SignUp,
    meta: { title: 'Taekwondo.com.ua' }
  },


  {
    path: '/user',
    component: DashboardLayout,
    redirect: '/user/profile',
    children: [
      {
        path: 'profile',
        name: 'UserRoleProfile',
        component: UserRoleProfile,
        meta: {
          requiresAuth: true,
          is_user : false,
          title: 'Taekwondo.com.ua'
        },

      },

      {
        path: 'team',
        name: 'UserTeam',
        component: UserTeam,
        meta: {
          requiresAuth: true,
          is_user : false,
          title: 'Taekwondo.com.ua'
        },
      },

      {
        path: 'participation',
        name: 'ParticipationInTournament',
        component: ParticipationInTournament,
        meta: {
          requiresAuth: true,
          is_user : false,
          title: 'Taekwondo.com.ua'
        },
      },

      {
        path: 'document-templates',
        name: 'DocumentTemplates',
        component: DocumentTemplates,
        meta: {
          requiresAuth: true,
          is_user : false,
          title: 'Taekwondo.com.ua'
        },
        children: [
          // {
          //   path: ' update/:id',
          //   name: 'TournamentFormUpdate',
          //   component: TournamentForm
          // }
        ]
      },



      {
        path: 'brackets',
        name: 'UserBracketsContructor',
        component: UserBracketsContructor,
        meta: {
          requiresAuth: true,
          is_user : false,
          title: 'Taekwondo.com.ua'
        },
      },

      {
        path: 'tournaments',
        name: 'Tournaments',
        component: Tournaments,
        meta: {
          requiresAuth: true,
          is_user : false,
          title: 'Taekwondo.com.ua'
        },
        children: [
          {
            path: ' update/:id',
            name: 'TournamentFormUpdate',
            component: TournamentForm,
            meta: {
              requiresAuth: true,
              is_user : false
            },
          }
        ]
      },
      {
        path: 'tournaments/create',
        name: 'TournamentFormCreate',
        component: TournamentForm,
        meta: {
          requiresAuth: true,
          is_user : false
        },
      },

      {
        path: 'tournaments/update/:id',
        name: 'TournamentFormUpdate',
        component: TournamentForm,
        props: true,
        meta: {
          requiresAuth: true,
          is_user : false
        },
      },

      {
        path: 'tournaments/overview/:id',
        name: 'TournamentOverview',
        component: TournamentOverview,
        props: true,
        meta: {
          requiresAuth: true,
          is_user : false
        },
      },

      {
        path: 'tournaments/overview/:id/bracket/:category',
        name: 'TournamentOverview',
        component: TournamentOverview,
        props: true,
        meta: {
          requiresAuth: true,
          is_user : false,
          title: 'Taekwondo.com.ua'
        },
      },

    ]
  },


  {
    path: '/error-404',
    component: NotFound,


  },
  { path: '*', component: NotFound, redirect: '/error-404', }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes
