<template>
  <div class="tournament-content-tab" v-bind:class="{'tournament-content-active-tab':activeTab == 11}">

    <modal name="category-section-form-modal" :adaptive=true :minHeight=350 height="auto" :minWidth=400 width="80%"
           :scrollable=true>
      <form-category-section :formAddCategory="formCategoryData"></form-category-section>
    </modal>


    <modal name="section-form-modal" :adaptive=true :minHeight=350 height="auto" :minWidth=400 width="80%"
           :scrollable=true>
      <form-section :formAddCategory="formSectionData"></form-section>
    </modal>


    <div class=" category-days">
      <div class="row">
        <div class="col-md-12">
          <card>

            <div class="row">
              <div class="col-md-2 col-sm-12">
                <h4 class="title">Всі категорії ({{ filteredCategories.length }})</h4>
                <div class="day-category day-0" id="day0">
                  <div style=" user-select: none" :data-category-id="item.id" class="category-item "
                       v-for="(item, index) in filteredCategories" :key="index" @click="updateOneCategory(item)">
                    <div>
                      {{ item.fullname }} ({{ item.count_sportsmans }}) <span class="text-danger" v-if="!item.fullname">не вказано</span>
                    </div>
                    <div class="dop-info">
                      <button v-if="item.type == 1" class="btn btn-sm btn-danger btn-fill" style="padding: 2px;">
                        керугі
                      </button>
                      <button v-if="item.type == 2" class="btn btn-sm btn-fill"
                              style="background: #9368e9; padding: 2px;">пумсе
                      </button>
                      <button v-if="item.show_type == 1" class="btn btn-sm btn-warning btn-fill" style="padding: 2px;">
                        сітка
                      </button>
                      <button v-if="item.show_type == 2" class="btn btn-sm  btn-fill" style="padding: 2px;">список
                      </button>
                      <button v-if="item.type == 2 && ( item.poomsae_type == 1 || item.poomsae_type == 2)"  class="btn btn-sm btn-dark btn-fill" style="padding: 2px;">
                        {{ item.type == 2 && item.poomsae_type == 1 ? 'традиційне' : '' }}{{ item.type == 2 && item.poomsae_type == 2 ? 'вільне' : '' }}


                      </button>

                      <span class="text-secondary" v-text="getSectionNameForCategory(item)"></span>
<!--                      <button class="btn btn-sm btn-dark btn-outline" style="padding: 2px;"> {{ item.count }}</button>-->
                    </div>

                  </div>

                </div>
              </div>

              <!-- Sections-->
              <div class="col-md-10">
                <div class="col-md-12"><h4 class="title">Відділення <button @click="addNewSection"
                                                          class="btn btn-sm btn-primary btn-fill"><i
                  class="nc-icon nc-simple-add" style="vertical-align: sub;"></i> Додати </button></h4></div>

                <div class="row">
                  <div class="col-md-4" style="margin-bottom: 25px" v-for="(section, index) in  tournamentOne.data.item.sections"  :key="'isection_'+index">


                    <div class="day-category day-1" >
                      <div class="section-card"  @click="updateSection(section)"> <span class="start-number">{{section.start_number}}</span> {{section.name}}

                        |
                        <span>Спортсмени: {{ section.count_sportsmans }}</span> |
                        <span>Поєдинки: {{ section.count_fights }}</span> |
                        <span>Макс. категорія: {{ section.max_category }}</span>
                      </div>



                      <div style="cursor:pointer; user-select: none" :data-category-id="item.id"
                           class="category-item sortable-category-drag-item" v-for="(item, index) in filteredCategories"
                           :key="section.id +'_dd1_'+index" v-if="item.sectionid == section.id">
                        <div>
                          {{ item.fullname }}<span class="text-danger" v-if="!item.fullname">не вказано</span>
                        </div>
                        <div class="dop-info">
                          <button v-if="item.type == 1" class="btn btn-sm btn-danger btn-fill" style="padding: 2px;">
                            керугі
                          </button>
                          <button v-if="item.type == 2" class="btn btn-sm btn-fill"
                                  style="background: #9368e9; padding: 2px;">пумсе
                          </button>
                          <button v-if="item.show_type == 1" class="btn btn-sm btn-warning btn-fill"
                                  style="padding: 2px;">сітка
                          </button>
                          <button v-if="item.show_type == 2" class="btn btn-sm  btn-fill" style="padding: 2px;">список
                          </button>
                          <button class="btn btn-sm btn-dark btn-fill" style="padding: 2px;">
                            {{ item.type == 2 && item.poomsae_type == 1 ? 'традиційне' : '' }}{{ item.type == 2 && item.poomsae_type == 2 ? 'вільне' : '' }}
                          </button>
                          <button class="btn btn-sm btn-dark btn-outline" style="padding: 2px;"> {{ item.count }}</button>


                        </div>

                      </div>

                    </div>
                  </div>
                </div>



              </div>


            </div>

          </card>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormCategorySection from 'src/pages/tournament/_formCategorySection.vue';
import FormSection from 'src/pages/tournament/_formSection.vue';
import {Sortable, Plugins, DragStartEvent, DragStopEvent} from '@shopify/draggable';
import FormCategory from 'src/pages/tournament/_formCategory.vue';

export default {
  name: "tab-categories",
  components: {
    'form-category-section': FormCategorySection,
    'form-section': FormSection
  },

  data: function () {

    return {
      sortableCategoyDay: '',
      formCategoryData: {
        id: '',
        section: '',
        name: '',
      },

      formSectionData: {
        id: '',
        sectionName: '',
        start_number: '',
      },
    }
  },


  computed: {
    activeTab() {
      return this.$store.getters['settings/activeTournamentTab'];
    },

    actionTournamentAddCategory() {
      return this.$store.getters['settings/actionTournamentAddCategory'];
    },

    activeTournamentCategoryTab() {
      return this.$store.getters['settings/activeTournamentCategoryTab'];
    },

    tournamentOne() {
      return this.$store.getters['tournament/tournamentOne'];
    },


    filteredCategories: function () {

      function compare(a, b) {
        if (a.count < b.count) {
          return -1;
        }
        if (a.count > b.count) {
          return 1;
        }
        return 0;
      }


      console.log('filteredCategories', this.tournamentOne.data.item.protocols);
      let filters = [];
      if (this.tournamentOne.data.item.protocols) {
        filters = this.tournamentOne.data.item.protocols;
        // let checkedFiters = this.tournamentOne.data.item.protocols.filter(function(el) {
        //   return el.count > 0;
        // });
        // checkedFiters.forEach(element => {
        //   filters.push(element);
        // });


      }
      // Sort by count
      // filters.sort( compare );
      return filters;
    },
  },



  methods: {

    // удаление одной категории с турнира
    updateOneCategory(category) {
      console.log(category);
      //   // this.formAddSportsman.first_name = sportsman.first_name;
      this.formCategoryData.name = category.fullname;
      this.formCategoryData.id = category.id;
      this.formCategoryData.section = category.section;
      for (let i = 0; i < this.tournamentOne.data.item.sections; i++) {
        if (category.section == this.tournamentOne.data.item.sections[i].id) {
          this.formCategoryData.section = this.tournamentOne.data.item.sections[i];
        }
      }

      console.log(this.formCategoryData);

      this.$modal.show('category-section-form-modal');
    },


    resetSectionForm(){
      this.formSectionData = {
        id: '',
        name: '',
        start_number: '',
      }
    },
    getSectionNameForCategory(category){

      console.log('category', category);

      if(category.sectionid != null && category.sectionid > 0){
        for (const categoryKey in this.tournamentOne.data.item.sections) {

          console.log('this.tournamentOne.data.item.sections[categoryKey]', this.tournamentOne.data.item.sections[categoryKey]);
          if(category.sectionid == this.tournamentOne.data.item.sections[categoryKey].id){
            return this.tournamentOne.data.item.sections[categoryKey].name;
          }
        }
      }

      return "";
    },

    addNewSection(){
      this.resetSectionForm();
      this.$modal.show('section-form-modal');
    },

    updateSection(section){
      this.resetSectionForm();

      console.log('section', section);

      this.formSectionData.id = section.id;
      this.formSectionData.name = section.name;
      this.formSectionData.start_number = section.start_number;
      this.$modal.show('section-form-modal');
    },


    deleteOneCategory(id) {

      if (window.confirm(" Після видалення дані неможливо буде відновити. Ви впевнені, що хочете видали категорію з турніру?")) {
        let params = {'id': id};
        this.$store.dispatch('tournament/deleteOneCategory', params)
          .then(() => this.deleteFromListCategory(id));
      }
    },
    deleteFromListCategory(id) {
      let length = this.tournamentOne.data.item.protocols.length;
      for (let i = 0; i < length; i++) {
        console.log(this.tournamentOne.data.item.protocols[i].id);
        if (this.tournamentOne.data.item.protocols[i].id === id) {
          this.tournamentOne.data.item.protocols.splice(i, 1);
          break;
        }
      }
    },



    showCategoryForm() {
      this.$modal.show('category-form-modal');
    },
    hideCategoryForm() {
      this.$modal.hide('category-form-modal');
    },


    setActiveCategoryTab(position) {
      this.$store.dispatch('settings/setActiveTournamentCategoryTab', position);
    },

    resortCategoryDays() {
      let day1 = document.getElementById('day1');
      let day2 = document.getElementById('day2');
      let itemsDay1 = day1.getElementsByClassName('category-item');
      let itemsDay2 = day2.getElementsByClassName('category-item');

      let categoryId = '';
      let day1ids = [];
      let day1position = [];
      let day2ids = [];
      let day2position = [];


      let i = 0;
      for (i = 0; i < itemsDay1.length; i++) {
        if (itemsDay1[i] == undefined) {
          continue;

        }
        if (itemsDay1[i].classList.contains('draggable-mirror')) {

        } else if (itemsDay1[i].classList.contains('draggable-mirror')) {

        } else if (itemsDay1[i].classList.contains('draggable--original')) {

        } else {

          console.log(itemsDay1[i]);
          categoryId = itemsDay1[i].getAttribute('data-category-id');

          day1ids.push(categoryId);
          day1position.push({id: categoryId, position: i})
        }

      }

      i++;
      for (let k = 0; k < itemsDay2.length; k++) {
        if (itemsDay2[i] == undefined) {
          continue;

        }
        if (itemsDay2[i].classList.contains('draggable-mirror')) {

        } else if (itemsDay2[k].classList.contains('draggable-mirror')) {

        } else if (itemsDay2[k].classList.contains('draggable--original')) {

        } else {
          categoryId = itemsDay2[k].getAttribute('data-category-id');
          day2ids.push(categoryId);
          day2position.push({id: categoryId, position: (k + i)})
        }

      }


      console.log('itemsDay1', itemsDay1);

      let params = {
        day1ids: day1ids,
        day2ids: day2ids,
        day1position: day1position,
        day2position: day2position,
      };
      console.log('params', params);
      this.$store.dispatch('tournament/setCategoryDays', params)
        // .then(() => this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.id}))
        .catch(error => {
          alert('Помилка сервера');
        });
    },


  }
}
</script>

<style scoped>

</style>
