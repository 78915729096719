import BaseInput from './components/Inputs/BaseInput.vue'
import BaseCheckbox from './components/Inputs/BaseCheckbox.vue'
import BaseRadio from './components/Inputs/BaseRadio.vue'
import BaseDropdown from './components/BaseDropdown.vue'
import Card from './components/Cards/Card.vue'
import JudgePoomsaeTraditionalScreen from './components/Screens/JudgePoomsaeTraditionalScreen.vue'
import Bracket64 from './components/Screens/Bracket64.vue'
import Bracket32 from './components/Screens/Bracket32.vue'
import Bracket2 from './components/Screens/Bracket2.vue'
import Bracket4 from './components/Screens/Bracket4.vue'
import Bracket8 from './components/Screens/Bracket8.vue'
import Bracket16 from './components/Screens/Bracket16.vue'
import BracketSportsmanItemDraw from './components/Screens/BracketSportsmanItemDraw.vue'
import BracketFightNumberDraw from './components/Screens/BracketFightNumberDraw.vue'

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install (Vue) {
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseCheckbox.name, BaseCheckbox)
    Vue.component(BaseRadio.name, BaseRadio)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component('card', Card)
    Vue.component('judge-poomsae-traditional-screen', JudgePoomsaeTraditionalScreen)
    Vue.component('bracket-2', Bracket2);
    Vue.component('bracket-4', Bracket4);
    Vue.component('bracket-8', Bracket8);
    Vue.component('bracket-16', Bracket16);
    Vue.component('bracket-32', Bracket32);
    Vue.component('bracket-64', Bracket64);
    Vue.component('bracket-sportsman-item-draw', BracketSportsmanItemDraw);
    Vue.component('bracket-fight-number-draw', BracketFightNumberDraw);
  }
}


export default GlobalComponents
