<template>
  <div class="sp-item" :data-sp="sportsman.id"  v-bind:class="{'empty':sportsman.name =='' }" >



    <div class="group-team group-team-top text-top-line ">
      <button v-if="sportsman.id" @click="removeSportsman()" class="btn btn-sm btn-danger btn-fill" style="padding: 0px 5px;margin-left: -5px;margin-right: 5px;">x</button>
      <span
      class="tehnical-number">{{technicalNumber}}</span>
      <span style="
    background: #fff;
    z-index: 1000;
    position: relative;
">{{sportsman.name}}</span>
      <span class="winner-points">{{sportsman.winPoints}}</span>
    </div>
    <div class="group-team group-team-top text-bottom-line"><span v-if="showOrganization">{{sportsman.oblast}}/{{sportsman.organization}}</span></div>
    <div class="group-team group-team-top line-center"></div>
  </div>
</template>

<script>
  export default {
    name: "bracket-sportsman-item-draw",
    props: {
      removeSportsmanBracket: {
        type: Function,
        description: "Function removeSportsmanBracket"
      },
      id: {
        type: [String,Number],
        description: "Sportsman id"
      },

      technicalNumber: {
        type: [String,Number],
        description: "Sportsman id"
      },

      sportsman: {
        type: [Object],
        description: "Sportsman object"
      },

      showOrganization: {
        type: [Boolean],
        description: "Sportsman object",
        default:false,
      },
      },
    computed:{
      activeTournamentId() {
        return this.$store.getters['settings/activeTournamentId'];
      },

      activeCategoryId() {
        return this.$store.getters['settings/activeCategoryId'];
      },

      activeBracketSize() {
        return this.$store.getters['settings/activeBracketSize'];
      },
    },

      methods:{
        removeSportsman(){

          if (window.confirm(" Видалити учасника з позиції ?")) {
            this.$store.dispatch('tournament/removeFromBracketSportsman', {id: this.sportsman.id, tournament_id:this.activeTournamentId, category_id:this.activeCategoryId, bracket_size:this.activeBracketSize, position:this.technicalNumber})
              .then(() => this.removedUpdate());
          }
        },
        removedUpdate(){

          this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.activeTournamentId})
              .then(() => this.$notify({
                group: 'foo',
                title: 'Дані оновлено',
                type: 'success',
              }));

        }
      }

  }
</script>

<style scoped>
.tehnical-number{
  display: none;
}

</style>
