<template>

  <div class="content" v-if="userOne.data">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card"><!---->
            <div class="card-header"><h4 class="card-title">Моя команда</h4>
              <p class="card-category">Лише Ви можете бачити список своїх спортсменів.
              </p>
            <div>
              <button class="btn btn-fill btn-primary" @click="showFormAddSportsman()">Додати спортсмена</button>

            </div>
            </div>
            <div class="card-body" >
              <div class="all-icons">
                <div class="row">


                  <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6"  v-for="(sportsman, index) in userOne.data.user.team" :key="index" >
                    <div class="font-icon-detail">
                      <div class="photo" @click="uploadSportsmanPhoto(index)" v-bind:style="'background-image: url('+ (sportsman.img != '' && sportsman.img != null ? ( baseUrlServer + sportsman.img) : '/images/user-default.png' +')')" style="cursor: pointer; background-image: url(/images/user-default.png);
    width: 100px;
    height: 100px;
    background-size: contain;
    background-position: center;
    border-radius: 50%;
    display: inline-block;">

                      </div>

                      <my-upload field="img"
                                 @crop-success="cropSuccess"
                                 @crop-upload-success="cropUploadSuccess"
                                 @crop-upload-fail="cropUploadFail"
                                 v-model="show"
                                 :noRotate="false"
                                 :width="300"
                                 :height="300"
                                 :url="baseUrlServer + '/user/upload-sportsman-team-avatar'"
                                 :params="params"
                                 :headers="headers"
                                 lang-type="ua"
                                 img-format="png"></my-upload>


                      <p>{{sportsman.first_name}} {{sportsman.last_name}} {{sportsman.middle_name}}</p>
                      <button type="button" class="btn btn-sm btn-danger btn-fill    " @click="removeSportsmanTeam(sportsman.id)"><i class="nc-icon nc-simple-remove" style="vertical-align: sub; font-size: initial;" ></i>Видалити {{sportsman.id}}
                      </button>
                    </div>

                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <modal :minHeight="400" :minWidth="760" :draggable="true" :resizable="true" :adaptive="true" name="add-sportsman-form">

      <div class="container">
      <div class="row_">
        <div class="row" style="padding-bottom: 15px; margin-bottom: 40px;">
          <div class="col-md-12">
            <h4 data-v-387c1e50="" class="card-title">Додати спортсмена в команду</h4>
          </div>
        </div>
          <div class="row">

            <div class="col-lg-4 col-sm-12">
              <base-input type="text"
                          label="Ім'я"
                          placeholder=""
                          v-model="formAddSportsman.first_name">
              </base-input>
            </div>

            <div class="col-lg-4 col-sm-12">
              <base-input type="text"
                          label="Прізвище"
                          placeholder=""
                          v-model="formAddSportsman.last_name">
              </base-input>
            </div>

            <div class="col-lg-4 col-sm-6">
              <base-input type="text"
                          label="По-батькові"
                          placeholder=""
                          v-model="formAddSportsman.middle_name">
              </base-input>
            </div>
            <div class="col-lg-4 col-sm-6">


                <base-input type="number" min="0" max="100"
                                            label="Дата народження"
                                            placeholder=""
                                            v-model="formAddSportsman.age2">

                  <flat-pickr
                    v-model="formAddSportsman.bdate"
                    :config="configDatePicker"
                    class="form-control"
                    placeholder="Select date"
                    name="date">
                  </flat-pickr>
                                </base-input>
            </div>


            <div class="col-lg-4 col-sm-12">

              <div class="form-group"><label class="control-label">
                Стать
              </label>
                <v-select label="name" :filterable="true" :options="[{id:0, name:'-'}, {id:1, name:'чоловік'}, {id:2, name:'жінка'}]"
                          v-model="formAddSportsman.sex">
                  <template slot="no-options">Укажіть стать
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      <span class="name-city">{{ option.name }}</span>
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      <span class="name-city">{{ option.name }}</span>
                    </div>
                  </template>
                </v-select>
              </div>

            </div>






          </div>

          <div class="row">
            <div class="col-md-3">
              <button @click="saveSportsman()" class="btn btn-sm btn-primary btn-fill  "  style="margin: 10px 10px 10px 10px;" >
                <i class="nc-icon nc-cloud-upload-94" style="vertical-align: sub;"></i> Зберегти</button>

            </div>
          </div>
        </div>
      </div>



    </modal>


  </div>


</template>

<script>
  import {Ukrainian} from 'flatpickr/dist/l10n/uk.js';
  import myUpload from 'vue-image-crop-upload';
  import {baseUrlServer} from '../../api/user';

  export default {
    name: "UserTeam",
    components: {	'my-upload': myUpload  },
    data: function () {

      return {

        baseUrlServer:'',
        ua: {
          hint: 'Натисніть, або перетягніть файл в це вікно',
          loading: 'Завантажую……',
          noSupported: 'Ваш браузер не підтримується, будь ласка скористайтесь IE10 + або іншими браузерами',
          success: 'Завантаження виконано успішно',
          fail: 'Помилка завантаження',
          preview: 'Попередній перегляд',
          btn: {
            off: 'Відмінити',
            close: 'Закрити',
            back: 'Назад',
            save: 'Зберегти'
          },
          error: {
            onlyImg: 'Тільки зображення',
            outOfSize: 'Зображення перевищує граничний розмір: ',
            lowestPx: 'Мінімальний розмір зображення: '
          }
        },

        // user: {
        //   company: '',
        //   username: ' ',
        //   email: ' ',
        //   firstName: ' ',
        //   lastName: ' ',
        //   address: ' ',
        //   city: '',
        //   region: '',
        //   country: '',
        //   aboutMe: ``
        // },


        show: false,
        params: {
          token: '123456798',
          name: 'avatar',
          id:''
        },
        headers: {
          smail: '*_~'
        },
        imgDataUrl: '/images/user-default.png', // the datebase64 url of created image


        configDatePicker: {
          allowInput: true,
          locale: Ukrainian,
          dateFormat: 'Y-m-d',
          minDate: 'today',
          enableTime: false,
          time_24hr: true
        },
        formAddSportsman:{
          first_name:'',
          last_name:'',
          middle_name:'',
          bdate:'',
          sex:'',
        },

      }
    },

    watch: {
      show: function(newVal) {

        if(newVal != true){
          this.$store.dispatch('user/fetchUserCurrent');
        }
      }
    },
    created(){
      // this.$store.dispatch('user/fetchUserCurrent');
      this.baseUrlServer = baseUrlServer;
      this.imgDataUrl = this.baseUrlServer + this.imgDataUrl;

      const token = localStorage.getItem('jwt')
      if (token) {

        this.headers = {'Authorization':'Bearer '+token, 'WWW-Authenticate':'Bearer '+token};
        console.log('headers', this.headers);
        // Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer '+token;
        // Vue.prototype.$http.defaults.headers.common['WWW-Authenticate'] = 'Bearer '+token;
      }
    },

    computed: {
      userOne() {
        return this.$store.getters['user/userOne'];
      },
    },

    methods:{
      showFormAddSportsman () {
        this.$modal.show('add-sportsman-form');
      },
      hideFormAddSportsman () {
        this.$modal.hide('add-sportsman-form');
      },

      removeSportsmanTeam(id){
        console.log('removeSportsmanTeam ', id);
        if (window.confirm(" Після видалення дані неможливо буде відновити. Ви впевнені, що хочете видали спортсмена з команди?")) {
          let params = {'id': id};
          this.$store.dispatch('user/removeOneSportsmanTeam', params)
            .then(() => this.deleteFromListSportsman(id));
        }
      },
      deleteFromListSportsman(id){
        let length = this.userOne.data.user.team.length;
        for (let i = 0; i < length; i++) {
          console.log(this.userOne.data.user.team[i].id);
          if (this.userOne.data.user.team[i].id === id){
            this.userOne.data.user.team.splice(i, 1);
            this.$notify({
              group: 'foo',
              title: 'Спортсмена видалено з команди',
              type: 'success',
            });
            break;
          }
        }
      },

      saveSportsman(){

        var params = Object.assign({}, this.formAddSportsman);
        params.sex = params.sex.id == undefined ? 0: params.sex.id;


        console.log(params);


        this.$store.dispatch('user/addSportsman', params)
          .then(() => this.$store.dispatch('user/fetchUserCurrent'))
          .catch(error => {
            alert('Помилка');
          });

        this.hideFormAddSportsman();
      },


      /**
       * crop success
       *
       * [param] imgDataUrl
       * [param] field
       */
      cropSuccess(imgDataUrl, field){
        // this.$store.dispatch('user/fetchUserCurrent');
        console.log('-------- crop success --------');
        this.imgDataUrl = imgDataUrl;
      },
      /**
       * upload success
       *
       * [param] jsonData   服务器返回数据，已进行json转码
       * [param] field
       */
      cropUploadSuccess(jsonData, field){
        console.log('-------- upload success --------');
        console.log(jsonData);
        console.log('field: ' + field);
      },
      /**
       * upload fail
       *
       * [param] status    server api return error status, like 500
       * [param] field
       */
      cropUploadFail(status, field){
        console.log('-------- upload fail --------');
        console.log(status);
        console.log('field: ' + field);
      },

      uploadSportsmanPhoto(index){
        this.show = true;
        this.params.id = this.userOne.data.user.team[index].id;

        this.imgDataUrl = this.baseUrlServer + this.userOne.data.user.team[index].img;
      }

    }
  }
</script>

<style scoped>

</style>
