import { render, staticRenderFns } from "./_tabInfo.vue?vue&type=template&id=6e9527bf&scoped=true&"
import script from "./_tabInfo.vue?vue&type=script&lang=js&"
export * from "./_tabInfo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e9527bf",
  null
  
)

export default component.exports