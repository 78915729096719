<template>
    <div class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">
                    <div class="card"><!---->
                        <div class="card-header"><h4 class="card-title">


                            Безкоштовні шаблони сіток, заявок.

                        </h4>
                            <p class="card-category">Якщо є ідеї по додаванню інших документів - пишіть нам на пошту <a
                                                                                        href="mailto:Taekwondo.com.ua@gmail.com">Taekwondo.com.ua@gmail.com</a>
                            </p></div>
                        <div class="card-body">
                            <div class="all-icons">
                                <div class="row">
                                    <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                                        <div class="font-icon-detail"><i class="nc-icon nc-align-left-2"></i>
                                            <p><a target="_blank" class="text-dark" href="https://docs.google.com/spreadsheets/d/1jfOmUqnZdUwVJJBHQ8pswfRIhgZM6Bur/edit?dls=true#gid=1640188028">Турнірна сітка на 2</a></p></div>
                                    </div>
                                    <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                                        <div class="font-icon-detail"><i class="nc-icon nc-align-left-2"></i>
                                            <p><a target="_blank" class="text-dark" href="https://drive.google.com/open?id=1q829Bcsp5HFGNS-H0N9ceDV2garIHbJK">Турнірна сітка на 4</a></p></div>
                                    </div>
                                    <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                                        <div class="font-icon-detail"><i class="nc-icon nc-align-left-2"></i>
                                            <p><a target="_blank" class="text-dark" href="https://drive.google.com/open?id=1SwH10kunmNkLarzjuDjR4zgbVAzb4m2K">Турнірна сітка на 8</a></p></div>
                                    </div>
                                    <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                                        <div class="font-icon-detail"><i class="nc-icon nc-align-left-2"></i>
                                            <p><a class="text-dark" href="xxxxx">Турнірна сітка на 16</a></p></div>
                                    </div>
                                    <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                                        <div class="font-icon-detail"><i class="nc-icon nc-align-left-2"></i>
                                            <p><a target="_blank" class="text-dark" href="https://drive.google.com/open?id=1LQP-zc-7lj9_OUnNuB9FLCS6BKAlYizZ">Турнірна сітка на 32</a></p></div>
                                    </div>
                                    <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                                        <div class="font-icon-detail"><i class="nc-icon nc-align-left-2"></i>
                                            <p><a target="_blank" class="text-dark" href="https://drive.google.com/open?id=1ti6ldGVLKkPQo1fGB90Ga2mUuhOWGkhd">Турнірна сітка на 64</a></p></div>
                                    </div>


                                    <div class="font-icon-list col-lg-2 col-md-3 col-sm-4 col-6">
                                        <div class="font-icon-detail"><i class="nc-icon nc-align-left-2"></i>
                                            <p><a target="_blank" class="text-dark" href="https://drive.google.com/open?id=1BDNO8UQq3SnBW1eS-aEwI8SMb-qpQ2htUxgrTR4W2CM">Турнірна сітка на 64</a></p></div>
                                    </div>

                                </div>
                            </div>
                        </div> <!----></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "DocumentTemplates"
  }
</script>

<style scoped>

</style>