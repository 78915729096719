<template>
  <div class="content_">
    <div class="container-fluid_ poomsae-queues" v-if="categoryOne && categoryOne.data && categoryOne.data.roundsData">
      <div class="card" style="padding: 10px 10px 10px 10px">
        <div class="row">
          <div class="col-md-12">
            <a class="btn btn-fill btn-sm" target="_blank" :href="baseUrlServer+'/tournament/print-pdf-list?category='+this.selectedDrawCategory.id">Скачати жеребкування PDF</a>
            |
            <a class="btn btn-fill btn-sm" target="_blank" :href="baseUrlServer+'/tournament/print-pdf-list-result?category='+this.selectedDrawCategory.id">Скачати результати PDF</a>
            |
            <a class="btn btn-fill btn-sm" target="_blank" :href="baseUrlServer+'/tournament/print-pdf-list-result?sort=place&category='+this.selectedDrawCategory.id">Скачати результати PDF з сортування по місцях</a>
            |
            <a class="btn btn-fill btn-sm" target="_blank" :href="baseUrlServer+'/tournament/print-pdf-list-result?sort=place&autosort=true&category='+this.selectedDrawCategory.id">Скачати результати PDF з АВТО-сортування по місцях</a>
            |
            <button class="btn btn-fill btn-sm " v-bind:class="{'btn-warning':toogleSortabl}" @click="toogleSortableCategoryDay()">Ручне жеребкування</button>
          </div>
          <div class="col-md-12">
            <h4 class="title">{{selectedDrawCategory.id}} | {{selectedDrawCategory.fullname}} </h4>
            <div class="">
              <div class="dop-info">
                <button v-if="selectedDrawCategory.type == 1" class="btn btn-sm btn-danger btn-fill" style="padding: 2px;">
                  керугі
                </button>
                <button v-if="selectedDrawCategory.type == 2" class="btn btn-sm btn-fill"
                        style="background: #9368e9; padding: 2px;">пумсе
                </button>
                <button v-if="selectedDrawCategory.show_type == 1" class="btn btn-sm btn-warning btn-fill"
                        style="padding: 2px;">сітка
                </button>
                <button v-if="selectedDrawCategory.show_type == 2" class="btn btn-sm  btn-fill" style="padding: 2px;">список
                </button>
                <button  class="btn btn-sm btn-dark btn-fill" style="padding: 2px;">
                  {{ selectedDrawCategory.type == 2 && selectedDrawCategory.poomsae_type == 1 ? 'традиційне' : '' }}{{
                    selectedDrawCategory.type == 2 &&
                    selectedDrawCategory.poomsae_type == 2 ? 'вільне' : ''
                  }}
                </button>

                <span>{{ selectedDrawCategory.sex == 1 ? 'Чоловіки' : '' }}{{ selectedDrawCategory.sex == 2 ? 'Жінки' : '' }}</span>
                <span style="background: #ddd; padding: 3px; margin-left: 10px">{{ selectedDrawCategory.count }}</span>

                <span style="margin-left: 10px; background: red; color: #fff">Kort EasyPoomsae</span><select v-model="activeKortEp" style=" width: 100px;margin-left: 20px;" >
                <option value="1">1</option>
                <option value="2">2</option>

              </select>

              </div>
            </div>
            <div class="previous-queue">


              <!--            Традиційне парні-->
              <table id="table-list" class="table table-bordered" style="font-size: 12px">
                <thead>
                <tr>
                  <td rowspan="2" class="text-center">ID</td>
                  <td rowspan="2" class="text-center" style="width: 300px;
    min-width:250px;
    max-width: 250px;">
                    Прізвище, ім’я спортсмена
                  </td>
<!--                  <td rowspan="2" class="text-center" style="    width: 150px;-->
<!--    min-width: 150px;-->
<!--    max-width: 150px;">-->
<!--                    Регіон / команда-->
<!--                  </td>-->
                  <td colspan="5" class="text-center">
                    Попереднє коло
                  </td>
                  <td colspan="5" class="text-center">
                    Півфінал
                    <button class="button-start" @click="startEpEvent('preliminary')">start TV</button>
                  </td>
                  <td colspan="5" class="text-center">
                    Фінал
                    <button class="button-start" @click="startEpEvent('preliminary')">start TV</button>
                  </td>
<!--                  <td rowspan="2" class="text-center">-->
<!--                    Загальна-->
<!--                    оцінка-->

<!--                  </td>-->
                  <td rowspan="2" class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;">
                    Підсумкове місце (результат)

                  </td>

                </tr>
                <tr>
                  <td class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;">EP</td>
                  <td class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;">техніка</td>
                  <td class="text-center" style="
    width: 50px;
    min-width: 50px;
    max-width: 85px;
    padding: 0px 2px;
">презентація</td>
                  <td class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;">разом</td>
                  <td class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;">загальна оцінка</td>

                  <td class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;">EP</td>
                  <td class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;">техніка</td>
                  <td class="text-center" style="
    width: 50px;
    min-width: 50px;
    max-width: 85px;
    padding: 0px 2px;
">презентація</td>
                  <td class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;">разом</td>
                  <td class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;">загальна оцінка</td>

                  <td class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;">EP</td>
                  <td class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;">техніка</td>
                  <td class="text-center" style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;">презентація</td>
                  <td class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;">разом</td>
                  <td class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;">загальна оцінка</td>


                </tr>
                </thead>

                <tbody class="poomsae-queue">
                <tr style="cursor:pointer; user-select: none" v-bind:key="item.id+'_category'"  v-for="(item, indexx) in categoryOne.data.roundsData" :data-sp-id="item.sp_id" :data-sp-name="item.name" :data-sp-oblast="item.oblast" :data-sp-organization="item.organization" :data-t-id="item.sp_id" class="poomsae-queue-item sortable-category-drag-item" >
                  <td class="text-left"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;">{{item.id}} <button class="btn btn-sm btn-danger btn-fill" @click="removeSportsman(item)">x</button></td>
                  <td class="text-left">{{item.name}} <br>
                    <div class="organization-small-info">
                      {{item.organization}}
                    </div></td>

                  <td class="text-center">
                    <button class="button-start" @click="startEpEvent(1,item,'preliminary')">start {{item.prev_ep_event1}}</button>
                    <hr style="margin: 5px; border-top-color: #000;">
                    <button class="button-start" @click="startEpEvent(2,item, 'preliminary')">start {{item.prev_ep_event2}}</button>
                  </td>

                  <td class="text-center"> <input @change="savePointsData()" v-model="item.prev_teh1" :data-sp-id="item.sp_id" data-field="prev_teh1" class="points-input input-points-field" type="text" style="min-width: 45px; width: 45px;"> <hr style="    margin: 5px; border-top-color: #000;"> <input @change="savePointsData()"  v-model="item.prev_teh2"  :data-sp-id="item.sp_id"  data-field="prev_teh2" class="points-input input-points-field" type="text" style="min-width: 45px; width: 45px;"> </td>
                  <td class="text-center"><input @change="savePointsData()" v-model="item.prev_pres1" :data-sp-id="item.sp_id" data-field="prev_pres1" class="points-input input-points-field" type="text" style="min-width: 45px; width: 45px;"> <hr style="    margin: 5px; border-top-color: #000;"> <input @change="savePointsData()" v-model="item.prev_pres2" :data-sp-id="item.sp_id" data-field="prev_pres2" class="points-input input-points-field"  type="text" style="min-width: 45px; width: 45px;"> </td>
                  <td class="text-center"><input @change="savePointsData()" v-model="item.prev_total_1" :data-sp-id="item.sp_id" data-field="prev_total_1" class="points-input input-points-field"  type="text" style="min-width: 45px; width: 45px;"> <hr style="    margin: 5px; border-top-color: #000;"> <input @change="savePointsData()" v-model="item.prev_total_2" :data-sp-id="item.sp_id" data-field="prev_total_2" class="points-input input-points-field" type="text" style="min-width: 45px; width: 45px;"> </td>
                  <td class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;"> <input @change="savePointsData()" v-model="item.prev_total" :data-sp-id="item.sp_id" data-field="prev_total" class="points-input" type="text" style="min-width: 45px; width: 45px;"> </td>

                  <td class="text-center">
                    <button class="button-start" @click="startEpEvent(1,item,'semi-final')">start {{item.semifinal_ep_event1}}</button>
                    <hr style="margin: 5px; border-top-color: #000;">
                    <button class="button-start" @click="startEpEvent(2,item, 'semi-final')">start {{item.semifinal_ep_event2}}</button>
                  </td>
                  <td class="text-center"><input @change="savePointsData()" v-model="item.semifinal_teh1" :data-sp-id="item.sp_id" data-field="semifinal_teh1" class="points-input input-points-field" type="text"  > <hr style="    margin: 5px; border-top-color: #000;"> <input  @change="savePointsData()" v-model="item.semifinal_teh2" :data-sp-id="item.sp_id" data-field="semifinal_teh2" class="points-input input-points-field" type="text"  > </td>
                  <td class="text-center"><input @change="savePointsData()" v-model="item.semifinal_pres1" :data-sp-id="item.sp_id" data-field="semifinal_pres1" class="points-input input-points-field" type="text"  > <hr style="    margin: 5px; border-top-color: #000;"> <input @change="savePointsData()" v-model="item.semifinal_pres2" :data-sp-id="item.sp_id" data-field="semifinal_pres2" class="points-input input-points-field" type="text" > </td>
                  <td class="text-center"><input @change="savePointsData()" v-model="item.semifinal_total_1" :data-sp-id="item.sp_id" data-field="semifinal_total_1" class="points-input input-points-field" type="text"  > <hr style="    margin: 5px; border-top-color: #000;"> <input @change="savePointsData()" v-model="item.semifinal_total_2" :data-sp-id="item.sp_id" data-field="semifinal_total_2" class="points-input input-points-field" type="text"  > </td>
                  <td class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;"> <input @change="savePointsData()" v-model="item.semifinal_total" :data-sp-id="item.sp_id" data-field="semifinal_total" class="points-input input-points-field" type="text"  > </td>


                  <td class="text-center">
                    <button class="button-start" @click="startEpEvent(1,item,'final')">start {{item.final_ep_event1}}</button>
                    <hr style="margin: 5px; border-top-color: #000;">
                    <button class="button-start" @click="startEpEvent(2,item, 'final')">start {{item.final_ep_event1}}</button>
                  </td>
                  <td class="text-center"><input @change="savePointsData()" v-model="item.final_teh1" :data-sp-id="item.sp_id" data-field="final_teh1" class="points-input" type="text" style="min-width: 45px; width: 45px;"> <hr style="    margin: 5px; border-top-color: #000;"> <input @change="savePointsData()" v-model="item.final_teh2" :data-sp-id="item.sp_id" data-field="final_teh2" class="points-input input-points-field" type="text"  > </td>
                  <td class="text-center"><input @change="savePointsData()" v-model="item.final_pres1" :data-sp-id="item.sp_id" data-field="final_pres1" class="points-input" type="text" style="min-width: 45px; width: 45px;"> <hr style="    margin: 5px; border-top-color: #000;"> <input @change="savePointsData()" v-model="item.final_pres2" :data-sp-id="item.sp_id" data-field="final_pres2" class="points-input input-points-field" type="text"  > </td>
                  <td class="text-center"><input @change="savePointsData()" v-model="item.final_total_1" :data-sp-id="item.sp_id" data-field="final_total_1" class="points-input" type="text" style="min-width: 45px; width: 45px;"> <hr style="    margin: 5px; border-top-color: #000;"> <input @change="savePointsData()" v-model="item.final_total_2" :data-sp-id="item.sp_id" data-field="final_total_2" class="points-input input-points-field" type="text"  > </td>
                  <td class="text-center"  style="  width: 50px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;"> <input @change="savePointsData()" v-model="item.total" :data-sp-id="item.sp_id" data-field="total" class="points-input" type="text" style="min-width: 45px; width: 45px;"> </td>
                  <td class="text-center"  style="  width: 150px;    min-width: 50px;    max-width: 85px;    padding: 0px 2px;     display: table-cell;">
                    <input @change="savePointsData()" v-model="item.place" :data-sp-id="item.sp_id" data-field="place" class="points-input input-points-field" type="text" style="min-width: 45px; width: 45px;"> </td>
                </tr>


<!--                <tr>-->
<!--                  <td class="text-center"> </td>-->
<!--                </tr>-->

                </tbody>

              </table>


              <!-- <div style="cursor:pointer; user-select: none" :data-sp-id="item.sp_id" :data-sp-name="item.name" :data-sp-oblast="item.oblast" :data-sp-organization="item.organization" :data-t-id="item.sp_id" class="poomsae-queue-item sortable-category-drag-item" v-for="(item, index) in categoryOne.data.roundsData" :key="'preround_'+index" v-if="item.round == 2" >
                 <div>
                   {{item.name}}<span class="text-danger" v-if="!item.name">не вказано</span>
                 </div>
                 <div class="dop-info">
                   <button   class="btn btn-sm btn-warning btn-outline" style="padding: 2px;"> <span class="text-dark">{{item.oblast}}</span></button>
                   <button   class="btn btn-sm btn-dark btn-outline" style="padding: 2px;"> <span class="text-dark">{{item.organization}}</span></button>
                 </div>

               </div>-->
            </div>
          </div>


        </div>
      </div>
    </div>


  </div>
</template>

<script>
import {baseUrlServer} from '../../api/user';
import {DragStartEvent, DragStopEvent, Plugins, Sortable} from '@shopify/draggable';
import bracketWinners from './bracket-winners.vue';

export default {
    name: 'result-constructor',
    components: {
      Sortable, Plugins, DragStartEvent, DragStopEvent, 'bracket-winners': bracketWinners
    },
    props: {
      selectedDrawCategory: Object
    },
    data() {
      return {
        activeKortEp:1,
        baseUrlServer:'',
        activeEventSportsman: null,
        activeNumberPoomsae: null,
        swappable: null,
        toogleSortabl: false,

        results: {
          protocol_type: 'list',
          tournament_id: '',
          protocolid_id: '',
          start_round: '',
          plases: {
            place1: '',
            place2: '',
          },
          rounds: [
            {
              type: 'preround',
              sps: [
                {
                  sp_id: '',
                  name: '',
                  oblast: '',
                  organization: '',
                  teh1: '',
                  pres1: '',
                  total1: '',
                  teh2: '',
                  pres2: '',
                  total2: '',
                  totalAll: ''
                },
              ],
            },
            {
              type: 'semifinal',
              sps: [
                {
                  sp_id: '',
                  teh1: '',
                  pres1: '',
                  total1: '',
                  teh2: '',
                  pres2: '',
                  total2: '',
                  totalAll: ''
                },
              ],
            },
            {
              type: 'final',
              sps: [
                {
                  sp_id: '',
                  teh1: '',
                  pres1: '',
                  total1: '',
                  teh2: '',
                  pres2: '',
                  total2: '',
                  totalAll: ''
                },
              ],
            },
          ],

        },

        sortableCategory: null,
        colorSheme: true,
        showBracket32: false,
        showBracket16: false,
        showBracket8: false,
        showBracket4: false,
        showBracket2: true,
        sortableSheme: false,
        sportsmans: [],
        bracketFigths: {},
        bracketEl: null,
        selectedFight: {
          f: '',
          number: '',
          decision: '',
          winSpId: '',
          winSpName: '',
          red: {spId: '', spName: '', points: '', spOrg: ''},
          blue: {spId: '', spName: '', points: '', spOrg: ''}
        },
      }
    },

    watch: {
      selectedDrawCategory: function (newVal) {

        this.selectedDrawCategory = newVal;

        this.init();
        this.getCategoryData();


        // this.resortBracketInit();
      }
    },

    mounted() {
      this.init();

      if (this.results.tournament_id != '' && this.results.tournament_id != undefined) {
        if (this.results.start_round == 'preround' || this.results.start_round == 'semifinal' || this.results.start_round == 'final') {

        } else {

          // this.resortCategoryDays();
        }
      }

    },
  created() {
    this.baseUrlServer = baseUrlServer;
  },

  computed: {
      categoryOne: function () {
        return this.$store.getters['tournament/categoryOne'];
      }
    },


    methods: {

      startEpEvent(poomsaeNumber, item, round){
        let validation = true;
        let type = 'Traditional';

        let poomsae_type = this.selectedDrawCategory.poomsae_type;
        if(poomsae_type == 2){
          type = 'Free Style';
        }

        let params = {
          poomsaeNumber:poomsaeNumber,
          sp_id:item.sp_id,
          tournament_id:item.tournament_id,
          round:round,
          kort:this.activeKortEp,
          type:type,
        }

        console.log('startEpEvent', item);
        if(poomsaeNumber == 1){
          // First poomsae presentation

        }
        else if(poomsaeNumber == 2){
          // Second poomsae presentation

        }else{
          alert('Error');
          validation = false;
        }


        if(validation === true){
          this.activeEventSportsman = item.sp_id;
          this.activeNumberPoomsae = poomsaeNumber;
          // Start event on EP
          console.log('params', params);

          this.$store.dispatch('tournament/startEpEvent', params).then(() => this.eventStarted());
        }

      },

      eventStarted(){

        this.getCategoryData();

        this.$notify(
            {
              text: 'Easy Poomsae Event started',
              horizontalAlign: 'right',
              verticalAlign: 'top',
              duration: 10000,
              speed: 1000,
              type: 'success'
            })
      },

      removeSportsman(item){
        console.log('removeSportsman')

        if (window.confirm(" Ви впевнені, що хочете видали учасника "+item.name +" з категорії '"+this.selectedDrawCategory.fullname +"' ?")) {
          let params = {'id': item.id, category:this.selectedDrawCategory.id};
          this.$store.dispatch('tournament/deleteSportsmanFromCategory', params).then(() => this.fetchTournamentOneFull());
        }
      },
      getCategoryData() {
        let saveData = {
          tournament: this.selectedDrawCategory.tournament_id,
          category: this.selectedDrawCategory.id,
        };
        this.$store.dispatch('tournament/fetchCategoryOne', saveData);
      },

      init() {
        if (this.selectedDrawCategory != null && this.selectedDrawCategory != undefined && this.selectedDrawCategory.id != undefined) {
          // раскидываем спорстменов по кругам
          this.sportsmans = this.selectedDrawCategory.sportsmans;
          this.results.protocolid_id = this.selectedDrawCategory.id;
          this.results.tournament_id = this.selectedDrawCategory.tournament_id;
          this.results.start_round = this.selectedDrawCategory.start_round;


          console.log('this.selectedDrawCategory poomsae list', this.selectedDrawCategory);

          // let countSp = Object.keys(this.sportsmans).length;
          // let i = 0;

          let saveData = {
            tournament: this.results.tournament_id,
            category: this.results.protocolid_id,
          };

          console.log('this.results.', this.results);

          // если это первая жеребьевка и не задано start_round - закидваем в предыдущий круг и сохраняем сразу
          if (this.results.start_round == '2' || this.results.start_round == 'preround' || this.results.start_round == 'semifinal' || this.results.start_round == 'final') {
            // закидываем спорсменов так как они уже раскинутые

            // this.$store.dispatch('tournament/fetchCategoryListSportsmans', saveData);
            // this.$store.dispatch('tournament/createCategoryListFirst', saveData);
            this.$store.dispatch('tournament/fetchCategoryOne', saveData);
          } else {

            console.log('createCategoryListFirst result');
            this.$store.dispatch('tournament/createCategoryListFirst', saveData);
            // this.results.rounds[0].sps = [];
            // this.sportsmans.forEach(element => {
            //
            //   let obj = {
            //     sp_id: element.id,
            //     name: element.name,
            //     oblast: element.oblast,
            //     organization: element.organization,
            //     teh1: '',
            //     pres1: '',
            //     total1: '',
            //     teh2: '',
            //     pres2: '',
            //     total2: '',
            //     totalAll: ''
            //   };
            //
            //   this.results.rounds[0].sps.push(obj);
            // });


            console.log('this.results.rounds[0]', this.results.rounds[0]);
            // this.results.rounds[0].sps

            // this.resortCategoryDays();
          }


        }

        // this.setSortableCategoryDay();
      },

      toogleSortableCategoryDay (){
        console.log('toogleSortableCategoryDay', this.toogleSortabl );
        if(this.toogleSortabl == true){
          this.toogleSortabl = false;
          if (this.sortableCategory !== null) {
            this.sortableCategory.destroy();
          }
        }else{
          this.toogleSortabl = true;
          this.setSortableCategoryDay();
        }

      },
      setSortableCategoryDay() {
        console.log('setSortableCategoryDay');

        let containersDays = document.querySelectorAll('tbody.poomsae-queue');
        console.log('poomsae-queuepoomsae-queuepoomsae-queuepoomsae-queue', containersDays);
        if (containersDays.length > 0) {
          if (this.sortableCategory !== null) {
            // debugger;
            console.log('destroy destroy', containersDays);

            this.sortableCategory.destroy();
          }

          this.sortableCategory = new Sortable(containersDays, {
            draggable: '.poomsae-queue-item',
            mirror: {
              constrainDimensions: true,
            },
            delay: 0,
            plugins: [Plugins.ResizeMirror],
            dragClass: "sortable-category-drag-item",
          });
          this.sortableCategory.on('drag:stop', () => this.resortCategoryDays());
        } else {
          this.sortableCategory = null;
        }
      },


      resortCategoryDays() {
        console.log('resortCategoryDays');
        let tableList = document.getElementById('table-list');
        let itemsList = tableList.getElementsByTagName('tr');
        console.log(itemsList);

        let itemsPositionIds = [];
        let i = 0;
        let p= 0;
        for (i = 0; i < itemsList.length; i++) {

          if(itemsList[i].classList.contains('draggable-mirror')){

          }else if(itemsList[i].classList.contains('draggable-mirror')){

          }else if(itemsList[i].classList.contains('draggable--original')){

          }else{

            let spID = itemsList[i].getAttribute('data-sp-id');
          if(spID > 0){
            p++;
            itemsPositionIds.push({id:spID, pos: p});
          }
        }
        }

        console.log(itemsPositionIds);
        this.saveData(itemsPositionIds);

      },

      saveData(sportsmans) {
        // send data to
        let saveData = {
          tournament: this.selectedDrawCategory.tournament_id,
          category: this.selectedDrawCategory.id,
          sportsmans: sportsmans,
        };
        this.$store.dispatch('tournament/setPositionListSp', saveData);

        console.log('saveData');
      },


      savePointsData(){
        let points = [];
      // class="points-input"
        let inputs = document.getElementsByClassName('points-input');

        if(inputs.length > 0){
          for (let i = 0; i < inputs.length; i++) {
            let sp_id = inputs[i].getAttribute('data-sp-id');
            let field_name = inputs[i].getAttribute('data-field');
            let field_value = inputs[i].value;
            // let field_name = inputs[i].getAttribute('field');

            points.push({
              sp_id:sp_id,
              field_name:field_name,
              field_value:field_value,
              tournament: this.selectedDrawCategory.tournament_id,
              category: this.selectedDrawCategory.id,

            })



          }

        }

        this.$store.dispatch('tournament/sePointsListSp', points);
        console.log('points', points);

      }

    }
  }
</script>

<style lang="scss">
.button-start{
  border: 1px solid #5d5757;
  box-shadow: none;
  text-transform: uppercase;
  background: #d6d5d3;
  font-size: smaller;
}

.poomsae-queues{
 overflow: auto;
}

tr.active-line td{
  background: #9a9a9a;

}

.organization-small-info{
  background: #dee2e6;
  border: 1px solid #ccc;
  padding: 1px 2px;
  font-size: smaller;
}

.previous-queue{
  .table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 5px 4px;
    vertical-align: middle;
  }
}



.input-points-field{
  min-width: 37px!important;
  width: 37px!important;
}
</style>
