import { render, staticRenderFns } from "./_tabCategories.vue?vue&type=template&id=0c4fa7e2&scoped=true&"
import script from "./_tabCategories.vue?vue&type=script&lang=js&"
export * from "./_tabCategories.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c4fa7e2",
  null
  
)

export default component.exports