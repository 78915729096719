<template>
  <div class="new-create-update-form">

    <div class="row">
      <div class="col-lg-6 col-sm-6">
        <base-input type="text"
                    label="Назва відділення"
                    placeholder=""
                    v-model="formAddCategory.name">
        </base-input>
      </div>
      <div class="col-lg-3 col-sm-4">

        <base-input type="text"
                    label="Старт поєдинків1"
                    placeholder=""
                    v-model="formAddCategory.start_number">
        </base-input>

      </div>


    </div>


    <div class="row">
      <div class="col-md-3">
        <button @click="saveCategoryAndAdd()" class="btn btn-sm btn-primary btn-fill  "
                style="margin: 10px 10px 10px 10px;">
          <i class="nc-icon nc-cloud-upload-94" style="vertical-align: sub;"></i> Зберегти
        </button>

        <button @click="saveCategoryClose()" class="btn btn-sm btn-dark btn-fill  "
                style="margin: 10px 10px 10px 10px;">
          <i class="nc-icon nc-simple-remove" style="vertical-align: sub;"></i> Закрити
        </button>


        <button @click="numerationClose()" class="btn btn-sm btn-danger btn-fill  "
                style="margin: 10px 10px 10px 10px;">
          <i class="nc-icon nc-atom" style="vertical-align: sub;"></i> Пронумерувати
        </button>


        <a target="_blank" :href="'https://app.taekwondo.com.ua/tournament-result/print-section-pdf?id='+formAddCategory.id+'&tid='+tournamentOne.data.item.id" class="btn btn-sm btn-warning btn-fill  "
                style="margin: 10px 10px 10px 10px;">
          <i class="nc-icon nc-cloud-download-93" style="vertical-align: sub;"></i> Скачати
        </a>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "form-category",
  props: ['formAddCategory'],


  computed: {
    tournamentOne() {
      return this.$store.getters['tournament/tournamentOne'];
    }
  },

  methods: {


    saveCategoryClose() {
      // this.actions.addCategory = false;
      this.$modal.hide('section-form-modal');
      // this.actions.addCategory = false;
    },


    numerationClose() {
      if (window.confirm("Ви впевнені, що хочете пронумерувати поєдинки? Всі попередні номера в даному відділенні будуть стерті")) {
        this.$notify(
          {
            text: 'Нумерація поєдинків',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            duration: 10000,
            speed: 1000,
            type: 'error'
          })
        var params = Object.assign({}, this.formAddCategory);
        this.$store.dispatch('tournament_section/fightNumeration', params).then(() => this.$store.dispatch('tournament/fetchTournamentOneFull', {id: params.tournament_id}))
          .then(() => this.updateBracket());
      }

      this.$modal.hide('section-form-modal');
      // this.actions.addCategory = false;
      // this.$modal.hide('section-form-modal');
      // this.actions.addCategory = false;
    },
    saveCategoryAndAdd() {
      this.saveCategory(false);
    },

    saveCategoryAndClose() {
      this.saveCategory(true);
    },


    saveCategory(close) {
      var params = Object.assign({}, this.formAddCategory);

      params.sectionid = this.formAddCategory.id == undefined ? 0 : this.formAddCategory.id;
      params.tournament_id = this.tournamentOne.data.item.id;


      console.log(params);


      if (params.id != undefined) {
        this.$store.dispatch('tournament_section/addSection', params)
          .then(() => this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.tournamentOne.data.item.id}))
          .then(() =>this.saveCategoryClose())
          .catch(error => {
            alert('Помилка');
          });

        // TODO добавить обработку ошибок

      } else {

        // .then(() => this.successfulSaved())
        // .catch(error => {
        //   this.faileSaved()
        // });
      }

      // this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.tournamentOne.data.item.id});
      this.$modal.hide('category-section-form-modal');
    },

  }
}
</script>

<style scoped>

</style>
