import { render, staticRenderFns } from "./_infoNavbar.vue?vue&type=template&id=534c1798&scoped=true&"
import script from "./_infoNavbar.vue?vue&type=script&lang=js&"
export * from "./_infoNavbar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "534c1798",
  null
  
)

export default component.exports