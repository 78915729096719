<template>
  <div class="content">
    <div v-if="!tournamentOne.data" class="row">
      <div class="col-12">
        <card style="min-height: 70px" class="strpied-tabled-with-hover"
              body-classes="table-full-width table-responsive">
          <template slot="header">
            <h4 class="card-title"> Завантаження данних. Зачекайте...</h4>
          </template>
        </card>
      </div>
    </div>


    <!--    Общая информация о турнире-->
    <info-navbar></info-navbar>

    <div class="container-fluid" v-if="tournamentOne.data  && tournamentOne.data.item">
      <!--      Таби меню управления турніром-->
      <control-tabs></control-tabs>
      <!--      Таба УЧАСНИКОВ-->
      <tab-sportsmans></tab-sportsmans>

      <!--      Таба КАТЕГОРИИ-->
      <tab-categories></tab-categories>

      <!--      Таба ЖЕРЕБКУВАННЯ-->
      <tab-drawing :category="category"></tab-drawing>


      <!--      Таба Розбивка поєдинків -->
      <tab-category-sections></tab-category-sections>


      <!--      Таба РЕЗУЛЬТАТИ-->
<!--      <tab-results :category="category"></tab-results>-->

      <div class="tournament-content-tab" v-bind:class="{'tournament-content-active-tab':activeTab == 6}">
        <div class="row">
          <div class="col-md-12">
            <card>
              <template slot="header">
                <h4 class="title">Функціонал в розробці й буде доступний найближчим часом1</h4>
              </template>
            </card>
          </div>
        </div>
      </div>
    </div>


    <div class="container-fluid">
      <tab-info></tab-info>
    </div>
  </div>
</template>

<script>


  import axios from 'axios';
  import ChartCard from 'src/components/Cards/ChartCard.vue'
  import StatsCard from 'src/components/Cards/StatsCard.vue'
  import LTable from 'src/components/Table.vue'
  // import UserBracketsContructor from  'src/pages/user/UserBracketsContructor.vue';
  import {Sortable, Plugins, DragStartEvent, DragStopEvent} from '@shopify/draggable';
  import UserBracketsContructor from 'src/pages/tools/UserBracketsContructor.vue';
  import ListContructor from 'src/pages/tools/ListContructor.vue';
  import ResultContructor from 'src/pages/tools/ResultContructor.vue';
  import ListContructorNew from 'src/pages/tools/ListContructorNew.vue';
  import InfoNavbar from 'src/pages/tournament/_infoNavbar.vue';
  import ControlTabs from 'src/pages/tournament/_controlTabs.vue';
  import TabSportsmans from 'src/pages/tournament/_tabSportsmans.vue';
  import TabCategories from 'src/pages/tournament/_tabCategories.vue';
  import TabCategorySection from 'src/pages/tournament/_tabCategorySection.vue';
  import TabDrawing from 'src/pages/tournament/_tabDrawing.vue';
  import TabResults from 'src/pages/tournament/_tabResults.vue';
  import TabInfo from 'src/pages/tournament/_tabInfo.vue';
  import {regions} from 'src/utils/dictionary.js';

  import Cookie from "js-cookie";
  import {baseUrlServer} from '../../api/user';
  export default {
    props: ['id', 'category'],
    components: {
      LTable,
      ChartCard,
      StatsCard,
      'list-constructor-new': ListContructorNew,
      'list-constructor': ListContructor,
      'result-constructor': ResultContructor,
      'brackets-constructor': UserBracketsContructor,
      'info-navbar': InfoNavbar,
      'control-tabs': ControlTabs,
      'tab-sportsmans': TabSportsmans,
      'tab-categories': TabCategories,
      'tab-drawing': TabDrawing,
      'tab-results': TabResults,
      'tab-info': TabInfo,
      'tab-category-sections': TabCategorySection,
      Sortable, Plugins, DragStartEvent, DragStopEvent
    },
    data() {
      return {
        selectedDrawCategory: {},
        baseUrlServer: '',
        hideEmptyCategories: false,
        showBracketCategories: false,
        regions: [],
        file: '',
      }
    },

    computed: {
      activeTab() {
        return this.$store.getters['settings/activeTournamentTab'];
      },

      sortedCategories: function () {

      },

      tournamentOne() {
        return this.$store.getters['tournament/tournamentOne'];
      },
    },
    beforeMount() {
      this.regions = regions;
    },
    mounted() {
      console.log(' process.env.NODE_ENV');
      console.log(process.env.NODE_ENV);

      if (this.id != undefined) {
        this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.id});
      }
      if (this.category != undefined) {
        this.$store.dispatch('settings/setActiveTournamentTab', 4);
      }
    },
    created(){
      this.baseUrlServer = baseUrlServer;
    },
    methods: {

      selectDrawCategory(category) {
        this.selectedDrawCategory = category;
      },


      // выгрузка файла CSV на сервер
      handleFileUpload() {
        this.file = this.$refs.file.files[0];

        let formData = new FormData();
        formData.append('id', this.id);
        formData.append('file', this.file);

        this.$store.dispatch('tournament/uploadFileCSV', formData).then(() => this.fetchTournamentOneFull());
      },
      fetchTournamentOneFull() {
        this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.id});
      },


      clickHideEmptyCategories() {
        // console.log(clickHideEmptyCategories)
        if (this.hideEmptyCategories == true) {
          this.hideEmptyCategories = false;
        } else {
          this.hideEmptyCategories = true;
        }
      },
      clickShowBracketCategories() {
        // console.log(clickHideEmptyCategories)
        if (this.showBracketCategories == true) {
          this.showBracketCategories = false;
        } else {
          this.showBracketCategories = true;
        }
      },
    },
  }
</script>
<style>

</style>
