<template>
  <div>
    <div class="judge-traditional-screen judge-screen" style="width: 1024px; height: 600px; display: none">

      <div class="header-judge">
        <div class="name-info">

          <div class="sportsman">Раус Анна</div>
          <div class="poomsae">Poomsae: T7</div>

        </div>

        <div class="category-info">

          <div class="poomsae">Poomsae: T7</div>

        </div>


        <div class="sent-result">

          <button class="btn btn-success">Відправити результати</button>
        </div>


      </div>

      <div class="score-section">

        <div class="section-points">
          <div class="title">
            Сила та потужність
          </div>
          <div class="points">
            <div class="item" v-for="item in info.strong"
                 v-bind:class="{'active':poomsaeScore.strong == item}"
                 @click="clickStrong(item)">{{item}}
            </div>
          </div>
        </div>


        <div class="section-points">
          <div class="title">
            Потужність / Швидкість / Ритм
          </div>
          <div class="points">
            <div class="item" v-for="item in info.speed"
                 v-bind:class="{'active':poomsaeScore.speed == item}"
                 @click="clickSpeed(item)">{{item}}
            </div>
          </div>
        </div>


        <div class="section-points">
          <div class="title">
            Вираження енергії
          </div>
          <div class="points">
            <div class="item" v-for="item in info.energetic"
                 v-bind:class="{'active':poomsaeScore.energetic == item}" @click="clickEnergetic(item)">
              {{item}}
            </div>
          </div>
        </div>


      </div>


      <div class="hot-score-buttons">
        <div class="button-judge" @click="recalcTechnic(-0.3)">-0.3</div>
        <div class="result">
          <div class="total-score-points">

            <div class="technical">Техніка: <span class="score-total">{{poomsaeScore.total.t}}</span></div>
            <div class="presentation">Презентація: <span class="score-total">{{poomsaeScore.total.p}}</span>
            </div>

          </div>

          <div class="total-score" style="padding-top: 15px;">
            <div @click="recalcTechnic(+0.3)" class="button-judge">+0.3


            </div>
            <div class="presentation">Загалом: <span class="score-total">{{poomsaeScore.total.p}}</span>
            </div>


            <div @click="recalcTechnic(+0.1)" class="button-judge">+0.1</div>
          </div>

        </div>
        <div class="button-judge" @click="recalcTechnic(-0.1)">-0.1</div>

      </div>

    </div>


    <div class="judge-traditional-screen judge-screen" style="width: 1024px; height: 600px; overflow: auto;">

      <div class="header-judge">
        <div class="name-info">

          <div class="sportsman">Раус Анна</div>
          <div class="poomsae">Poomsae: T7</div>

        </div>

        <div class="category-info">

          <div class="poomsae">Poomsae: T7</div>

        </div>


        <div class="sent-result">

          <button class="btn btn-success">Відправити результати</button>
        </div>


      </div>

      <div class="score-section">
        <div class="hot-score-buttons" style="height: 300px;">
          <div class="button-judge" style="position:relative;" @click="recalcTechnic(-0.3)">-0.3
            <!--                        <span class="count" style="font-size: 16px; background: rgb(221, 221, 221) none repeat scroll 0% 0%; border-radius: 30px; position: absolute; width: 50px; height: 50px; line-height: 50px; top: 0px; right: 0px; border: 1px solid rgb(185, 185, 185);">x{{counts.count_03}}</span>-->
          </div>
          <div class="result">
            <div class="total-score-points">
              <div class="technical">Техніка: <span class="score-total">{{poomsaeScore.total.t}}</span></div>
              <div class="presentation">Презентація: <span class="score-total">{{poomsaeScore.total.p}}</span>
              </div>

            </div>

            <div class="total-score" style="padding-top: 15px;">
              <div></div>
              <div class="presentation" style="font-size: 40px;color: green;font-weight: bold;">Загалом: <span class="score-total">{{poomsaeScore.total.t}}</span></div>
              <div></div>
            </div>


            <div class="total-score" style="padding-top: 95px;">
              <div @click="recalcTechnic(+0.3)" class="button-judge">+0.3</div>
              <div class="presentation">Техніка: <span class="score-total">{{poomsaeScore.total.t}}</span></div>
              <div @click="recalcTechnic(+0.1)" class="button-judge">+0.1</div>
            </div>

          </div>
          <div class="button-judge" style="position:relative;" @click="recalcTechnic(-0.1)" >-0.1
            <!--                        <span class="count" style="font-size: 16px; background: rgb(221, 221, 221) none repeat scroll 0% 0%; border-radius: 30px; position: absolute; width: 50px; height: 50px; line-height: 50px; top: 0px; left: 0px; border: 1px solid rgb(185, 185, 185);">x{{counts.count_01}}</span>-->
          </div>

        </div>

        <div class="section-points">
          <div class="title">
            Сила та потужність
          </div>
          <div class="points">
            <div class="item" v-for="item in info.strong"
                 v-bind:class="{'active':poomsaeScore.strong == item}"
                 @click="clickStrong(item)">{{item}}
            </div>
          </div>
        </div>


        <div class="section-points">
          <div class="title">
            Потужність / Швидкість / Ритм
          </div>
          <div class="points">
            <div class="item" v-for="item in info.speed"
                 v-bind:class="{'active':poomsaeScore.speed == item}"
                 @click="clickSpeed(item)">{{item}}
            </div>
          </div>
        </div>


        <div class="section-points">
          <div class="title">
            Вираження енергії
          </div>
          <div class="points">
            <div class="item" v-for="item in info.energetic"
                 v-bind:class="{'active':poomsaeScore.energetic == item}" @click="clickEnergetic(item)">
              {{item}}
            </div>
          </div>
        </div>


      </div>




    </div>


  </div>


</template>

<script>
  export default {
    name: "judge-poomsae-traditional-screen",
    props: {
      title: {
        type: String,
        description: "Card title"
      },
      subTitle: {
        type: String,
        description: "Card subtitle"
      },
      type: {
        type: String,
        description: "Card type (e.g primary/danger etc)"
      },
      headerClasses: {
        type: [String, Object, Array],
        description: "Card header css classes"
      },
      bodyClasses: {
        type: [String, Object, Array],
        description: "Card body css classes"
      },
      footerClasses: {
        type: [String, Object, Array],
        description: "Card footer css classes"
      }
    },
    data() {
      return {
        info: {
          strong: [2.0, 1.9, 1.8, 1.7, 1.6, 1.5, 1.4, 1.3, 1.2, 1.1, 1.0, 0.9, 0.8, 0.7, 0.6, 0.5],
          speed: [2.0, 1.9, 1.8, 1.7, 1.6, 1.5, 1.4, 1.3, 1.2, 1.1, 1.0, 0.9, 0.8, 0.7, 0.6, 0.5],
          energetic: [2.0, 1.9, 1.8, 1.7, 1.6, 1.5, 1.4, 1.3, 1.2, 1.1, 1.0, 0.9, 0.8, 0.7, 0.6, 0.5],
        },
        poomsaeScore: {
          strong: 0,
          speed: 0,
          energetic: 0,
          j2: {t: 0, p: 0, t_del: 0, p_del: 0},
          j3: {t: 0, p: 0, t_del: 0, p_del: 0},
          j4: {t: 0, p: 0, t_del: 0, p_del: 1},
          j5: {t: 0, p: 0, t_del: 1, p_del: 0},
          total: {
            p: '6.0',
            t: '4.0',
          }
        },
        counts:{
          count_03:0,
          count_01:0
        }
      }
    },
    methods: {
      clickStrong(item) {
        this.poomsaeScore.strong = item;
        this.recalcPresentation();
      },
      clickSpeed(item) {
        this.poomsaeScore.speed = item;
        this.recalcPresentation();
      },
      clickEnergetic(item) {
        this.poomsaeScore.energetic = item;
        this.recalc();
      },
      recalcPresentation() {
        let totalP = parseFloat(this.poomsaeScore.energetic) + parseFloat(this.poomsaeScore.speed) + parseFloat(this.poomsaeScore.strong);
        this.poomsaeScore.total.p = totalP.toFixed(2);
      },
      recalcTechnic(val) {
        console.log(val);

        let totalTechnic = parseFloat(val) + parseFloat(this.poomsaeScore.total.t);
        console.log(totalTechnic);
        if (totalTechnic >= 0 && totalTechnic <= 4) {


          if(parseFloat(val) == -0.3){
            ++this.counts.count_03;
          }else if (parseFloat(val) == 0.3){
            --this.counts.count_03;
          }else if (parseFloat(val) == -0.1){
            ++this.counts.count_01;
          }else if (parseFloat(val) == 0.1){
            --this.counts.count_01;
          }


          this.poomsaeScore.total.t = totalTechnic.toFixed(2);
        }

      }
    }
  }
</script>

<style scoped>

</style>












