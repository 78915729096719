<template>
  <div class="row new-create-update-form" v-if="actionTournamentAddSportsman == true">
    <div class="col-md-12">
      <div class="row">
        <div class="col-lg-3 col-sm-12">
          <base-input type="text"
                      label="Прізвище Ім'я По-батькові учасника"
                      placeholder=""
                      v-model="formAddSportsman.name">
          </base-input>
        </div>

        <div class="col-lg-3 col-sm-12">
          <base-input type="number" min="1970"
                      label="Дата народження"
                      placeholder=""
                      v-model="formAddSportsman.bdate">
          </base-input>
        </div>

        <div class="col-lg-3 col-sm-12">
          <base-input type="text"
                      label="Спортивна кваліфікація"
                      placeholder=""
                      v-model="formAddSportsman.qualification">
          </base-input>
        </div>

        <div class="col-lg-3 col-sm-12">

          <div class="form-group"><label class="control-label">
            Стать
          </label>
            <v-select label="name" :filterable="true"
                      :options="[{id:0, name:'-'}, {id:1, name:'чоловік'}, {id:2, name:'жінка'}]"
                      v-model="formAddSportsman.sex">
              <template slot="no-options">Укажіть стать
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <span class="name-city">{{ option.name }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  <span class="name-city">{{ option.name }}</span>
                </div>
              </template>
            </v-select>
          </div>

        </div>

      </div>


      <div class="row">

        <div class="col-lg-3 col-sm-12">
          <base-input type="text"
                      label="ФСТ/Організація"
                      placeholder=""
                      v-model="formAddSportsman.organization">
          </base-input>
        </div>

        <div class="col-lg-3 col-sm-12">
          <base-input type="text"
                      label="Тренер"
                      placeholder=""
                      v-model="formAddSportsman.coach">
          </base-input>
        </div>
        <div class="col-lg-3 col-sm-12">
          <base-input type="text"
                      label="Тренер 2"
                      placeholder=""
                      v-model="formAddSportsman.trainer2">
          </base-input>
        </div>


        <div class="col-lg-3 col-sm-12">

          <div class="form-group"><label class="control-label">
            Область
          </label>
            <v-select label="name" :filterable="true" :options="regions"
                      v-model="formAddSportsman.oblast">
              <template slot="no-options">Укажіть стать
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <span class="name-city">{{ option.name }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  <span class="name-city">{{ option.name }}</span>
                </div>
              </template>
            </v-select>
          </div>

        </div>

      </div>


      <div class="row">

        <div class="col-md-12">

          <div class="form-group"><label class="control-label">
            Категорія
          </label>
            <v-select label="name" :filter="fuseSearch"  :filterable="true" :options="tournamentOne.data.item.protocols"

                      v-model="formAddSportsman.category_id">
              <template slot="no-options">Укажіть категорію
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  <span class="name-city">{{ option.fullname }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  <span class="name-city">{{ option.fullname }}</span>
                </div>
              </template>
            </v-select>
          </div>

        </div>


      </div>


      <div class="row">
        <div class="col-md-3">
          <button @click="saveSportsmanAndAdd()" class="btn btn-sm btn-primary btn-fill  "
                  style="margin: 10px 10px 10px 10px;">
            <i class="nc-icon nc-cloud-upload-94" style="vertical-align: sub;"></i> Зберегти
          </button>

          <button @click="saveSportsmanClose()" class="btn btn-sm btn-dark btn-fill  "
                  style="margin: 10px 10px 10px 10px;">
            <i class="nc-icon nc-simple-remove" style="vertical-align: sub;"></i> Закрити
          </button>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import {regions} from 'src/utils/dictionary.js';

export default {
  props: ['formAddSportsman'],
  name: "form-sportsman",
  data: function () {

    return {
      regions: [],
      // formAddSportsman:{
      //   id:'',
      //   tournament:'',
      //   name:'',
      //   bdate:'',
      //   organization:'',
      //   qualification:'',
      //   sex:'',
      //   weight:'',
      //   coach:'',
      //   trainer2:'',
      //   category_id:'',
      //   oblast:'',
      // },
    }
  },
  beforeMount() {
    this.regions = regions;
  },
  computed: {

    tournamentOne() {
      return this.$store.getters['tournament/tournamentOne'];
    },
    actionTournamentAddSportsman() {
      return this.$store.getters['settings/actionTournamentAddSportsman'];
    },
  },
  methods: {
    fuseSearch(options, search) {
       return options.filter(function (el) { return el.fullname.includes(search)})
    },
    saveSportsmanAndAdd() {
      this.saveSportsman(false);
      // this.formAddSportsman.id = '';
      // this.formAddSportsman.name = '';
      // this.formAddSportsman.bdate = '';
      // this.formAddSportsman.category_id = '';
    },

    saveSportsman(close) {
      var params = Object.assign({}, this.formAddSportsman);
      params.tournament = this.tournamentOne.data.item.id;
      params.sex = params.sex.id;
      params.bdate = params.bdate;
      params.organization = params.organization;
      params.qualification = params.qualification;
      params.coach = params.coach;
      params.trainer2 = params.trainer2;
      params.category_id = params.category_id.id;


      if (params.oblast != '' && params.oblast != undefined && params.oblast.name == undefined) {
        params.oblast = params.oblast;
      } else {
        params.oblast = params.oblast == '' || params.oblast == undefined ? '' : params.oblast.name;
      }

      params.id = this.formAddSportsman.id;

      console.log(params);
      // TODO виправити oblast
      if (params.name.length < 1) {
        alert('ПРІЗВИЩЕ ІМ\'Я ПО-БАТЬКОВІ УЧАСНИКА');
        return false;
      }

      // TODO виправити дати
      if (parseInt(params.category_id) < 1) {
        alert('Укажіть категорію');
        return false;
      }
      // TODO виправити oblast
      if (params.oblast.length < 1) {
        alert('Укажіть область');
        return false;
      }

      // TODO виправити coach
      if (params.coach.length < 1) {
        alert('Укажіть тренера');
        return false;
      }

      // TODO виправити coach
      if (params.coach.length < 1) {
        alert('Укажіть тренера');
        return false;
      }

      // TODO виправити oblast
      if (params.organization.length < 1) {
        alert('Укажіть організацію');
        return false;
      }

      if (params.id != undefined) {
        this.$store.dispatch('tournament/addSportsman', params)
            .then(() => this.updatedData())
            .catch(error => {
              // this.faileSaved()
              alert('Помилка');
            });

        // TODO добавить обработку ошибок

      } else {

        // .then(() => this.successfulSaved())
        // .catch(error => {
        //   this.faileSaved()
        // });
      }

      this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.tournamentOne.data.item.id});
      this.$modal.hide('sportsman-form-modal');


    },

    saveSportsmanClose() {
      this.$store.dispatch('settings/setActionTournamentAddSportsman', false);
      this.$modal.hide('sportsman-form-modal');
    },

    /**
     * Оновлення данних по турніру
     */
    updatedData() {
      this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.tournamentOne.data.item.id});
      this.$notify(
          {
            text: 'Дані оновлено',
            horizontalAlign: 'right',
            verticalAlign: 'top',
            duration: 10000,
            speed: 1000,
            type: 'success'
          })
    },

  },
}
</script>

<style scoped>

</style>
