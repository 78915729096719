<template>
  <div class="row">
    <div class="col-xl-12 col-md-12">
      <ul class="nav nav-tabs ">
        <li class="nav-item">
          <a href="javascript:;" class="nav-link "  v-bind:class="{'active':activeTab == 1}"   v-on:click="setActiveTab(1)"  >ЗАГАЛЬНА ІНФОРМАЦІЯ</a>
        </li>
        <li class="nav-item">
          <a href="javascript:;" class="nav-link "  v-bind:class="{'active':activeTab == 7}"   v-on:click="setActiveTab(7)"  >КАТЕГОРІЇ</a>
        </li>
        <li class="nav-item">
          <a href="javascript:;"  class="nav-link "  v-bind:class="{'active':activeTab == 2}"   v-on:click="setActiveTab(2)"  >СПОРТСМЕНИ</a>
        </li>
        <!--            <li class="nav-item">-->
        <!--              <a class="nav-link " href="javascript:;"  v-bind:class="{'active':activeTab == 3}"   v-on:click="setActiveTab(3)"  >СПИСОК УЧАСНИКІВ</a>-->
        <!--            </li>-->
        <li class="nav-item">
          <a class="nav-link " href="javascript:;" v-bind:class="{'active':activeTab == 4}"  v-on:click="setActiveTab(4)" >ЖЕРЕБКУВАННЯ</a>
        </li>

      <!--  <li class="nav-item">
          <a class="nav-link " href="javascript:;" v-bind:class="{'active':activeTab == 10}"  v-on:click="setActiveTab(10)" >СИСТЕМА ПРОВЕДЕННЯ</a>
        </li>
-->
<!--        <li class="nav-item">-->
<!--          <a class="nav-link " href="javascript:;"  v-bind:class="{'active':activeTab == 5}"  v-on:click="setActiveTab(5)"  >РЕЗУЛЬТАТИ</a>-->
<!--        </li>-->

        <li class="nav-item">
          <a class="nav-link text-uppercase " href="javascript:;" v-bind:class="{'active':activeTab == 11}"  v-on:click="setActiveTab(11)" >Нумерація</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

  // import Cookie from "js-cookie";

  export default {
    name: "control-tabs",
    computed: {
      activeTab() {
        return this.$store.getters['settings/activeTournamentTab'];
      },
    },
    methods:{
      setActiveTab(position) {
        this.$store.dispatch('settings/setActiveTournamentTab', position);
      },
      // setActiveTab(position) {
      //   console.log('setActiveTab ' + position);
      //   this.activeTab = position;
      //   Cookie.set("tab_in_"+this.id, position);
      // },
    }
  }
</script>

<style scoped>

</style>
