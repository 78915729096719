<template>
  <div class="new-create-update-form">

    <div class="row">
      <div class="col-lg-12 col-sm-12">
        <base-input type="text"
                    label="Назва"
                    readonly=""
                    placeholder=""
                    v-model="formAddCategory.name">
        </base-input>
      </div>


    </div>

    <div class="row">
      <div class="col-lg-3 col-sm-12">

        <div class="form-group"><label class="control-label">
          Відділення
        </label>
          <v-select label="name" :filterable="true"
                    :options="tournamentOne.data.item.sections"
                    v-model="formAddCategory.section">
            <template slot="no-options">Укажіть назву секції
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                <span class="name-city">{{ option.name }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                <span class="name-city">{{ option.name }}</span>
              </div>
            </template>
          </v-select>
        </div>

      </div>
    </div>


    <div class="row">
      <div class="col-md-3">
        <button @click="saveCategoryAndAdd()" class="btn btn-sm btn-primary btn-fill  "
                style="margin: 10px 10px 10px 10px;">
          <i class="nc-icon nc-cloud-upload-94" style="vertical-align: sub;"></i> Зберегти
        </button>

        <button @click="saveCategoryClose()" class="btn btn-sm btn-dark btn-fill  "
                style="margin: 10px 10px 10px 10px;">
          <i class="nc-icon nc-simple-remove" style="vertical-align: sub;"></i> Закрити
        </button>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "form-category",
  props: ['formAddCategory'],


  computed: {
    tournamentOne() {
      return this.$store.getters['tournament/tournamentOne'];
    }
  },

  methods: {


    saveCategoryClose() {
      // this.actions.addCategory = false;
      this.$modal.hide('category-section-form-modal');
      // this.actions.addCategory = false;
    },
    saveCategoryAndAdd() {
      this.saveCategory(false);
    },

    saveCategoryAndClose() {
      this.saveCategory(true);
    },


    saveCategory(close) {
      var params = Object.assign({}, this.formAddCategory);

      params.sectionid = this.formAddCategory.section.id == undefined ? 0 : this.formAddCategory.section.id;
      params.tournament_id = this.tournamentOne.data.item.id;


      console.log(params);


      if (params.id != undefined) {
        this.$store.dispatch('tournament_section/setCategorySection', params)
          .then(() => this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.tournamentOne.data.item.id}))
          .catch(error => {
            alert('Помилка');
          });

        // TODO добавить обработку ошибок

      } else {

        // .then(() => this.successfulSaved())
        // .catch(error => {
        //   this.faileSaved()
        // });
      }

      // this.$store.dispatch('tournament/fetchTournamentOneFull', {id: this.tournamentOne.data.item.id});
      this.$modal.hide('category-section-form-modal');
    },

  }
}
</script>

<style scoped>

</style>
