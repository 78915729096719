<template>
  <div>
    <div class="super_container">

      <!-- Header -->




      <!-- Home -->



      <div class="contact" style="min-height: calc(100vh - 790px); padding-top: 150px;">
        <div class="container">
          <div class="row">

            <!-- Contact Info -->
            <div class="col-lg-12">
              <div class="contact_title">СОГЛАСИЕ С РАССЫЛКОЙ</div>
              <div class="contact_text">


                <p style="text-align: justify;"><span style="color: black;">Заполняя форму подписки на сайте&nbsp;</span><a href="https://leeloo.ai/"><span style="color: windowtext; text-decoration: none; text-underline: none;">Компании</span></a><span style="color: black;">, Вы соглашаетесь с&nbsp;Политикой Конфиденциальности&nbsp; Компании, а также с другими положениями:</span></p>
                <ol>
                  <li style="text-align: justify;"><span style="color: black;">В</span><span style="color: black;">ы даете бессрочное согласие на обработку всех персональных данных, в соответствии с публичной офертой и политикой конфиденциальности;</span></li>
                  <li style="text-align: justify;"><span style="color: black;"> Вы не возражаете против получения </span><span style="color: black;">sms</span><span style="color: black;">, email</span><span style="color: black;"> и других</span><span style="color: black;"> уведомлений от&nbsp;</span><span style="color: black;">Компании</span><span style="color: black;">;</span></li>
                  <li style="text-align: justify;"><span style="color: black;"> Вы не возражаете против получения sms, email и других уведомлений от Компании о изменениях на сайте, иных событиях, представляющих возможный интерес для Вас;</span></li>
                  <li style="text-align: justify;"><span style="color: black;"> Вы можете отписаться от информационной рассылки&nbsp;Компании&nbsp;в любое время, по своему желанию, обратившись к Администрации сайта и попросить удалить Вас из подписной базы сайта.</span></li>
                </ol>
                <p style="text-align: justify;"><span style="color: black;">После получения Администрацией сайта&nbsp;такой просьбы, Ваша информация, используемая для рассылки, будет удалена из базы в течение 48 часов, о чем Вы будете уведомлены дополнительно.</span></p>



              </div>


            </div>

          </div>

        </div>
      </div>





    </div>
  </div>



</template>
<script>
  import Card from 'src/components/Cards/Card.vue'

  export default {
    components: {
      Card
    },
    data () {
      return {
        user: {
          company: '',
          username: '',
          email: '',
          firstName: '',
          lastName: '',
          address: '',
          city: '',
          region: '',
          country: '',
          aboutMe: ``
        },

        userSignup: {
          username:{ value:'', valid:true, error:''},
          email:{ value:'', valid:true, error:''},
          firstName:{ value:'', valid:true, error:''},
          lastName:{ value:'', valid:true, error:''},
          password:{ value:'', valid:true, error:''},
          passwordRepeat:{ value:'', valid:true, error:''},
        },
        userLogin: {
          email:{ value:'', valid:true, error:''},
          password:{ value:'', valid:true, error:''},
        },
        userSignupErrors:[],
        userLoginErrors:[]
      }
    },
    computed: {
      // isLoading() {
      //   return this.$store.getters['tournament/isLoading'];
      // },
      hasErrorSignup() {
        return this.$store.getters['user/hasErrorSignup'];
      },
      errorSignup() {
        return this.$store.getters['user/errorSignup'];
      },
      errorLogin() {
        return this.$store.getters['user/errorLogin'];
      },

      hasErrorLogin() {
        return this.$store.getters['user/hasErrorLogin'];
      },


    },
    watch: {
      // whenever question changes, this function will run
      errorLogin: function (newValue, oldValue) {
        console.log('change errorLogin', newValue);
        // console.log('newValue.messages', newValue.messages);
        if(newValue != undefined && newValue.messages != undefined){
          this.userLoginErrors = newValue.messages;
        }else{
          this.userLoginErrors = '';
        }

        console.log('userLoginErrors', this.userLoginErrors);
      },
      // whenever question changes, this function will run
      errorSignup: function (newValue, oldValue) {
        if(newValue.messages != undefined){
          this.userSignupErrors = newValue.messages;
        }
        console.log(newValue);
        if(newValue.fields != undefined){
          if(newValue.fields.includes('username')){
            this.userSignup.username.valid = false;
          }else{
            this.userSignup.username.valid = true;
          }

          if(newValue.fields.includes('email')){
            this.userSignup.email.valid = false;
          }else{
            this.userSignup.email.valid = true;
          }

          if(newValue.fields.includes('firstName')){
            this.userSignup.firstName.valid = false;
          }else{
            this.userSignup.firstName.valid = true;
          }

          if(newValue.fields.includes('lastname')){
            this.userSignup.lastName.valid = false;
          }else{
            this.userSignup.lastName.valid = true;
          }

          if(newValue.fields.includes('password')){
            this.userSignup.password.valid = false;
          }else{
            this.userSignup.password.valid = true;
          }

          if(newValue.fields.includes('passwordRepeat')){
            this.userSignup.passwordRepeat.valid = false;
            this.userSignup.password.valid = false;
          }else{
            this.userSignup.passwordRepeat.valid = true;
            this.userSignup.password.valid = true;
          }


        }

      }
    },
    methods: {
      updateProfile () {
        alert('Your data: ' + JSON.stringify(this.user))
      },
      toRegistration(){
        this.$router.replace({ path: `/signUp` }) // -> /user/123
      },
      userSignupForm(){
        let params = {username:this.userSignup.username.value, email:this.userSignup.email.value, password:this.userSignup.password.value, passwordRepeat:this.userSignup.passwordRepeat.value, fname:this.userSignup.firstName.value, lname:this.userSignup.lastName.value};
        this.$store.dispatch('user/createUser', params);
        // alert('Your data: ' + JSON.stringify(this.userSignup));
      },
      userLoginForm(){
        let params = { email:this.userLogin.email.value, password:this.userLogin.password.value};
        let objthis =  this;
        this.$store.dispatch('user/loginUser', params).then(function (){

          location.href = '/user/';
          // objthis.$store.dispatch('user/fetchUserCurrent').then(()=>objthis.$router.replace({ path: `/user/` }));




        }) ;// -> /user/123);
        // alert('Your data: ' + JSON.stringify(this.userSignup));
      },

      focusFieldSignup(field){
        if(field =='username'){
          this.userSignup.username.valid = true;
        }

        if(field =='email'){
          this.userSignup.email.valid = true;
        }

        if(field =='firstName'){
          this.userSignup.firstName.valid = true;
        }

        if(field =='lastname'){
          this.userSignup.lastName.valid = true;
        }

        if(field =='password'){
          this.userSignup.password.valid = true;
        }

        if(field =='passwordRepeat'){
          this.userSignup.passwordRepeat.valid = true;
        }

        this.userSignupErrors = '';
      }
    }
  }

</script>


<style scoped>

  /*@import "../assets/home/styles/bootstrap-4.1.2/bootstrap.min.css";*/
  /*@import "../assets/home/plugins/font-awesome-4.7.0/css/font-awesome.min.css";*/
  /*@import "../assets/home/styles/main_styles.css";*/
  /*@import "../assets/home/styles/main_styles.css";*/
  /*@import "../assets/home/styles/contact.css";*/
  /*@import "../assets/home/styles/responsive.css";*/
  /*@import "../assets/home/plugins/OwlCarousel2-2.2.1/animate.css";*/
</style>
