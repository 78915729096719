<template>
  <div class="loader-wrapper">
    <div class="ring">Завантаження
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Ring"
}
</script>

<style scoped>

</style>
